import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { build as buildUri } from '../../../utils/uri';
import Summary from './summary';
import RegisterView from './RegisterView';
import ContractContext from '../../contracts/ContractContext';
import withBatch from '../../batch/withBatch';

import { remove } from '../action-creators';
import { selectContract } from '../../contracts/action-creators';
import { registerBatch, appendBatch } from '../../batch/action-creators';

const normalizeShipments = shipments =>
  shipments.map(({ user, parcelInfo, ...c }) => ({
    receiverUserId: user.id,
    assigneeUserId: user.assignee && user.assignee.id,
    ...parcelInfo,
    ...c,
  }));

const mapStateToProps = null;
const mapDispatchToProps = {
  registerBatch,
  appendBatch,
  remove,
  selectContract,
};

const Register = compose(
  withBatch,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    (selectedState, actions, { batch, ...props }) => ({
      onAdd: shipment =>
        batch
          ? actions.appendBatch(batch.id, normalizeShipments([shipment]))
          : actions.registerBatch(props.contract.id, normalizeShipments([shipment])).then(batch => {
              props.history.replace(buildUri(props.match.url, batch.id));
              return batch;
            }),
      batch,
      ...props,
      ...selectedState,
      remove: actions.remove,
      selectLocation: locationId => actions.selectContract(props.contract.id, locationId),
    })
  )
)(RegisterView);

const Trackedregister = routeProps => (
  <ContractContext>
    {({ contract }) => <Register {...routeProps} contract={contract} batchId={routeProps.match.params.batchId} />}
  </ContractContext>
);

export default ({ match, ...props }) => (
  <Switch>
    <Route
      path={`${match.url}/:batchId/summary`}
      component={routeProps => (
        <Summary {...props} {...routeProps} batchId={routeProps.match.params.batchId} />
      )}
    />
    {/* It's important that /:batchId? uses an actual defined component (as opposed to inlined) */}
    {/* since it's expected to change route, and we don't want a remount but instead keep the original instance */}
    <Route path={`${match.url}/:batchId?`} component={Trackedregister} />
  </Switch>
);
