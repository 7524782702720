import React from 'react';
import { styled } from 'styletron-react';
import style from '../style';
import { Input, TextArea } from './Input';
import { Select as SelectInput } from './Select';

export const FormWrapper = styled('div', {
  marginBottom: '15px',
});
export const FormGroup = styled('div', {
  marginBottom: '2rem',
});
export const FieldError = styled('i', {
  color: style.red,
});

export const FormLabel = styled('label', ({ $required, $inline }) => ({
  display: $inline ? 'inline' : 'block',
  maxWidth: '100%',
  marginBottom: '5px',
  fontWeight: '700',
  '::after': $required
    ? {
        content: '"*"',
        color: style.red,
        marginLeft: '.2em',
      }
    : {},
}));

const typeAttributes = {
  Integer: {
    type: 'number',
    step: 1,
    validation: props => value =>
      !value || (Number.isInteger(+value) && (!props.hasOwnProperty('min') || +value >= +props.min)),
  },
  String: {
    type: 'text',
  },
  Decimal: {
    type: 'number',
  },
  Boolean: {
    type: 'checkbox',
  },
};

// Value here should not default to '' as it can be a numeric value that could be 0
export const fieldFactory = Component => ({
  value,
  label,
  type = 'text',
  onChange,
  placeholder,
  autoFocus,
  ...props
}) => {
  const { validation, ...typeProps } = typeAttributes[type] || {};
  const validatedOnChange = e => (!validation || validation(props)(e.target.value)) && onChange(e);
  return (
    <FormWrapper>
      <FormLabel $required={props.required}>{label}</FormLabel>
      <Component
        type={type}
        {...typeProps}
        {...props}
        value={value}
        onChange={validatedOnChange}
        placeholder={placeholder || label}
        autoFocus={autoFocus || false}
      />
      {props.error && <FieldError>Error when saving: {props.error}</FieldError>}
      {props.errors && props.errors.map((e, i) => <FieldError key={i}>{e}</FieldError>)}
    </FormWrapper>
  );
};

export const TextAreaField = fieldFactory(TextArea);

export const CheckboxField = ({ value, label, type = 'checkbox', onChange, placeholder, ...props }) => {
  const { validation, ...typeProps } = typeAttributes[type] || {};
  const validatedOnChange = e => (!validation || validation(props)(e.target.value)) && onChange(e);

  return (
    <FormWrapper>
      <input
        type={type}
        {...typeProps}
        {...props}
        value={value}
        onChange={validatedOnChange}
        id={props.id || props.name}
        placeholder={placeholder || label}
      />{' '}
      <FormLabel htmlFor={props.id || props.name} $required={props.required} $inline>
        {label}
      </FormLabel>
      {props.error && <FieldError>Error when saving: {props.error}</FieldError>}
      {props.errors && props.errors.map((e, i) => <FieldError key={i}>{e}</FieldError>)}
    </FormWrapper>
  );
};

export const Select = ({ children, label, value, ...props }) => (
  <FormWrapper>
    {label && <FormLabel $required={props.required}>{label}</FormLabel>}
    <SelectInput {...props} value={value} style={{ background: 'white' }}>
      {children}
    </SelectInput>
    {props.error && <FieldError>Error when saving: {props.error}</FieldError>}
    {props.errors && props.errors.map(e => <FieldError>{e}</FieldError>)}
  </FormWrapper>
);

export default fieldFactory(Input);
