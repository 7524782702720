import React from 'react';
import { getLocalPrinters, statusCodes } from './printing';
import StatusMessage from './StatusMessage';

class SelectPrinter extends React.Component {
  state = {
    printers: {},
  };

  componentDidMount() {
    getLocalPrinters().then(printers => this.setState({ printers }), () => this.setState({ printers: {} }));
  }
  render() {
    const { selectedPrinter, onChange, printerStatus } = this.props;
    return (
      <div>
        {Object.keys(this.state.printers).length > 0 && (
          <div>
            <select
              value={selectedPrinter ? selectedPrinter.uid : ''}
              onChange={e => onChange(this.state.printers[e.target.value])}
              style={{ maxWidth: '250px' }}
            >
              {!selectedPrinter && <option value="">- Select printer -</option>}
              {Object.keys(this.state.printers)
                .map(id => this.state.printers[id])
                .map(printer => (
                  <option key={printer.uid} value={printer.uid}>
                    Printer: {printer.name}
                  </option>
                ))}
            </select>
          </div>
        )}
        {printerStatus && (
          <StatusMessage $error={printerStatus[0] !== 1}>
            Printer status:{' '}
            {(Array.isArray(printerStatus) && printerStatus.map(s => statusCodes[s]).join()) || printerStatus}
          </StatusMessage>
        )}
      </div>
    );
  }
}

export default SelectPrinter;
