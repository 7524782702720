import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { send } from './action-creators';
import { toQuery, fromQuery } from '../../utils/uri';
import debounce from 'lodash.debounce';
import { useQuery, gql } from '@apollo/client';

const BATCH_NOTIFICATIONS_QUERY = gql`
  query BatchNotifications($contractId: Int!, $after: String, $before: String, $first: Int, $locationId: Int) {
    contract(id: $contractId) {
      id
      unsentNotifications
      batchNotifications(after: $after, before: $before, first: $first, locationId: $locationId) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            batchId
            registeredDate
            currentLocations {
              currentLocation {
                id
                name
              }
              consignments {
                id
                consignmentNumber
                deliveryLocation {
                  id
                  name
                }
                receiverUser {
                  firstName
                  lastName
                  email
                }
                registeredDate
              }
            }
          }
        }
      }
    }
  }
`;

const WithBatchNotifications = ({ contractId, send, children, location }) => {
  const [locationId, setLocationId] = useState(null);
  const queryParams = location ? fromQuery(location.search) : {};

  useEffect(() => {
    const debounceQuery = debounce((query, history, match, queryParams) => {
      history.replace(
        `${match.path}?${toQuery({
          query,
          ...(queryParams.first && {
            first: queryParams.first,
          }),
        })}`
      );
    }, 300);

    return () => {
      debounceQuery.cancel();
    };
  }, []);

  useEffect(() => {
    if (location && location.search) {
      const queryData = fromQuery(location.search);
      if (queryData.locationId !== undefined) {
        setLocationId(parseInt(queryData.locationId));
      }
    }
  }, [location]);

  const { data, refetch } = useQuery(BATCH_NOTIFICATIONS_QUERY, {
    variables: {
      ...queryParams,
      contractId: parseInt(contractId),
      locationId: locationId || 0,
    },
    // Until we mutate in the new consignments, we need to always get the updated list from the server
    fetchPolicy: 'network-only',
  });

  const handleSend = (ids) => {
    return send(ids)
      .then((result) => {
        return result;
      })
      .then(() => {
        refetch();
      });
  };

  return children({ data, send: handleSend });
};

const mapStateToProps = (state) => ({ contractId: state.contracts.selected.id });
const mapDispatchToProps = { send };

export default connect(mapStateToProps, mapDispatchToProps)(WithBatchNotifications);
