import React from 'react';
import { withStyle } from 'styletron-react';
import List, { BaseLi } from '../../../components/List';
import Shipment from './ShipmentListItem';
import CSSTransitionGroup from 'react-addons-css-transition-group';
import './shipmentlist.css';

const Li = withStyle(BaseLi, {
  background: 'white',
});

const transitionTime = 750;

export default ({ shipments, onRemove }) => (
  <List>
    <CSSTransitionGroup
      transitionName="shipments"
      transitionEnterTimeout={transitionTime}
      transitionLeaveTimeout={transitionTime}
    >
      {shipments.map(shipment => (
        <Li key={shipment.consignmentNumber}>
          <Shipment {...shipment} remove={() => onRemove(shipment.consignmentNumber)} />
        </Li>
      ))}
    </CSSTransitionGroup>
  </List>
);
