import React, { Fragment, useState } from 'react';
import Form from '../Form';
import Notification from '../../../utils/promiseNotification';
import { connect } from 'react-redux';
import { PageHeader, PageHeading, PageContent } from '../../../components/Page';
import Button from '../../../components/Button';
import GraphQLProvider from '../../../components/GraphQLProvider';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const USER_QUERY = gql`
  query ServiceUserForm($contractId: Int!, $serviceUserId: Int!) {
    contract(id: $contractId) {
      id
      serviceUser(id: $serviceUserId) {
        id
        firstName
        lastName
        email
        phoneNumber
        referenceId
        status
        contract {
          id
          name
        }
        letterLocation {
          id
          name
          referenceId
        }
        parcelLocation {
          id
          name
          referenceId
        }
        customFields {
          key
          value
        }
        assignee {
          id
          firstName
          lastName
          email
        }
        isAssigneeFor {
          id
          firstName
          lastName
          email
        }
        isAssignee
      }
    }
  }
`;

const DELETE_USER_MUTATION = gql`
  mutation ServiceUserRemove($input: ServiceUserRemoveInputType!) {
    serviceUserRemove(input: $input) {
      id
      status
    }
  }
`;

const EditServiceUser = ({ contract, serviceUserId, disabled, updateServiceUser, history }) => {
  const [deleteEnabled, setDeleteEnabled] = useState(false);

  const toggleEnableDelete = () => setDeleteEnabled((prevState) => !prevState);

  const { data, loading, refetch } = useQuery(USER_QUERY, {
    variables: { contractId: parseInt(contract.id), serviceUserId: parseInt(serviceUserId) },
  });

  const [deleteUser] = useMutation(DELETE_USER_MUTATION, {
    variables: { input: { id: parseInt(serviceUserId) } },
  });

  const handleUpdate = (values, refetch) => {
    return updateServiceUser(values)
      .then((res) => {
        refetch({ skipCache: true });
        return res;
      })
      .then(Notification.success('Recipient saved'))
      .catch(Notification.error('Error: Recipient not saved'));
  };

  const handleDelete = () => {
    return deleteUser({ input: { id: serviceUserId } })
      .then((res) => {
        Notification.success('Recipient deleted')(res);
        history.push('/recipients');
      })
      .catch((error) => {
        console.error(error);
        Notification.error('Error: Deleting recipient failed')(error);
      });
  };

  return (
    <div>
      <PageHeader>
        <PageHeading>Edit Recipient</PageHeading>
      </PageHeader>
      <PageContent>
        <GraphQLProvider>
          {data && (
            <Form
              serviceUser={{
                ...data.contract.serviceUser,
                customFields: (data.contract.serviceUser ? data.contract.serviceUser.customFields : []).reduce(
                  (acc, curr) => ({ ...acc, [curr.key]: curr.value }),
                  {}
                ),
              }}
              save={(values) => handleUpdate(values, refetch)}
              contract={contract}
              disabled={disabled || loading || (data && data.contract.serviceUser.status === 'Inactive')}
              enableAssigneeUserInput={data && !!!data.contract.serviceUser.isAssignee}
              actions={
                <Fragment>
                  {data && data.contract.serviceUser.status === 'Inactive' && <span>This user is deactivated</span>}
                  {data && data.contract.serviceUser.status === 'Active' && (
                    <Fragment>
                      <label>
                        Check to enable delete button{' '}
                        <input type="checkbox" checked={deleteEnabled} onChange={toggleEnableDelete} />
                      </label>
                      <Button
                        disabled={!data || disabled || !deleteEnabled || data.contract.serviceUser.status !== 'Active'}
                        onClick={handleDelete}
                        danger
                      >
                        Delete
                      </Button>
                    </Fragment>
                  )}
                </Fragment>
              }
            />
          )}
        </GraphQLProvider>
      </PageContent>
    </div>
  );
};

export default connect((state, props) => ({
  serviceUserId: props.match.params.serviceUserId,
  contract: state.contracts.selected,
}))(EditServiceUser);
