import React, { Fragment } from 'react';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import userManager from '../utils/userManager';
import Header from './Header';
import Loader from './Loader';
import { WithViewport } from './Viewport';

export default function Wrapper({ location, user, children }) {
  return (
    <WithViewport>
      {({ isMd }) => (
        <Fragment>
          <NotificationContainer />
          <Header signout={() => userManager.signoutRedirect()} user={user} location={location} isCompact={!isMd} />
          {user ? children : <Loader />}
        </Fragment>
      )}
    </WithViewport>
  );
}
