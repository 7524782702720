import React from 'react';
import ShipmentList from '../../ShipmentList';
import withBatch from '../../../batch/withBatch';
import Print from '../../../../components/Print/PrintShipments';
import { pathBuilder } from '../../../../utils/fetch';
import { PageHeading, PageHeader, PageContent } from '../../../../components/Page';
import { ErrorMessage } from '../../../../components/MiscPresentational';

const comparePropertyLocators = (a, b) =>
  a === b ? true : Array.isArray(a) && Array.isArray(b) ? a.join() === b.join() : false;

const locateValue = (item, propertyLocator) =>
  Array.isArray(propertyLocator)
    ? propertyLocator.reduce((value, property) => value[property], item)
    : item[propertyLocator];

const sort = (items, propertyLocator, ascending) =>
  [].concat(items).sort((a, b) => {
    const aValue = locateValue(a, propertyLocator);
    const bValue = locateValue(b, propertyLocator);
    return aValue < bValue ? (ascending ? -1 : 1) : aValue > bValue ? (ascending ? 1 : -1) : 0;
  });

class Summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      property: 'shipmentNumber',
      ascending: true,
    };
  }
  onSortChanged(property) {
    this.setState(state => ({
      property,
      ascending: comparePropertyLocators(property, state.property) && !state.ascending,
    }));
  }
  itemSelected(shipmentId) {
    this.props.history.push(pathBuilder('/shipments', shipmentId));
  }
  render() {
    const { batch, contracts } = this.props;
    const { property, ascending } = this.state;

    if (!batch) {
      return <div>Loading summary...</div>;
    }
    const batchNotInContract = batch.contractId !== contracts.selected.id;
    const batchContract = contracts.items.filter(item => item.id === batch.contractId)[0];
    const sorted = sort(batch.items, property, ascending);

    return (
      <div>
        <PageHeader>
          <PageHeading>Summary of registered shipments</PageHeading>
        </PageHeader>
        <PageContent>
          {!batchNotInContract && <p>Registered items: {batch.items.length}</p> && (
            <ShipmentList
              shipments={sorted}
              onSortChanged={property => this.onSortChanged(property)}
              onItemSelected={shipmentId => this.itemSelected(shipmentId)}
              disabled={batchNotInContract}
            />
          )}
          {batchNotInContract && (
            <ErrorMessage>
              {`${batch.items.length > 1 ? 'These shipments belong' : 'This shipment belongs'} to ${(batchContract &&
                batchContract.name) ||
                'another contract'}`}
            </ErrorMessage>
          )}
          <Print shipments={batch.items} />
        </PageContent>
      </div>
    );
  }
}

export default withBatch(Summary);
