import fetch from '../../utils/fetch';

export const CONTRACT_LOAD = 'CONTRACT_LOAD';
export const CONTRACT_LOAD_SUCCESS = 'CONTRACT_LOAD_SUCCESS';
export const CONTRACT_SELECT = 'CONTRACT_SELECT';
export const CONTRACT_CREATE_SUCCESS = 'CONTRACT_CREATE_SUCCESS';
export const CONTRACT_DELETE = 'CONTRACT_DELETE';
export const CONTRACT_EDIT = 'CONTRACT_EDIT';
export const CONTRACT_EDIT_SUCCESS = 'CONTRACT_EDIT_SUCCESS';
export const CONTRACT_EDIT_ERROR = 'CONTRACT_EDIT_ERROR';
export const CONTRACT_EDIT_CUSTOMFIELDS = 'CONTRACT_EDIT_CUSTOMFIELDS';
export const CONTRACT_EDIT_CUSTOMFIELDS_SUCCESS = 'CONTRACT_EDIT_CUSTOMFIELDS_SUCCESS';
export const CONTRACT_EDIT_CUSTOMFIELDS_ERROR = 'CONTRACT_EDIT_CUSTOMFIELDS_ERROR';

export const load = contractId => (dispatch, getState) => {
  dispatch({
    type: CONTRACT_LOAD,
    contractId,
  });
  const contractFetch = fetch(`api/contract/${contractId}`)(dispatch, getState);
  const templateFetch = fetch(`api/contract/${contractId}/template`)(dispatch, getState);
  return Promise.all([contractFetch, templateFetch]).then(results => {
    dispatch({
      type: CONTRACT_LOAD_SUCCESS,
      contractId,
      data: results.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    });
  });
};

export const selectContract = (contractId, locationId) => {
  return {
    type: CONTRACT_SELECT,
    contractId,
    locationId,
  };
};

export const createContract = contract => (dispatch, getState) =>
  fetch('api/contract', {
    method: 'POST',
    body: JSON.stringify(contract),
  })(dispatch, getState).then(result => {
    dispatch({
      type: CONTRACT_CREATE_SUCCESS,
      data: result,
    });
    return result;
  });

export const editContract = contract => (dispatch, getState) => {
  dispatch({
    type: CONTRACT_EDIT,
    contractId: contract.id,
    data: contract,
  });
  return fetch(`api/contract/${contract.id}`, {
    method: 'PUT',
    body: JSON.stringify(contract),
  })(dispatch, getState).then(
    () => {
      dispatch({
        type: CONTRACT_EDIT_SUCCESS,
        contractId: contract.id,
        data: contract,
      });
    },
    error => {
      dispatch({
        type: CONTRACT_EDIT_ERROR,
        contractId: contract.id,
        data: error,
      });
      return Promise.reject(error);
    }
  );
};

export const deleteContract = id => {
  return {
    type: CONTRACT_DELETE,
    contractId: id,
  };
};

const cleanCustomFields = customFields =>
  Object.keys(customFields).reduce(
    (acc, groupKey) => ({
      ...acc,
      [groupKey]: (customFields[groupKey] || []).filter(customField => customField.name),
    }),
    {}
  );

export const editCustomFields = (contractId, customFields) => (dispatch, getState) => {
  const cleaned = cleanCustomFields(customFields);
  dispatch({
    type: CONTRACT_EDIT_CUSTOMFIELDS,
    contractId,
  });
  return fetch(`api/contract/${contractId}/template`, {
    method: 'PUT',
    body: JSON.stringify(cleaned),
  })(dispatch, getState).then(
    () => {
      dispatch({
        type: CONTRACT_EDIT_CUSTOMFIELDS_SUCCESS,
        contractId,
      });
    },
    error => {
      dispatch({
        type: CONTRACT_EDIT_CUSTOMFIELDS_ERROR,
        contractId,
      });
      return Promise.reject(error);
    }
  );
};
