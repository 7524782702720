import React from 'react';
import { state as asyncState } from '../../utils/fetch';
import { connect } from 'react-redux';
import Page, { PageHeader, PageHeading, PageContent } from '../../components/Page';
import WithConfig from './WithConfig';
import Form from './Form';
import { updateOption } from './action-creators';
import ErrorBoundary from '../../components/ErrorBoundary';

class AppConfigPage extends React.Component {
  updateConfig = values => {
    const { config, update } = this.props;
    return Promise.all(
      config.options.map(
        option =>
          option.value !== values[option.id] &&
          update({
            ...option,
            value: values[option.id],
          })
      )
    );
  };

  render() {
    const { isAdmin, config, configIsLoaded } = this.props;
    return (
      <ErrorBoundary>
        <Page noContractSelector>
          <PageHeader>
            <PageHeading>Configure SmartFlow</PageHeading>
          </PageHeader>
          <PageContent>
            <Form
              disabled={!isAdmin || !configIsLoaded}
              options={config.options.reduce((acc, option) => {
                acc[option.id] = option.value;
                return acc;
              }, {})}
              config={config}
              save={this.updateConfig}
            />
          </PageContent>
        </Page>
      </ErrorBoundary>
    );
  }
}

const ConfigPage = connect(
  state => ({
    isAdmin: state.authentication.isAdmin,
    config: state.config,
    configIsLoaded: state.config.state === asyncState.done,
  }),
  {
    update: values => updateOption(values),
  }
)(AppConfigPage);

export default () => <WithConfig>{config => <ConfigPage config={config} />}</WithConfig>;
