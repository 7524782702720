import React from 'react';
import { styled } from 'styletron-react';

const svgProps = {
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
  x: '0px',
  y: '0px',
  width: '18px',
  fill: 'white',
  height: '18px',
  viewBox: '0 0 512 512',
  enableBackground: 'new 0 0 512 512',
  xmlSpace: 'preserve',
};

const Svg = styled('svg', {
  maxHeight: '100%',
  maxWidth: '100%',
  marginBottom: '-4px',
  marginLeft: '4px',
  transformOrigin: 'unset',
});

const Icon = path => props => (
  <Svg {...svgProps} {...props}>
    {path}
  </Svg>
);

export default Icon;
