import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import GraphQLProvider from '../../../components/GraphQLProvider';
import { PageHeader, PageHeading, PageContent } from '../../../components/Page';
import List from '../../../components/List';
import Location from './Location';

import { LOCATIONS_QUERY } from '../graph';

const LocationsIndex = ({ contractId, match }) => {
  const { loading, error, data } = useQuery(LOCATIONS_QUERY, {
    variables: { contractId: parseInt(contractId) },
    fetchPolicy: 'network-only',
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <GraphQLProvider>
      <Fragment>
        <PageHeader>
          <PageHeading>Locations</PageHeading>
        </PageHeader>
        <PageContent>
          <List>
            {data.contract.locations
              .filter((location) => location.status !== 'InActive')
              .map((l) => (
                <Location key={l.id} level={0} {...l} match={match} />
              ))}
          </List>
        </PageContent>
      </Fragment>
    </GraphQLProvider>
  );
};

export default LocationsIndex;
