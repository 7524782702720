import React from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import ContractSelector from '../../components/ContractSelector';
import ErrorBoundary from '../../components/ErrorBoundary';
import Page, { TabNav, makeTab, activeTabStyle } from '../../components/Page';
import Import from './import';
import Add from './Add';
import Edit from './Edit';
import LocationsIndex from './components/LocationsIndex';
import { addLocation, updateLocation } from './action-creators';
import GraphQLProvider from '../../components/GraphQLProvider';

const Tab = makeTab(NavLink);

const Locations = ({ addLocation, updateLocation, contractId, match }) => (
  <ErrorBoundary>
    <GraphQLProvider>
      <ContractSelector />
      <Page>
        <TabNav outOfBase>
          <Tab
            exact
            to={`${match.path}`}
            activeStyle={activeTabStyle}
            isActive={(match, location) => {
              if (!match) {
                return location.pathname.startsWith('/locations/edit');
              }
              return match.path === location.pathname;
            }}
          >
            Locations
          </Tab>
          <Tab to={`${match.path}/add`} activeStyle={activeTabStyle}>
            Add
          </Tab>
          <Tab to={`${match.path}/import`} activeStyle={activeTabStyle}>
            Import
          </Tab>
        </TabNav>
        <Switch>
          <Route path={`${match.url}/import`} component={Import} />
          <Route
            path={`${match.url}/add`}
            component={() => (
              // TOMAS: This might not be necessary anymore!!!
              // <Add addLocation={location => addLocation(location, contractId).then(client.resetStore())} />
              <Add addLocation={location => addLocation(location, contractId)} />
            )}
          />
          <Route
            path={`${match.url}/edit/:locationId`}
            component={props => (
              <Edit
                updateLocation={location => updateLocation(location, contractId)}
                contractId={contractId}
                {...props}
              />
            )}
          />
          <Route
            component={({ match }) => (
              <LocationsIndex match={match} contractId={contractId} />
            )}
          />
        </Switch>
      </Page>
    </GraphQLProvider>
  </ErrorBoundary>
);

const mapStateToProps = state => ({
  contractId: state.contracts.selected.id,
});
const mapDispatchToProps = {
  addLocation,
  updateLocation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Locations);
