import { gql } from '@apollo/client';

export const SET_CURRENT_LOCATION_MUTATION = gql`
  mutation ConsignmentSetCurrentLocation($input: ConsignmentsSetCurrentLocationsInputType!) {
    consignmentSetCurrentLocation(input: $input) {
      id
      consignmentNumber
      currentLocation {
        id
        name
      }
    }
  }
`;
