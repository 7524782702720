export const htmlToMarkdown = htmlString => {
  // Replace HTML tags with Markdown equivalents
  let markdownString = htmlString
    .replace(/<h1>(.*?)<\/h1>/g, '# $1')
    .replace(/<h2>(.*?)<\/h2>/g, '## $1')
    .replace(/<h3>(.*?)<\/h3>/g, '### $1')
    .replace(/<h4>(.*?)<\/h4>/g, '#### $1')
    .replace(/<p>(.*?)<\/p>/g, '$1')
    .replace(/<p>(.*?)/g, '$1')
    .replace(/<br>(.*?)/g, '\n')
    .replace(/<br\s*\/?\s*>/gi, '\n')
    .replace(/<hr\s*\/?\s*>/gi, '\n')
    .replace(/<a\s+href="(.*?)">(.*?)<\/a>/g, '[$2]($1)')
    .replace(/<ul>(.*?)<\/ul>/gs, (match, p1) => {
      const listItems = p1.replace(/<li>(.*?)<\/li>/g, '- $1');
      return `${listItems}`;
    })
    .replace(/<ol>(.*?)<\/ol>/gs, (match, p1) => {
      const listItems = p1.replace(/<li>(.*?)<\/li>/g, '1. $1');
      return `${listItems}`;
    });

  return markdownString;
};
