import React from 'react';
import { Input } from '../../components/Input';
import SearchIcon from '../Icons/SearchIcon';
import Spinner from '../Spinner';

const SearchField = React.forwardRef(({ loading, placeholder, ...rest }, ref) => (
  <Input
    type="text"
    placeholder={placeholder || 'Search'}
    icon={loading ? <Spinner /> : <SearchIcon fill={'currentColor'} />}
    ref={ref}
    {...rest}
  />
));

export default SearchField;
