import { AUTH_LOAD_SUCCESS } from '../../authentication/action-creators';
import { state as asyncState } from '../../utils/fetch';
import { getUserData } from '../../components/UserData';
import {
  CONTRACT_LOAD,
  CONTRACT_LOAD_SUCCESS,
  CONTRACT_SELECT,
  CONTRACT_CREATE_SUCCESS,
  CONTRACT_EDIT,
  CONTRACT_EDIT_SUCCESS,
  CONTRACT_EDIT_ERROR,
  CONTRACT_EDIT_CUSTOMFIELDS,
  CONTRACT_EDIT_CUSTOMFIELDS_SUCCESS,
  CONTRACT_EDIT_CUSTOMFIELDS_ERROR,
  CONTRACT_DELETE,
} from './action-creators';

const defaultState = {
  items: [],
  selected: undefined,
};

const contractStructure = {
  id: undefined,
  name: '',
  state: asyncState.notTriggered,
  isEditable: false,
  consignmentFields: [],
  letterFields: [],
  parcelFields: [],
  serviceUserFields: [],
  isLoadedOnce: true,
  isFullyLoaded: false,
};

const withUpdatedContract = (state, contractId, enrich) => {
  const items = state.items.map(c =>
    c.id !== contractId
      ? c
      : {
          ...c,
          ...enrich,
        }
  );
  return {
    ...state,
    items,
    selected: items.filter(c => c.id === contractId)[0],
  };
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_LOAD_SUCCESS:
      const contracts = action.data.allowedContracts
        ? action.data.allowedContracts.map(c => ({
            ...contractStructure,
            ...c,
          }))
        : [];

      const userData = getUserData();

      const selected = {
        ...(contracts.filter(c => c.id === +userData.main.currentContract)[0] || contracts[0]),
        selectedLocationId: +userData.main.currentLocation,
      };
      return {
        ...state,
        items: contracts.map(c => (c.id === selected.id ? selected : c)),
        selected,
      };
    case CONTRACT_CREATE_SUCCESS:
      return {
        ...state,
        items: state.items.concat({
          ...contractStructure,
          ...action.data,
        }),
      };
    case CONTRACT_DELETE:
      return {
        ...state,
        items: state.items.filter(i => i.id !== action.contractId),
      };
    case CONTRACT_EDIT:
    case CONTRACT_EDIT_CUSTOMFIELDS:
      return withUpdatedContract(state, action.contractId, {
        state: asyncState.pushing,
        isEditable: false,
      });
    case CONTRACT_EDIT_SUCCESS:
    case CONTRACT_EDIT_CUSTOMFIELDS_SUCCESS:
      return withUpdatedContract(state, action.contractId, {
        state: asyncState.notTriggered,
      });
    case CONTRACT_EDIT_ERROR:
    case CONTRACT_EDIT_CUSTOMFIELDS_ERROR:
      return withUpdatedContract(state, action.contractId, {
        state: asyncState.done,
        isEditable: true,
      });
    case CONTRACT_LOAD:
      return withUpdatedContract(state, action.contractId, {
        state: asyncState.fetching,
      });
    case CONTRACT_LOAD_SUCCESS:
      const { contractId, ...enrich } = action.data;
      return withUpdatedContract(state, contractId, {
        ...enrich,
        state: asyncState.done,
        isEditable: true,
        isFullyLoaded: true,
      });
    case CONTRACT_SELECT:
      const selectedContract = action.contractId
        ? state.items.filter(c => c.id === action.contractId)[0]
        : state.items[0];

      return {
        ...state,
        selected: {
          ...selectedContract,
          selectedLocationId: +action.locationId,
        },
      };
    default:
      return state;
  }
};
