import React from 'react';
import { connect } from 'react-redux';
import { styled } from 'styletron-react';
import style from '../../../../style';
import Button from '../../../../components/Button';
import CustomField from './CustomField';
import { load } from '../../action-creators';

const GroupList = styled('ul', {
  padding: 0,
  listStyle: 'none',
});

const GroupBase = styled('div', {
  borderLeft: `5px solid ${style.primary}`,
  padding: '5px 20px 5px 15px',
  marginBottom: '20px',
});

const GroupTitle = styled('h3', {
  margin: '4px 0 10px 0',
});

const GroupError = styled('div', {
  color: style.red,
});

const withEditedGroupItem = (items, itemIndex, item) => items.map((_, i) => (i === itemIndex ? item : _));

const Group = ({ contractId, label, items, onAdd, onChange, disabled, errors, loadContract }) => (
  <GroupBase>
    <GroupTitle>{label}</GroupTitle>
    <GroupList>
      {items.map((item, i) => (
        <CustomField
          key={i}
          {...item}
          isDisabled={item.disabled || disabled}
          onChange={item => onChange(withEditedGroupItem(items, i, item))}
          onRemove={() => onChange(items.filter((_, itemi) => i !== itemi))}
        />
      ))}
    </GroupList>
    {errors && errors.map(e => <GroupError>{e}</GroupError>)}
    <div>
      <Button disabled={disabled} onClick={onAdd}>
        Add new
      </Button>
    </div>
  </GroupBase>
);

const mapDispatchToProps = {
  loadContract: load,
};

export default connect(
  null,
  mapDispatchToProps
)(Group);
