import { createUserManager } from 'redux-oidc';
import { Log } from 'oidc-client';

if (process.env.NODE_ENV !== 'production') {
  Log.logger = console;
  Log.level = Log.DEBUG;
}

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port ? `:${window.location.port}` : '';
const protocolAndHost = `${protocol}//${hostname}${port}`;

const applicationId = process.env.REACT_APP_AZURE_AD_CLIENT_ID;
const tenantId = process.env.REACT_APP_AZURE_AD_TENANT_ID;

const authority = `https://login.microsoftonline.com/${tenantId}/`;
var apiUri = process.env.REACT_APP_API_URI;

const settings = {
  authority,
  client_id: applicationId,
  redirect_uri: `${protocolAndHost}/callback`,
  response_type: 'id_token token',
  scope: 'openid profile Directory.AccessAsUser.All',
  resource: applicationId,

  silent_redirect_uri: `${protocolAndHost}/silentrenew`,
  includeIdTokenInSilentRenew: false,
  automaticSilentRenew: true,
  silentRequestTimeout: 10000,
  accessTokenExpiringNotificationTime: 60,
  post_logout_redirect_uri: `${protocolAndHost}/signout-callback-oidc`,

  filterProtocolClaims: true,
  loadUserInfo: false,
  metadata: {
    issuer: `https://sts.windows.net/${tenantId}/`,
    jwks_uri: `${apiUri}/keys/index/${tenantId}`,
    end_session_endpoint: `${authority}/oauth2/logout`,
    authorization_endpoint: `${authority}/oauth2/authorize`,
  },
};

const userManager = createUserManager(settings);

export default userManager;
