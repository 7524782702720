import { styled, withStyle } from 'styletron-react';
import style from '../style';

export const Row = styled('div', {
  display: 'flex',
  margin: '0 -10px',
  flexDirection: 'column',
  ...style.sm({
    flexDirection: 'row',
  }),
});

export const Col = styled('div', {
  flexGrow: 1,
  flexBasis: 0,
  padding: '0 10px',
  margin: 0,
});

export const NoOffsetCol = withStyle(Col, {
  padding: '20px',
});

export const Well = withStyle(NoOffsetCol, {
  background: style.light,
});

export const RightAlign = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

export const Container = styled('div', {
  margin: '0 auto',
  padding: `0 ${style.compactBodyPadding}`,
  maxWidth: '1500px',
  ...style.md({
    paddingleft: '20px',
    paddingRight: '20px',
  }),
  ...style.lg({
    padding: '0 40px',
  }),
  ...style.xl({
    maxWidth: `${style.viewports.xl}px`,
  }),
});
