import React from 'react';
import { DateRangePicker } from 'react-dates';

export default ({ startDate, endDate, onDatesChange, focusedInput, onFocusChange, onClose, isOutsideRange }) => (
  <DateRangePicker
    startDate={startDate}
    endDate={endDate}
    onDatesChange={onDatesChange}
    focusedInput={focusedInput}
    onFocusChange={onFocusChange}
    onClose={onClose}
    isOutsideRange={isOutsideRange}
    startDateId="startDate"
    endDateId="endDate"
    firstDayOfWeek={1}
    minimumNights={0}
    displayFormat="DD-MM-YYYY"
  />
);
