import React from 'react';
import { styled } from 'styletron-react';
import { getDefaultPrinter, getLocalPrinters, getPrinterStatus, statusCodes } from './printing';
import Button from '../Button';
import StatusMessage from './StatusMessage';

const PrintSection = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
});

const ButtonWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  minHeight: '40px',
});

export default class Print extends React.Component {
  state = {
    selectedPrinter: undefined,
    printers: {},
    printerStatus: [],
    error: undefined,
  };
  componentDidMount() {
    getDefaultPrinter()
      .then((selectedPrinter) => {
        this.setState({ selectedPrinter });
        return selectedPrinter;
      })
      .then((printer) => getPrinterStatus(printer))
      .then((printerStatus) => this.setState({ printerStatus }))
      .catch((error) => {
        !Array.isArray(error) && this.setState({ error });
        Array.isArray(error) && this.setState({ printerStatus: error });
        return error;
      });
    getLocalPrinters().then(
      (printers) => this.setState({ printers }),
      () => this.setState({ printers: {} })
    );
  }
  changePrinter(id) {
    const printer = this.state.printers[id];
    this.setState({
      selectedPrinter: printer,
      printerStatus: [],
    });
    getPrinterStatus(printer).then((printerStatus) => this.setState({ printerStatus }));
  }
  render() {
    const { selectedPrinter, printerStatus, error } = this.state;
    const { disabled, onPrint, children } = this.props;
    return (
      <PrintSection>
        <ButtonWrapper>
          <Button
            primary
            type="button"
            onClick={() => onPrint(selectedPrinter).catch((printerStatus) => this.setState({ printerStatus }))}
            disabled={!(selectedPrinter !== undefined && printerStatus[0] === 1) || disabled}
          >
            {children || 'Print sticker'}
          </Button>
          {printerStatus && (
            <StatusMessage $error={printerStatus[0] !== 1}>
              {printerStatus.length > 0 ? printerStatus.map((s) => statusCodes[s]).join() : error}
            </StatusMessage>
          )}
        </ButtonWrapper>
      </PrintSection>
    );
  }
}
