import { styled } from 'styletron-react';
import style from '../style';

const EmptyState = styled('div', {
  textAlign: 'center',
  fontSize: '2rem',
  color: style.grey,
  padding: `${style.basePaddingVertical * 5}px ${style.basePaddingHorizontal}px`,
});

export default EmptyState;
