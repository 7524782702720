// import React from 'react';
// import { Provider as StyletronProvider } from 'styletron-react';
// import { Client as StyletronClient } from 'styletron-engine-atomic';
import * as colors from './colors.js';
import * as spacings from './spacings.js';
import * as elementstyling from './elementstyling.js';
import * as fonts from './fonts.js';
import responsive from './responsive';
import './main.css';

// const styletron = new StyletronClient();

// const StyleProvider = ({ children }) => <StyletronProvider value={styletron}>{children}</StyletronProvider>;

const important = style =>
  Object.keys(style).reduce(
    (acc, key) => ({
      ...acc,
      [key]: `${style[key]} !important`,
    }),
    {}
  );

const multiStyle = (selectors, style) => selectors.reduce((acc, s) => ({ ...acc, [s]: style }), {});

export { important, multiStyle };

export default {
  ...colors,
  ...spacings,
  ...responsive,
  ...elementstyling,
  ...fonts,
};
