import React from 'react';
import { state as asyncState } from '../../utils/fetch';
import { connect } from 'react-redux';
import { fetchBatch } from './action-creators';

export default Component => {
  class WithBatch extends React.Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (
        nextProps.batchId &&
        nextProps.batchId !== this.props.batchId &&
        nextProps.state === asyncState.notTriggered
      ) {
        nextProps.fetch();
      }
    }
    componentDidMount() {
      if (
        this.props.batchId &&
        (!this.props.batch ||
          this.props.batch.state === asyncState.notTriggered)
      ) {
        this.props.fetch();
      }
    }
    render() {
      return <Component {...this.props} />;
    }
  }

  return connect(
    (state, { batchId }) => ({
      contracts: state.contracts,
      batch:
        state.shipment.batches[+batchId] ||
        (batchId && {
          id: batchId,
          state: asyncState.notTriggered,
          items: [],
        }),
    }),
    (dispatch, { batchId }) => ({
      fetch: () => dispatch(fetchBatch(batchId)),
    })
  )(WithBatch);
};
