import React from 'react';
import { styled } from 'styletron-react';
import { Input } from './Input';

const IdentityVerificationFieldWrapper = styled('div', {
  width: '10rem',
});
const FieldHeading = styled('div', {
  margin: '1.2em 0 10px',
});

const IdentityVerificationField = props => (
  <IdentityVerificationFieldWrapper>
    <FieldHeading>Identity verification</FieldHeading>
    <Input autoFocus onChange={e => props.onChange(e.target.value)} />
  </IdentityVerificationFieldWrapper>
);

export default IdentityVerificationField;
