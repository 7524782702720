import React, { Fragment } from 'react';
import { Route, Switch, Redirect, NavLink } from 'react-router-dom';
import Register from './register';
import Search from './search';
import BatchNotifications from '../batchNotifications';
import ContractSelector from '../../components/ContractSelector';
import DetailsView from './DetailsView';
import Page, { TabNav, makeTab, activeTabStyle, TabBadge } from '../../components/Page';
import WithShipment from './withShipment';
import { WithViewport } from '../../components/Viewport';
import Compact from './Compact';
import Statistics from './Statistics';
import ErrorBoundary from '../../components/ErrorBoundary';
import { connect } from 'react-redux';
import GraphQLProvider from '../../components/GraphQLProvider';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

const Tab = makeTab(NavLink);

export const TABS_COUNT_QUERY = gql`
  query BatchNotifications($contractId: Int!) {
    contract(id: $contractId) {
      id
      unsentNotifications
    }
  }
`;

const Tabs = ({ contractId, match }) => {
  const { data, loading } = useQuery(TABS_COUNT_QUERY, { variables: { contractId: parseInt(contractId) } });

  return (
    <TabNav>
      <Fragment>
        <Tab to={`${match.path}/register`} activeStyle={activeTabStyle}>
          Register Shipment
        </Tab>
        <Tab to={`${match.path}/search`} activeStyle={activeTabStyle}>
          Existing Shipments
        </Tab>
        <Tab to={`${match.path}/pending-notifications`} activeStyle={activeTabStyle}>
          Unsent notifications
          <TabBadge count={data ? data.contract.unsentNotifications : 0} fetching={loading} />
        </Tab>
        <Tab to={`${match.path}/statistics`} activeStyle={activeTabStyle}>
          Statistics
        </Tab>
      </Fragment>
    </TabNav>
  );
};

const ConnectedTabs = connect((state, props) => ({
  contractId: state.contracts.selected && state.contracts.selected.id,
}))(Tabs);

const Shipment = ({ match }) => (
  <ErrorBoundary>
    <GraphQLProvider>
      <WithViewport>
        {({ isMd }) =>
          isMd ? (
            <Fragment>
              <ContractSelector />
              <Page>
                <ConnectedTabs match={match} outOfBase />
                <Switch>
                  <Route path={`${match.url}/register`} component={Register} />
                  <Route path={`${match.url}/search`} component={Search} />
                  <Route path={`${match.url}/pending-notifications`} component={BatchNotifications} />
                  <Route path={`${match.url}/statistics`} component={Statistics} />
                  <Route
                    path={`${match.url}/:shipmentId`}
                    component={(routeProps) => (
                      <WithShipment shipmentId={routeProps.match.params.shipmentId}>
                        {(shipmentProps) => <DetailsView {...shipmentProps} />}
                      </WithShipment>
                    )}
                  />
                  <Redirect to={`${match.url}/register`} />
                </Switch>
              </Page>
            </Fragment>
          ) : (
            <Switch>
              <Redirect from={`${match.url}/register`} to={`${match.url}`} />
              <Redirect from={`${match.url}/search`} to={`${match.url}`} />
              <Redirect from={`${match.url}/pending-notifications`} to={`${match.url}`} />
              <Redirect from={`${match.url}/statistics`} to={`${match.url}`} />
              <Route
                path={`${match.url}/:shipmentId`}
                component={(routeProps) => (
                  <Page>
                    <WithShipment shipmentId={routeProps.match.params.shipmentId}>
                      {(shipmentProps) => <DetailsView {...shipmentProps} isCompact />}
                    </WithShipment>
                  </Page>
                )}
              />
              <Route component={() => <Compact match={match} />} />
            </Switch>
          )
        }
      </WithViewport>
    </GraphQLProvider>
  </ErrorBoundary>
);

export default Shipment;
