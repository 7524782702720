import fetch from '../utils/fetch';

export const AUTH_LOAD = 'AUTH_LOAD';
export const AUTH_LOAD_SUCCESS = 'AUTH_LOAD_SUCCESS';

export const load = () => (dispatch, getState) => {
  dispatch({
    type: AUTH_LOAD,
  });
  return fetch('api/auth')(dispatch, getState).then(data => {
    dispatch({
      type: AUTH_LOAD_SUCCESS,
      data,
    });
  });
};
