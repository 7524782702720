import React from 'react';
import Page, { PageHeading, PageHeader, PageContent, PageDescription } from '../../components/Page';
import ErrorBoundary from '../../components/ErrorBoundary';
import Punchcard from '../../components/statistics/Punchcard';
import StatisticType from '../../components/statistics/StatisticType';
import { STATISTICS_QUERY } from './graph';
import GraphQLProvider from '../../components/GraphQLProvider';
import { Query } from '@apollo/client/react/components';

export default () => (
  <ErrorBoundary>
    <Page>
      <PageHeader>
        <PageHeading>Statistics</PageHeading>
      </PageHeader>
      <PageDescription>
        <p>
          This shows the activity for all contracts in Smartflow. The two types are <StatisticType no={0} /> and{' '}
          <StatisticType no={1} />. A larger circle indicates more activity.
        </p>
        <p>The data presented are all shipments ever registered and delivered.</p>
      </PageDescription>
      <GraphQLProvider>
        <PageContent>
          <Query query={STATISTICS_QUERY}>
            {({ data }) => (!data ? null : <Punchcard punchcard={data.statistics.punchcard} />)}
          </Query>
        </PageContent>
      </GraphQLProvider>
    </Page>
  </ErrorBoundary>
);
