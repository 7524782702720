import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Dropdown, { DropdownItem } from '../Dropdown';

const version = process.env.REACT_APP_VERSION;

const UserDropdown = ({ user, isAdmin, location, signout }) => (
  <Dropdown title={user.profile.name}>
    <Fragment>
      {version && <DropdownItem $noHover>{version}</DropdownItem>}
      {isAdmin && (
        <Fragment>
          <DropdownItem $as={NavLink} location={location} to="/config">
            Config
          </DropdownItem>
          <DropdownItem $as={NavLink} location={location} to="/statistics">
            Statistics
          </DropdownItem>
          <DropdownItem $as={NavLink} location={location} to="/invoicing">
            Invoicing
          </DropdownItem>
        </Fragment>
      )}
      <DropdownItem onClick={signout}>Logout</DropdownItem>
    </Fragment>
  </Dropdown>
);

export default UserDropdown;
