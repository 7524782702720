import React from 'react';
import Form from '../Form';
import { connect } from 'react-redux';
import { PageHeader, PageHeading, PageContent } from '../../../components/Page';
import Notification from '../../../utils/promiseNotification';
import { addServiceUser } from '../action-creators';

const AddServiceUser = ({ addServiceUser, contract, disabled, onClose, setServiceUserAsRecipient }) => {
  return (
    <div>
      <PageHeader>
        <PageHeading>Add Recipient</PageHeading>
      </PageHeader>
      <PageContent>
        <Form
          save={(serviceUser) =>
            addServiceUser(serviceUser, contract.id)
              .then(Notification.success('Recipient added'))
              .then((result) => setServiceUserAsRecipient && setServiceUserAsRecipient(result.id))
              .catch(Notification.error('Error: Adding recipient failed'))
          }
          contractId={contract.id}
          contract={contract}
          disabled={disabled}
          serviceUser={{
            customFields:
              (contract.serviceUserFields.length > 0 &&
                contract.serviceUserFields.reduce((obj, field) => {
                  obj[field.name] = '';
                  return obj;
                }, {})) ||
              {},
          }}
          onClose={onClose}
        />
      </PageContent>
    </div>
  );
};

const mapStateToProps = (state) => ({ contract: state.contracts.selected });

const mapDispatchToProps = {
  addServiceUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddServiceUser);
