import fetch from '../../utils/fetch';

export const CONFIG_LOAD = 'CONFIG_LOAD';
export const CONFIG_LOAD_SUCCESS = 'CONFIG_LOAD_SUCCESS';
export const CONFIG_OPTION_LOAD = 'CONFIG_OPTION_LOAD';
export const CONFIG_OPTION_LOAD_SUCCESS = 'CONFIG_OPTION_LOAD_SUCCESS';
export const UPDATE_CONFIG_OPTION = 'UPDATE_CONFIG_OPTION';
export const UPDATE_CONFIG_OPTION_SUCCESS = 'UPDATE_CONFIG_OPTION_SUCCESS';

export const loadConfiguration = () => (dispatch, getState) => {
  dispatch({
    type: CONFIG_LOAD,
  });
  fetch('api/config')(dispatch, getState).then(data => {
    dispatch({
      type: CONFIG_LOAD_SUCCESS,
      data,
    });
  });
};

export const updateOption = option => (dispatch, getState) => {
  dispatch({
    type: UPDATE_CONFIG_OPTION,
  });
  return fetch(`api/config/${option.id}`, {
    method: 'PUT',
    body: JSON.stringify(option),
  })(dispatch, getState).then(res => {
    dispatch({ type: UPDATE_CONFIG_OPTION_SUCCESS, option });
  });
};
