import React from 'react';

const LocationsFilled = props => (
  <svg
    width="16"
    height="24"
    viewBox="0 0 16 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>locationFilled</title>
    <g id="Canvas-1" transform="translate(-731 -4206)">
      <g id="locationFilled">
        <g id="Fill 7">
          <use
            xlinkHref="#locationFilled-path0_fill"
            transform="translate(731 4206)"
            fill={props.fill || '#5C5C5C'}
          />
        </g>
      </g>
    </g>
    <defs>
      <path
        id="locationFilled-path0_fill"
        fillRule="evenodd"
        d="M 8 11.5C 6.07 11.5 4.5 9.929 4.5 8C 4.5 6.071 6.07 4.5 8 4.5C 9.93 4.5 11.5 6.071 11.5 8C 11.5 9.929 9.93 11.5 8 11.5ZM 8 0C 3.589 0 0 3.588 0 8C 0 12.244 7.272 23.307 7.583 23.775C 7.675 23.915 7.832 24 8 24C 8.168 24 8.324 23.915 8.417 23.775C 8.727 23.307 16 12.244 16 8C 16 3.588 12.411 0 8 0Z"
      />
    </defs>
  </svg>
);

export default LocationsFilled;
