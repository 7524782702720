const makeUri = (contractId, subPath) =>
  `${window.location.protocol}//${window.location
    .host}/contracts/${contractId}/${subPath}`;

export const { shipment, location } = {
  shipment: (contractId, shipmentNumber) =>
    makeUri(contractId, `shipments/${shipmentNumber}`),
  location: (contractId, locationId) =>
    makeUri(contractId, `locations/${locationId}`),
};
