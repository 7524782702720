import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { state as asyncState } from '../../utils/fetch';
import { load } from './action-creators';

const ensureContractLoaded = (contract, load) => {
  if (contract.state === asyncState.notTriggered) {
    load(contract.id);
  }
};

const ContractContext = ({ contract, load, children }) => {
  useEffect(() => {
    ensureContractLoaded(contract, load);
  }, [contract, load]);

  return contract.state === asyncState.done || contract.isLoadedOnce ? (
    <div>{typeof children === 'function' ? children({ contract }) : children}</div>
  ) : (
    <div>Loading customer...</div>
  );
};

const mapStateToProps = (state, props) => ({
  contract: props.contract || state.contracts.selected,
});

export default connect(mapStateToProps, { load })(ContractContext);
