import React from 'react';
import { NavLink } from 'react-router-dom';
import { processSilentRenew, CallbackComponent } from 'redux-oidc';
import userManager from '../utils/userManager';
import Loader from './Loader';
import { Container } from './Layout';
import Button from './Button';
import Page, { PageHeader, PageContent, PageHeading } from './Page';

const Callback = ({ history }) => (
  <CallbackComponent
    userManager={userManager}
    successCallback={result => {
      history.push(result.state.localPath || '/');
    }}
    errorCallback={err => console.error(err)}
  >
    <Loader />
  </CallbackComponent>
);

const SilentRenew = () => {
  processSilentRenew();
  return <Loader />;
};

const Logout = () => {
  userManager.signoutRedirectCallback().then(() => console.log('Logged out'));
  return (
    <Container>
      <Page>
        <PageHeader>
          <PageHeading>Successfully signed out</PageHeading>
        </PageHeader>
        <PageContent>
          <NavLink to="/">
            <Button primary>Sign In</Button>
          </NavLink>
        </PageContent>
      </Page>
    </Container>
  );
};

export { Callback, SilentRenew, Logout };
