import React from 'react';
import { styled } from 'styletron-react';
import style from '../../../style';

// Blur actionbuttons as otherwise the scanner will trigger them when they're focused
const ActionButton = ({ onClick, children, label, danger = false, ...props }) => (
  <ActionButtonBase
    type="button"
    {...props}
    onClick={e => {
      e.target.blur();
      onClick(e);
    }}
    $danger={danger}
  >
    {label}
  </ActionButtonBase>
);

const ActionButtonBase = styled('button', ({ $danger }) => ({
  flexGrow: 1,
  background: 'none',
  border: 'none',
  padding: '0.5rem',
  cursor: 'pointer',
  color: $danger ? style.statusRed : 'inherit',
}));

export default ActionButton;
