import React from 'react';
import { styled } from 'styletron-react';
import style, { multiStyle } from '../../style';

export const makeButtonStyle = ({ $primary, $danger, $link } = {}) => ({
  background: style.dark,
  color: 'white',
  padding: '8px 20px',
  border: style.baseBorder,
  boxShadow: style.baseShadow,
  lineHeight: '18px',
  transition: 'opacity 0.2s ease-in-out',
  marginLeft: '1rem',
  ':firstChild': {
    marginLeft: 0,
  },
  ':hover': {
    backgroundColor: style.darkDarker,
    color: '#fff',
  },
  ':active': {
    backgroundColor: style.darkDarker,
    color: '#fff',
  },
  ':focus': {
    backgroundColor: style.darkDarker,
    color: '#fff',
    outline: 'none',
  },
  ...multiStyle([':disabled', ':disabled:hover'], {
    color: style.textColorLight,
    background: style.statusGrey,
    border: 'none',
    opacity: 1,
  }),
  ...($primary && {
    background: style.primary,
    borderColor: style.primary,
    ':hover': {
      backgroundColor: style.primaryDarker,
      color: '#fff',
    },
    ':focus': {
      outline: style.focusBorderRed,
      outlineOffset: '-2px',
      backgroundColor: style.primaryDarker,
      color: '#fff',
    },
  }),
  ...($danger && {
    backgroundColor: style.red,
    borderColor: style.red,
    opacity: 1,
    ...multiStyle([':hover', ':focus'], {
      opacity: '0.8',
    }),
  }),
  ...($link && {
    padding: 0,
    backgroundColor: 'transparent',
    border: 'transparent',
    color: style.primary,
    '-webkit-appearance': 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    ...multiStyle([':active', ':hover', ':focus'], {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      color: style.primaryDarker,
      outline: 'none',
    }),
  }),
});

const Button = styled('button', props => makeButtonStyle(props));

export default ({ children, type = 'button', primary = false, danger = false, link = false, ...rest }) => (
  <Button type={type} $primary={primary} $danger={danger} $link={link} {...rest}>
    {children}
  </Button>
);
