import React from 'react';
import { styled } from 'styletron-react';
import style from '../style';

const Label = styled('label', ({ $focused }) => ({
  display: 'inline-flex',
  border: '1px solid #ccc',
  borderRadius: '0',
  color: style.textColor,
  fontWeight: 'normal',
  cursor: 'pointer',
  minWidth: '200px',
  position: 'relative',
  marginBottom: '20px',
  ...($focused && {
    border: style.focusBorderRed,
    outline: 'none',
  }),
}));

const Input = styled('input', {
  position: 'absolute',
  height: '0',
  width: '0',
  opacity: '0',
});

const FakeButton = styled('div', {
  background: '#ccc',
  padding: style.basePadding,
  display: 'inline-block',
  position: 'absolute',
  right: '0',
});

const InnerLabel = styled('div', {
  padding: style.basePadding,
});

export default class File extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
    };
  }
  render() {
    const { label, buttonLabel = '...', id, ...inputProps } = this.props;
    const { focus } = this.state;
    return (
      <Label htmlFor={id} $focused={focus}>
        <InnerLabel>{label}</InnerLabel>
        <Input
          {...inputProps}
          id={id}
          type="file"
          onFocus={() => this.setState({ focus: true })}
          onBlur={() => this.setState({ focus: false })}
        />
        <FakeButton>{buttonLabel}</FakeButton>
      </Label>
    );
  }
}
