import XLSX from 'xlsx';
import fetch, { raw } from '../../../utils/fetch';

export const USER_IMPORT_FILE_PARSING = 'USER_IMPORT_FILE_PARSING';
export const USER_IMPORT_FILE_PARSED_SUCCESS = 'USER_IMPORT_FILE_PARSED_SUCCESS';
export const USER_IMPORT_FILE_PARSED_FAILED = 'USER_IMPORT_FILE_PARSED_FAILED';
export const USER_IMPORT_STARTED = 'USER_IMPORT_STARTED';
export const USER_IMPORT_SUCCESS = 'USER_IMPORT_SUCCESS';
export const USER_IMPORT_FAILED = 'USER_IMPORT_FAILED';
export const USER_DEACTIVATION_STARTED = 'USER_DEACTIVATION_STARTED';
export const USER_DEACTIVATION_SUCCESS = 'USER_DEACTIVATION_SUCCESS';
export const USER_DEACTIVATION_FAILED = 'USER_DEACTIVATION_FAILED';

export const deactivateUsers = (contractId, { usersToDeactivate }) => dispatch => {
  if (!usersToDeactivate || usersToDeactivate.length === 0) {
    return Promise.resolve();
  }

  dispatch({
    type: USER_DEACTIVATION_STARTED,
  });

  return dispatch(
    fetch('api/serviceuser/deactivate', {
      method: 'POST',
      body: JSON.stringify({
        contractId,
        referenceIds: usersToDeactivate.map(u => u.referenceId),
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  )
    .then(res => {
      if (res.error) {
        return dispatch({
          type: USER_DEACTIVATION_FAILED,
          error: {
            message: res.error,
            data: res.data,
          },
        });
      }

      dispatch({
        type: USER_DEACTIVATION_SUCCESS,
        data: res,
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: USER_DEACTIVATION_FAILED,
        error: {
          message: error ? error.message : error,
        },
      });
      throw error;
    });
};

export const importUsers = (contractId, { columns, users }) => dispatch => {
  if (!columns || columns.length === 0) {
    return;
  }

  if (!users || users.length === 0) {
    return;
  }

  dispatch({
    type: USER_IMPORT_STARTED,
  });

  return dispatch(
    fetch('api/serviceuser/import', {
      method: 'POST',
      body: JSON.stringify({
        contractId,
        columns,
        users,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  )
    .then(res => {
      if (res.error) {
        return dispatch({
          type: USER_IMPORT_FAILED,
          error: {
            message: res.error,
            data: res.data,
          },
        });
      }

      dispatch({
        type: USER_IMPORT_SUCCESS,
        data: res,
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: USER_IMPORT_FAILED,
        error,
      });
      throw error;
    });
};

const FIRSTNAME_COL_NO = 0;
const LASTNAME_COL_NO = 1;
const EMAIL_COL_NO = 2;
const REFERENCE_ID_COL_NO = 4;
// const LETTER_LOCATION_REFERENCE_ID_COL_NO = 5;
// const PARCEL_LOCATION_REFERENCE_ID_COL_NO = 6;

export const parseFile = file => dispatch => {
  if (!file) {
    return;
  }

  dispatch({
    type: USER_IMPORT_FILE_PARSING,
    data: {
      filename: file.name,
    },
  });

  const reader = new FileReader();

  reader.onload = event => {
    const fileData = new Uint8Array(event.target.result);

    const workbook = XLSX.read(fileData, { type: 'array' });
    const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
    // Filter out rows that doesn't contain all mandatory fields
    const data = XLSX.utils
      .sheet_to_json(first_worksheet, { header: 1 })
      .filter(row => row[FIRSTNAME_COL_NO] && row[LASTNAME_COL_NO] && row[EMAIL_COL_NO] && row[REFERENCE_ID_COL_NO]);

    dispatch({
      type: USER_IMPORT_FILE_PARSED_SUCCESS,
      data,
    });
  };

  reader.onerror = event => {
    dispatch({
      type: USER_IMPORT_FILE_PARSED_FAILED,
      error: {
        message: event.error && (event.error.message || event.error),
        data: {},
      },
    });
  };

  setTimeout(() => reader.readAsArrayBuffer(file), 500);
};

export const getExportLink = contractId => (dispatch, getState) =>
  raw(`api/serviceuser/export?contractid=${contractId}`)(dispatch, getState).then(res => {
    return res.headers.get('Location');
  });
