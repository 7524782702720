import React from 'react';
import handlebars from 'handlebars/dist/cjs/handlebars';
import { styled } from 'styletron-react';
import style from '../../../style';
import WithStickerConfiguration from '../../stickerConfiguration/WithStickerConfiguration';
import { TextAreaField } from '../../../components/Field';
import { Row, Col, NoOffsetCol, Well } from '../../../components/Layout';
import { Render as WithForm } from '../../../components/withForm';
import Button from '../../../components/Button';
import Notification from '../../../utils/promiseNotification';

const PropertyCode = styled('code', {
  background: style.superLight,
  padding: '0.25em',
});

const Property = ({ children }) => (
  <li>
    <PropertyCode>{children}</PropertyCode>
  </li>
);

const Snippet = styled('pre', {
  overflow: 'auto',
  whiteSpace: 'pre-wrap',
  background: style.superLight,
  padding: '0.5rem',
});

const validateConfiguration = configuration =>
  ['line1', 'line2', 'line3'].reduce((acc, key) => {
    try {
      handlebars.precompile(configuration[key] || '');
    } catch (e) {
      return { ...acc, [key]: e.message };
    }
    return acc;
  }, {});

export default ({ contract }) => (
  <WithStickerConfiguration contractId={contract.id}>
    {({ configuration, edit }) => (
      <WithForm defaultValues={configuration}>
        {({ values, onChange, isSubmitting, setSubmitting, onSuccess, onError, errors }) => (
          <NoOffsetCol>
            <Row>
              <Col>
                <h2>Edit sticker lines</h2>
                <i>
                  Make sure that your configuration doesn't exceed 2 lines of output per field (handlebar tags doesn't
                  count towards the line count). Handlebars documentation{' '}
                  <a href="https://handlebarsjs.com/" target="_BLANK" rel="noopener noreferrer">
                    https://handlebarsjs.com/
                  </a>
                </i>
              </Col>
            </Row>
            <Row>
              <Col>
                <TextAreaField
                  value={values.line1 || ''}
                  errors={[errors.line1]}
                  label="Field 1"
                  name="line1"
                  onChange={onChange}
                  disabled={isSubmitting}
                  cols="25"
                  rows="2"
                  wrap="off"
                />
                <h5>Default for Field 1</h5>
                <Snippet>
                  {`{{#if assignee}}
{{assignee.firstName}} {{assignee.lastName}} on behalf of
{{receiver.firstName}} {{receiver.lastName}}
{{/if}}
{{#unless assignee}}{{receiver.firstName}} {{receiver.lastName}}{{/unless}}`}
                </Snippet>
              </Col>
            </Row>
            <Row>
              <Col>
                <TextAreaField
                  value={values.line2 || ''}
                  errors={[errors.line2]}
                  label="Field 2"
                  name="line2"
                  onChange={onChange}
                  disabled={isSubmitting}
                  cols="25"
                  rows="2"
                  wrap="off"
                />
                <h5>Default for Field 2</h5>
                <Snippet>{'{{deliveryLocation.name}}'}</Snippet>
              </Col>
            </Row>
            <Row>
              <Col>
                <TextAreaField
                  value={values.line3 || ''}
                  errors={[errors.line3]}
                  label="Field 3"
                  name="line3"
                  onChange={onChange}
                  disabled={isSubmitting}
                  cols="25"
                  rows="2"
                  wrap="off"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  primary
                  onClick={() => {
                    setSubmitting();
                    const validationResult = validateConfiguration(values);
                    if (Object.keys(validationResult).length) {
                      onError({ data: validationResult });
                    } else {
                      edit(contract.id, {
                        ...configuration,
                        ...values,
                      })
                        .then(onSuccess)
                        .then(Notification.success('Configuration saved'))
                        .catch(Notification.error('Error: Configuration failed'));
                    }
                  }}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Col>
                <Well>
                  <h5>The following properties are available</h5>
                  <ul>
                    <Property>type (Letter/Parcel)</Property>
                    <Property>assignee</Property>
                    <Property>assignee.email</Property>
                    <Property>assignee.firstname</Property>
                    <Property>assignee.lastname</Property>
                    {contract.serviceUserFields
                      .map(custom => custom.name)
                      .map(key => (
                        <Property key={`assignee-${key}`}>assignee.customfields.{key.toLowerCase()}</Property>
                      ))}
                    <Property>contract.id</Property>
                    <Property>deliveryLocation.name</Property>
                    <Property>forwarder</Property>
                    <Property>referenceNumber</Property>
                    <Property>receiver.email</Property>
                    <Property>receiver.firstname</Property>
                    <Property>receiver.lastname</Property>
                    {contract.serviceUserFields
                      .map(custom => custom.name)
                      .map(key => (
                        <Property key={`receiver-${key}`}>receiver.customfields.{key.toLowerCase()}</Property>
                      ))}
                    {contract.consignmentFields
                      .map(custom => custom.name)
                      .map(key => (
                        <Property key={`customfield-${key}`}>customfields.{key.toLowerCase()}</Property>
                      ))}
                  </ul>
                  <h5>Available on letters</h5>
                  <ul>
                    {contract.letterFields
                      .map(custom => custom.name)
                      .map(key => (
                        <Property key={`letterfields-${key}`}>customfields.{key.toLowerCase()}</Property>
                      ))}
                  </ul>
                  <h5>Available on parcels</h5>
                  <ul>
                    {contract.parcelFields
                      .map(custom => custom.name)
                      .map(key => (
                        <Property key={`parcelfields-${key}`}>customfields.{key.toLowerCase()}</Property>
                      ))}
                  </ul>
                </Well>
              </Col>
            </Row>
          </NoOffsetCol>
        )}
      </WithForm>
    )}
  </WithStickerConfiguration>
);
