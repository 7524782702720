import { toQuery } from '../../utils/uri';
import { raw as fetch } from '../../utils/fetch';

const searchUsers = searchQuery => (dispatch, getState) => {
  const params = {
    query: '',
    offset: 0,
    limit: 10,
    orderBy: 'firstname',
    sortByDescending: false,
    ...searchQuery,
  };

  return fetch(`api/serviceuser/search?${toQuery(params)}`)(dispatch, getState);
};

export const searchUserById = (id, contractId) => (dispatch, getState) => {
  return fetch(`api/serviceuser/getbyid?id=${id}&contractId=${contractId}`)(dispatch, getState);
};

export default searchUsers;
