export const mergeDefinitionKey = (customFieldDefinition, entityCustomField) => {
  switch (customFieldDefinition.type) {
    case 'Select':
      return 'select';
    default:
      return entityCustomField;
  }
};

export const mergeWithDefinition = (customFieldsDefinition, entityCustomFields) => {
  // Sanity check, if user has NO (we need an array to work with) value in custom fields
  entityCustomFields = entityCustomFields || [];
  return (customFieldsDefinition || []).reduce((acc, customField) => {
    if (customField.active && !customField.disabled) {
      if (customField.type.toLowerCase() === 'select') {
        var selectOptionId = entityCustomFields[customField.name] || -1;
        // If user doesnt has a value set or it is "missing" from the entityCustomFields, set value to empty string
        var selectValue = selectOptionId !== -1 ? customField.options.find(cf => cf.id === selectOptionId).value : '';
        acc = { ...acc, [customField.name]: selectValue };
      } else {
        acc = { ...acc, [customField.name]: (entityCustomFields && entityCustomFields[customField.name]) || '' };
      }
    }
    return acc;
  }, {});
};
