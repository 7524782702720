import React from 'react';
import { connect } from 'react-redux';

import { loadConfiguration } from './action-creators';
import { state as asyncState } from '../../utils/fetch';

class WithConfig extends React.Component {
  componentDidMount() {
    this.props.config.state === asyncState.notTriggered &&
      this.props.loadConfiguration();
  }
  render() {
    return this.props.children(this.props.config);
  }
}

export default connect(state => ({ config: state.config }), {
  loadConfiguration,
})(WithConfig);
