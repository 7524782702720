import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/buffer';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/fromEvent';

const scanner$ = Observable.fromEvent(document.body, 'keypress');

export default () => {
  //Detect keypresses that occur within 75ms and ends with enter.
  //This is a probable indication that it's sent by a scanner
  return scanner$
    .buffer(scanner$.debounceTime(75))
    .filter(
      events => events.length > 2 && events[events.length - 1].keyCode === 13
    )
    .map(events => {
      return events
        .slice(0, -1)
        .map(e => e.key)
        .join('');
    });
};
