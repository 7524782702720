import React from 'react';
import { styled } from 'styletron-react';
import { Toggle, ToggleGroup } from '../../../components/Toggle';
import { fieldFactory } from '../../../components/Field';
import { ParcelIcon } from '../../../components/Icons/Parcel';
import { LetterIcon } from '../../../components/Icons/Letter';

const ToggleField = fieldFactory(ToggleGroup);

const ToggleWrapper = styled('div', {
  display: 'inline-block',
  lineHeight: '30px',
  textAlign: 'center',
});

const ShipmentTypeToggle = ({ onChange, value }) => (
  <ToggleField onChange={onChange} value={value} name="shipmenttype" label="Type of shipment">
    <Toggle value={0}>
      <ToggleWrapper>
        <LetterIcon />
        Letter
      </ToggleWrapper>
    </Toggle>
    <Toggle value={1}>
      {' '}
      <ToggleWrapper>
        <ParcelIcon />
        Parcel
      </ToggleWrapper>
    </Toggle>
  </ToggleField>
);

export default ShipmentTypeToggle;
