import { AUTH_LOAD, AUTH_LOAD_SUCCESS } from './action-creators';
import { state as asyncState } from '../utils/fetch';

const defaultState = {
  state: asyncState.notTriggered,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_LOAD:
      return {
        ...state,
        state: asyncState.fetching,
      };
    case AUTH_LOAD_SUCCESS:
      return {
        ...state,
        isAdmin: action.data.isAdmin,
        state: asyncState.done,
      };
    default:
      return state;
  }
};
