import React from 'react';
import { styled } from 'styletron-react';
import { NavLink } from 'react-router-dom';

import LocationsFilled from '../Icons/LocationsFilled';
import ShipmentsFilled from '../Icons/ShipmentsFilled';
import RecipientsFilled from '../Icons/RecipientsFilled';
import CustomersFilled from '../Icons/CustomersFilled';

import style from '../../style';
import UserDropdown from './UserDropdown';
import HelpLink from './HelpLink';

const routes = {
  shipments: {
    title: 'Shipments',
    icon: ShipmentsFilled,
  },
  recipients: {
    title: 'Recipients',
    icon: RecipientsFilled,
  },
  locations: {
    title: 'Locations',
    icon: LocationsFilled,
  },
  customers: {
    title: 'Customers',
    admin: true,
    icon: CustomersFilled,
  },
};

const Nav = ({ user, signout, location, isAdmin }) => (
  <NavWrapper>
    <Items>
      {Object.keys(routes)
        .map(k => ({ ...routes[k], to: k }))
        .map(route => (
          <Item location={location} to={`/${route.to}`} key={route.to}>
            {route.icon && (
              <NavIcon>
                <route.icon fill={style.primary} />
              </NavIcon>
            )}
            {route.title}
          </Item>
        ))}
    </Items>
    <StatusContainer>
      <HelpLink />
      <UserDropdown user={user} isAdmin={isAdmin} location={location} signout={signout} />
    </StatusContainer>
  </NavWrapper>
);

// Styled components
const activeAfterStyles = {
  width: '100%',
  transition: 'all 0.2s',
  color: 'initial',
};

export const itemFactory = Component =>
  styled(Component, ({ location, to }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0.7rem 1em 0.8em 0.5em',
    color: style.textColor,
    lineHeight: '3.5rem',
    position: 'relative',
    '::after': {
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      margin: 'auto',
      width: '0%',
      content: '""',
      color: 'transparent',
      background: style.primary,
      height: '3px',
      ...(location && location.pathname.startsWith(to) ? activeAfterStyles : {}),
    },
    ':hover': {
      textDecoration: 'none',
      color: style.textColor,
      '-webkit-text-stroke': 'initial',
    },
    ':hover::after': {
      width: '100%',
      transition: 'all 0.2s',
    },
    ':focus': {
      textDecoration: 'none',
      '-webkit-text-stroke': 'initial',
      color: 'initial',
      outline: '0',
    },
    ':focus::after': activeAfterStyles,
  }));

const NavWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'no-wrap',
});

const Items = styled('div', {
  display: 'inline-flex',
  flexDirection: 'column',
  ...style.md({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  }),
  ...style.lg({
    alignItems: 'center',
  }),
});

const Item = itemFactory(NavLink);

const NavIcon = styled('div', {
  marginRight: '.8em',
  display: 'flex',
  alignItems: 'center',
});

const StatusContainer = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
});

export default Nav;
