import React from 'react';
import { raw } from '../../utils/fetch';
import { connect } from 'react-redux';
import Page, { PageHeader, PageHeading, PageDescription, PageContent } from '../../components/Page';
import Button from '../../components/Button';
import ErrorBoundary from '../../components/ErrorBoundary';

// Action creator
const getExportLink = () => (dispatch, getState) =>
  raw('api/invoicing/export')(dispatch, getState)
    .then(res => res.headers.get('Location'))
    .then(location => {
      document.location = location;
    });

class InvoicingPage extends React.Component {
  render() {
    const { isAdmin, exportInvoiceDetails } = this.props;
    return (
      <ErrorBoundary>
        <Page noContractSelector>
          <PageHeader>
            <PageHeading>Invoicing</PageHeading>
          </PageHeader>
          <PageDescription>Download invoice details for every customer.</PageDescription>
          <PageContent>
            <Button primary disabled={!isAdmin} onClick={exportInvoiceDetails}>
              Export invoicing details
            </Button>
          </PageContent>
        </Page>
      </ErrorBoundary>
    );
  }
}
const mapStateToProps = state => ({
  isAdmin: state.authentication.isAdmin,
});
const mapDispatchToProps = {
  exportInvoiceDetails: () => getExportLink(),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicingPage);
