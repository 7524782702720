import React, { useState, useEffect } from 'react';
import Collapsable from './Collapsable';
import { styled } from 'styletron-react';
import style from '../style';

const SectionHeader = styled('div', ({ $active }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `solid 1px ${style.grey}`,
  position: 'relative',
  padding: '20px',
  cursor: 'pointer',
  '::after': {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    content: '""',
    width: '0',
    height: '5px',
    background: style.primary,
    transition: 'all 0.3s',
    ...( $active && {
      width: '30%',
    }),
  },
}));

const SectionHeading = styled('h2', {
  marginBottom: '0',
});

export const AccordionPane = ({ children }) => <div>{children}</div>;

const Accordion = ({ children }) => {
  const [paneStates, setPaneStates] = useState([]);

  useEffect(() => {
    setPaneStates(children.map((c) => !c.props.defaultOpen));
  }, [children]);

  const toggle = (i) => {
    setPaneStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[i] = !prevStates[i];
      return newStates;
    });
  };

  return children.map((pane, i) => (
    <React.Fragment key={i}>
      <SectionHeader $active={!paneStates[i]} onClick={() => toggle(i)}>
        <SectionHeading>{pane.props.title}</SectionHeading>
      </SectionHeader>
      <Collapsable collapsed={paneStates[i]}>{pane.props.children}</Collapsable>
    </React.Fragment>
  ));
};

export default Accordion;
