import {
  USER_IMPORT_FILE_PARSING,
  USER_IMPORT_FILE_PARSED_SUCCESS,
  USER_IMPORT_FILE_PARSED_FAILED,
  USER_IMPORT_STARTED,
  USER_IMPORT_SUCCESS,
  USER_IMPORT_FAILED,
  USER_DEACTIVATION_STARTED,
  USER_DEACTIVATION_SUCCESS,
  USER_DEACTIVATION_FAILED,
} from './import/action-creators';
import {
  SERVICE_USER_FETCH,
  SERVICE_USER_FETCH_SUCESS,
} from './action-creators';
import { state as asyncState } from '../../utils/fetch';

const defaultState = {
  userImport: {
    isParsing: false,
    isImporting: false,
    isDeactivating: false,
    filename: '',
    columns: [],
    users: [],
    importResult: {},
    error: undefined,
  },
  users: {},
};

const withUser = (state, serviceUserId, diff) => ({
  ...state,
  users: {
    ...state.users,
    [serviceUserId]: {
      ...state.users[serviceUserId],
      ...diff,
    },
  },
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case SERVICE_USER_FETCH:
      return withUser(state, action.serviceUserId, {
        state: asyncState.fetching,
      });
    case SERVICE_USER_FETCH_SUCESS:
      return withUser(state, action.serviceUserId, {
        ...action.data,
        state: asyncState.done,
      });
    case USER_IMPORT_FILE_PARSING:
      return {
        ...state,
        userImport: {
          ...state.userImport,
          isParsing: true,
          filename: action.data.filename,
          error: undefined,
        },
      };
    case USER_IMPORT_FILE_PARSED_SUCCESS:
      return {
        ...state,
        userImport: {
          ...state.userImport,
          isParsing: false,
          columns: action.data.shift(),
          users: action.data,
          importResult: {},
          error: undefined,
        },
      };
    case USER_IMPORT_FILE_PARSED_FAILED:
      return {
        ...state,
        userImport: {
          ...state.userImport,
          isParsing: false,
          error: action.error,
        },
      };
    case USER_IMPORT_STARTED:
      return {
        ...state,
        userImport: {
          ...state.userImport,
          isImporting: true,
          error: undefined,
        },
      };
    case USER_IMPORT_SUCCESS:
      return {
        ...state,
        userImport: {
          ...state.userImport,
          isImporting: false,
          error: undefined,
          columns: [],
          users: [],
          importResult: action.data,
        },
      };
    case USER_IMPORT_FAILED:
      return {
        ...state,
        userImport: {
          ...state.userImport,
          isImporting: false,
          error: action.error,
        },
      };
    case USER_DEACTIVATION_STARTED:
      return {
        ...state,
        userImport: {
          ...state.userImport,
          isDeactivating: true,
          error: undefined,
        },
      };
    case USER_DEACTIVATION_SUCCESS:
      return {
        ...state,
        userImport: {
          ...state.userImport,
          isDeactivating: false,
          error: undefined,
          importResult: {
            ...state.userImport.importResult,
            ...action.data,
            usersToDeactivate: [],
          },
        },
      };
    case USER_DEACTIVATION_FAILED:
      return {
        ...state,
        userImport: {
          ...state.userImport,
          isDeactivating: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};
