import { toQuery, parseLinks } from '../../../utils/uri';
import { raw as fetch } from '../../../utils/fetch';
import { fetched } from '../action-creators';

export const search = params => (dispatch, getState) =>
  fetch(`api/consignment/search?${toQuery(params)}`)(
    dispatch,
    getState
  ).then(result =>
    result.json().then(shipments => {
      dispatch(fetched(shipments));
      return {
        shipments,
        ...parseLinks(result.headers.get('Link')),
      };
    })
  );

export const getExportLink = params => (dispatch, getState) =>
  fetch(
    `api/consignment/export?contractId=${params.contractId}&timezoneoffset=${-new Date().getTimezoneOffset() /
      60}`
  )(dispatch, getState).then(
    res => `${res.headers.get('Location')}&${toQuery(params)}`
  );
