import React from 'react';
import { styled, withStyle } from 'styletron-react';
import { statisticTypes } from './StatisticType';
import { Table, TableHeader, TableHeaderCell, TableCell } from '../Table';

const EmptyMessage = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '18px',
  color: '#cecece',
});

const Cell = withStyle(TableCell, {
  textAlign: 'center',
});
const HeadCell = withStyle(TableHeaderCell, {
  textAlign: 'center',
});

const Badge = styled('div', ({ type }) => ({
  backgroundColor: statisticTypes[type].color,
  borderRadius: '100%',
}));

const BadgeWrapper = styled('div', {
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '2rem',
  height: '2rem',
});

const TooltipTrigger = styled('div', {
  position: 'absolute ',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0,
  cursor: 'pointer',
  transition: 'opacity 0.4s ease-in-out',
  ':hover': {
    opacity: 1,
  },
});

const TooltipContent = styled('ul', {
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, 0)',
  bottom: '100%',
  minWidth: '100%',
  borderRadius: '0.5rem',
  background: '#666',
  listStyle: 'none',
  padding: '0.5rem',
  pointerEvents: 'none',
  marginBottom: '0.5rem',
});

const Tooltip = ({ children }) => (
  <TooltipTrigger>
    <TooltipContent>{children}</TooltipContent>
  </TooltipTrigger>
);

const TooltipItem = styled('li', ({ type }) => ({
  color: statisticTypes[type].color,
  whiteSpace: 'nowrap',
  listStyle: 'none',
}));

const grid = Array.apply(0, new Array(24)).map((_, hourIndex) => ({
  hour: hourIndex + 1,
  days: Array.apply(0, new Array(7)).map((___, dayIndex) => dayIndex + 1),
}));

const PunchCardCell = ({ maxCount, cellStatistics }) => (
  <Cell style={{ position: 'relative' }}>
    {cellStatistics.filter(statistic => statistic.count).length > 0 && (
      <Tooltip>
        {cellStatistics
          .filter(statistic => statistic.count)
          .map(statistic => (
            <TooltipItem key={statistic.type} type={statistic.type}>
              {statisticTypes[statistic.type].title}: {statistic.count}
            </TooltipItem>
          ))}
      </Tooltip>
    )}
    {cellStatistics.map(statistic => (
      <BadgeWrapper key={statistic.type}>
        {statistic.count && (
          <Badge
            type={statistic.type}
            style={{
              width: `${100 * (statistic.count / maxCount[statistic.type])}%`,
              height: `${100 * (statistic.count / maxCount[statistic.type])}%`,
            }}
          />
        )}
      </BadgeWrapper>
    ))}
  </Cell>
);

export default ({ punchcard }) => {
  const data = punchcard
    .filter(s => s.type < 2)
    .reduce(
      (hours, statistic) => ({
        ...hours,
        [statistic.hour]: {
          ...hours[statistic.hour],
          [statistic.weekday]: {
            ...(hours[statistic.hour] ? hours[statistic.hour][statistic.weekday] : {}),
            [statistic.type]: statistic,
          },
        },
      }),
      {}
    );
  const maxCount = punchcard
    .filter(s => s.type < 2)
    .reduce(
      (types, statistic) => ({
        ...types,
        [statistic.type]: Math.max(types[statistic.type] || 0, statistic.count),
      }),
      {}
    );

  if (!punchcard || punchcard.length === 0) {
    return <EmptyMessage>No statistics available</EmptyMessage>;
  }

  return (
    <Table>
      <TableHeader>
        <HeadCell />
        <HeadCell>Mon</HeadCell>
        <HeadCell>Tue</HeadCell>
        <HeadCell>Wed</HeadCell>
        <HeadCell>Thu</HeadCell>
        <HeadCell>Fri</HeadCell>
        <HeadCell>Sat</HeadCell>
        <HeadCell>Sun</HeadCell>
      </TableHeader>
      <tbody>
        {grid
          .filter(hour => data[hour.hour])
          .map(hour => (
            <tr key={hour.hour}>
              <Cell>{`${'00'.substring(hour.hour.toString().length)}${hour.hour}`}:00</Cell>
              {hour.days.map(day => (
                <PunchCardCell
                  key={day}
                  maxCount={maxCount}
                  cellStatistics={[0, 1].map(
                    type =>
                      (data[hour.hour][day] && data[hour.hour][day][type]) || {
                        type,
                      }
                  )}
                />
              ))}
            </tr>
          ))}
      </tbody>
    </Table>
  );
};
