import { CLEAR, ADD, ADD_LOADED, REMOVE, MERGE, UPDATE } from './action-creators';
import { SHIPMENTS_DELIVER_SUCCESS, SEND_REMINDERS_SUCCESS } from '../action-creators';

const defaultState = { selectedShipments: {} };

export default (state = defaultState, action) => {
  switch (action.type) {
    case SEND_REMINDERS_SUCCESS:
    case SHIPMENTS_DELIVER_SUCCESS:
    case CLEAR:
      return {
        ...state,
        selectedShipments: {},
      };

    case ADD:
      return {
        ...state,
        selectedShipments: {
          ...state.selectedShipments,
          [action.consignmentNumber]: {
            consignmentNumber: action.consignmentNumber,
          },
        },
      };

    case ADD_LOADED:
      return {
        ...state,
        selectedShipments: {
          ...state.selectedShipments,
          [action.consignmentNumber]: action.shipment,
        },
      };

    case REMOVE:
      const shipments = { ...state.selectedShipments };
      delete shipments[action.consignmentNumber];
      return {
        ...state,
        selectedShipments: {
          ...shipments,
        },
      };

    case MERGE:
      return {
        ...state,
        selectedShipments: {
          ...Object.keys(action.diff).reduce(
            (acc, curr) => ({
              ...acc,
              [curr]: {
                ...state.selectedShipments[curr],
                ...action.diff[curr],
              },
            }),
            state.selectedShipments
          ),
        },
      };

    case UPDATE:
      return {
        ...state,
        selectedShipments: {
          ...state.selectedShipments,
          [action.consignmentNumber]: action.shipment,
        },
      };

    default:
      return state;
  }
};
