import React, { Fragment } from 'react';
import withViewport from '../../components/Viewport';
import List, { ListItem } from '../../components/List';
import EmptyState from '../../components/EmptyState';
import PersonWithMail from '../../components/PersonWithMail';
import { formatDate } from '../../utils/dateUtil';
import { withStyle } from 'styletron-react';
import style from '../../style';
import { TableWrapper, Table, TableHeader, TableHeaderCell, TableRow, TableCell } from '../../components/Table';

const BreakCell = withStyle(TableCell, {
  wordBreak: 'break-word',
});

const hasAssignee = consignment =>
  consignment.assignee &&
  (consignment.assignee.email || consignment.assignee.firstName || consignment.assignee.lastName);

const ShipmentList = ({
  shipments,
  onSortChanged = () => {},
  onItemSelected = () => {},
  onItemChecked,
  onAllItemChecked,
  isMd,
}) =>
  isMd ? (
    shipments.length === 0 ? (
      <EmptyState>No shipments found</EmptyState>
    ) : (
      <TableWrapper>
        <Table>
          <TableHeader background={style.primary} color={style.white}>
            {onItemChecked && (
              <TableHeaderCell>
                {onAllItemChecked && (
                  <input
                    type="checkbox"
                    checked={shipments.some(s => s.isChecked)}
                    onChange={e => onAllItemChecked(e.target.checked)}
                  />
                )}
              </TableHeaderCell>
            )}
            <TableHeaderCell onClick={() => onSortChanged('shipmentNumber')}>#</TableHeaderCell>
            <TableHeaderCell onClick={() => onSortChanged(['receiver', 'firstName'])}>Recipient</TableHeaderCell>
            <TableHeaderCell onClick={() => onSortChanged('numberOfPackages')}>No of pkg</TableHeaderCell>
            <TableHeaderCell onClick={() => onSortChanged(['currentLocation', 'name'])}>
              Current location
            </TableHeaderCell>
            <TableHeaderCell onClick={() => onSortChanged(['deliveryLocation', 'name'])}>
              Delivery location
            </TableHeaderCell>
            <TableHeaderCell onClick={() => onSortChanged('forwarder')}>Forwarder</TableHeaderCell>
            <TableHeaderCell onClick={() => onSortChanged('referenceNumber')}>Reference</TableHeaderCell>
            <TableHeaderCell onClick={() => onSortChanged('date')}>Date</TableHeaderCell>
            <TableHeaderCell onClick={() => onSortChanged('status')}>Status</TableHeaderCell>
          </TableHeader>
          <tbody>
            {shipments.map(c => (
              <TableRow key={c.id} onClick={() => onItemSelected(c.id)} $clickable>
                {onItemChecked && (
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={c.isChecked || false}
                      onChange={e => onItemChecked(c, e.target.checked)}
                      onClick={e => e.stopPropagation()}
                    />
                  </TableCell>
                )}
                <TableCell
                  title={
                    hasAssignee(c) && `Assigned to: ${c.assignee.firstName || 'ANONYMIZED'} ${c.assignee.lastName}`
                  }
                >
                  {c.consignmentNumber}
                  {hasAssignee(c) && <span>*</span>}
                </TableCell>

                <TableCell>
                  {c.receiver ? (
                    <Fragment>
                      {c.receiver.firstName} {c.receiver.lastName} <br />
                      {c.receiver.email}
                    </Fragment>
                  ) : (
                    'ANONYMIZED'
                  )}
                </TableCell>
                <TableCell>{c.numberOfPackages}</TableCell>
                <TableCell>{c.currentLocation && c.currentLocation.name}</TableCell>
                <TableCell>{c.deliveryLocation.name}</TableCell>
                <TableCell>{c.forwarder}</TableCell>
                <BreakCell>{c.referenceNumber}</BreakCell>
                <TableCell>{formatDate(c.registeredDate)}</TableCell>
                <TableCell>{c.status}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    )
  ) : (
    // ? Is this even used at all? Doesn't all viewports < isMd show CompactMode?
    <List>
      {shipments.map(c => (
        <ListItem key={c.id} onClick={() => onItemSelected(c.id)}>
          <div>{c.shipmentNumber}</div>
          <PersonWithMail {...c.reciever} />
        </ListItem>
      ))}
    </List>
  );

export default withViewport(ShipmentList);
