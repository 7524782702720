import React, { createContext, useContext, useEffect, useState } from 'react';
import { object } from 'prop-types';
import style from '../../style';
import isEqual from 'lodash.isequal';

const generateViewportPropertyName = viewportName =>
  `is${viewportName.charAt(0).toUpperCase() + viewportName.substring(1)}`;

const propertyNames = Object.keys(style.viewports).reduce(
  (acc, key) => ({
    ...acc,
    [generateViewportPropertyName(key)]: style.viewports[key],
  }),
  {}
);

const getActiveViewports = width =>
  Object.keys(propertyNames)
    .filter(key => width >= propertyNames[key])
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: true,
      }),
      {}
    );

const ViewportContext = createContext(null);

export const ViewportProvider = ({ children }) => {
  const [activeViewports, setActiveViewports] = useState(() => getActiveViewports(window.innerWidth));

  const onResize = () => {
    const newActiveViewports = getActiveViewports(window.innerWidth);
    if (!isEqual(activeViewports, newActiveViewports)) {
      setActiveViewports(newActiveViewports);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeViewports]);

  return <ViewportContext.Provider value={activeViewports}>{children}</ViewportContext.Provider>;
};

ViewportProvider.propTypes = {
  children: object.isRequired,
};

export const WithViewport = ({ children }) => {
  const activeViewports = useContext(ViewportContext);
  return children(activeViewports);
};

// WithViewport.propTypes = {
//   children: object.isRequired,
// };

const withViewport = Component => props => (
  <WithViewport>{viewportProps => <Component {...props} {...viewportProps} />}</WithViewport>
);

export default withViewport;
