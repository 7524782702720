import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import oidcMiddleware, { reducer as oidcReducer } from 'redux-oidc';
import userManager from './utils/userManager';
import thunk from 'redux-thunk';

import authentication from './authentication/reducer';
import shipment from './features/shipment/reducer';
import shipmentCompact from './features/shipment/Compact/reducer';
import contracts from './features/contracts/reducer';
import serviceUsers from './features/serviceUsers/reducer';
import locations from './features/locations/reducer';
import config from './features/appConfig/reducer';
import stickerConfiguration from './features/stickerConfiguration/reducer';

const reducers = combineReducers({
  oidc: oidcReducer,
  authentication,
  shipment,
  shipmentCompact,
  contracts,
  serviceUsers,
  locations,
  config,
  stickerConfiguration,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(
      thunk,
      oidcMiddleware(userManager),
      store => next => action => {
        // console.log(action);
        return next(action);
      }
    )
  )
);

export default store;
