import React from 'react';
import withForm from '../../components/withForm';
import Field, { FormWrapper, FormLabel, CheckboxField as Checkbox, FieldError } from '../../components/Field';
import CustomField from '../../components/CustomField';
import UserPicker from '../../components/UserPicker';
import LocationSelector from '../../components/LocationSelector';
import Button from '../../components/Button';
import { RightAlign } from '../../components/Layout';
import { ErrorMessage } from '../../components/MiscPresentational';
import { StyledLink as UserLink } from '../../components/Page';

const ServiceUserForm = ({
  values,
  onChange,
  onValuesChange,
  onSubmit,
  isSubmitting,
  serviceUser,
  disabled,
  contract,
  enableAssigneeUserInput,
  setFormState,
  formState,
  actions,
  onClose,
  onBlur,
}) => {
  const contractChangeErr =
    values.contract && values.contract.id !== contract.id && `This service user belongs to ${values.contract.name}`;


  const emailIsNotValid = validateEmail(values.email);

  function validateEmail(value) {
    let isValid;
    if (!value) {
      isValid = true;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      isValid = false;
    }
    else
      isValid = true;

    return isValid;
  }

  const locationIsEmpty =
    (contract.hasLetters && !values.letterLocation) || (contract.hasParcels && !values.parcelLocation);


  const isValidToSubmit =
    !isSubmitting &&
    !disabled &&
    !(!values.firstName || !values.lastName || !values.referenceId || locationIsEmpty) &&
    !contractChangeErr &&
    emailIsNotValid;

  const onAssigneeCheck = e => {
    const checked = e.target.checked;
    setFormState({ useAssigneeUser: checked });
    if (!!!checked) {
      onValuesChange({
        assignee: null,
      });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit();
  };

  console.log('values.isValidEmail', values.isValidEmail);
  const backgroundColor =  values.isValidEmail === undefined ?  'white' :  values.isValidEmail?  'white' : 'pink'; 
  
  return (
    <form onSubmit={handleSubmit}>
      <Field
        label="First Name"
        name="firstName"
        value={values.firstName || ''}
        onChange={onChange}
        disabled={disabled || isSubmitting}
        required
        autoFocus={true}
      />
      <Field
        label="Last Name"
        name="lastName"
        value={values.lastName || ''}
        onChange={onChange}
        disabled={disabled || isSubmitting}
        required
      />
      
      {!emailIsNotValid && <FieldError >Email is not valid <span>*</span></FieldError>}
      
      <Field
        label="Email"
        name="email"
        style={{ background: backgroundColor }}
        value={values.email || ''}
        onChange={onChange}
        disabled={disabled || isSubmitting}
        onBlur={onBlur}
      />
      <Field
        label="Phone Number"
        name="phoneNumber"
        value={values.phoneNumber || ''}
        onChange={onChange}
        disabled={disabled || isSubmitting}
      />

      {contract.hasLetters && (
        <LocationSelector
          label="Letter Location"
          name="letterLocationReferenceId"
          value={values.letterLocation ? values.letterLocation.id : undefined}
          onChange={location => onValuesChange({ letterLocation: location })}
          allowUnset={!values.letterLocation}
          contractId={contract.id}
          required
          disabled={disabled || isSubmitting}
        />
      )}

      {contract.hasParcels && (
        <LocationSelector
          label="Parcel Location"
          name="parcelLocationReferenceId"
          value={values.parcelLocation ? values.parcelLocation.id : undefined}
          onChange={location => onValuesChange({ parcelLocation: location })}
          allowUnset={!values.parcelLocation}
          contractId={contract.id}
          required
          disabled={disabled || isSubmitting}
        />
      )}

      <Field
        label="Unique reference id"
        name="referenceId"
        value={values.referenceId || ''}
        onChange={onChange}
        disabled={disabled || isSubmitting}
        required
      />

      {enableAssigneeUserInput && (
        <FormWrapper>
          <Checkbox
            label="Use assignee recipient"
            checked={!!formState.useAssigneeUser || values.assignee || false} // Use "!!" if value is null.
            onChange={onAssigneeCheck}
            disabled={disabled || isSubmitting}
            id="useAssigneeUser"
            name="useAssigneeUser"
          />
        </FormWrapper>
      )}
      {enableAssigneeUserInput && (formState.useAssigneeUser || values.assignee) && (
        <FormWrapper>
          <FormLabel>Assignee recipient</FormLabel>
          <UserPicker
            onChange={assignee => onValuesChange({ assignee })}
            user={values.assignee}
            currentUserId={serviceUser.id}
            includeAssigneeUsers={enableAssigneeUserInput}
          />
        </FormWrapper>
      )}
      {serviceUser.isAssignee && (
        <FormWrapper>
          <FormLabel>Is assignee for</FormLabel>
          {serviceUser.isAssigneeFor.map(user => (
            <p key={user.id}>
              {`${user.firstName} ${user.lastName}`} <UserLink to={`${user.id}`}>Edit</UserLink>
            </p>
          ))}
        </FormWrapper>
      )}

      {contract.serviceUserFields.length > 0 &&
        contract.serviceUserFields
          .filter(suf => !suf.disabled)
          .map((field, i) => (
            <CustomField
              key={i}
              label={field.name}
              name={field.name}
              options={field.options}
              value={(values.customFields && values.customFields[field.name]) || ''}
              type={field.type}
              required={field.required}
              onChange={value =>
                onValuesChange({
                  customFields: {
                    ...values.customFields,
                    [field.name]: value,
                  },
                })
              }
              disabled={disabled || isSubmitting}
            />
          ))}
      <RightAlign>
        <ErrorMessage>{contractChangeErr}</ErrorMessage>
        {actions}
        {onClose && <Button onClick={onClose}>Close</Button>}
        <Button type="submit" primary disabled={!isValidToSubmit}>
          Save
        </Button>
      </RightAlign>
    </form>
  );
};

export default withForm({
  mapPropsToValues: props => props.serviceUser,
  mapPropsToSubmit: props => props.save,
})(ServiceUserForm);
