import React from 'react';
import CSSTransitionGroup from 'react-addons-css-transition-group';
import { styled } from 'styletron-react';
import style, { multiStyle } from '../../../style';
import './panel.css';

const transitionSpeed = 400;
const basePadding = '0.75rem';

const PanelPosition = styled('div', ({ $isActive }) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  left: 0,
  height: '80vh',
  transform: `translate3d(0, ${$isActive ? 0 : '100%'}, 0)`,
  transition: `transform ease-in-out ${transitionSpeed}ms`,
}));

const PanelBackground = styled('div', ({ $isActive }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  height: 0,
  width: '100vw',
  background: style.darkDarker,
  animation: `${transitionSpeed}ms ${$isActive ? 'fadein' : 'fadeout'} forwards`,
}));

const Panel = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  background: 'white',
});

const PanelHeader = styled('div', {
  fontSize: '1.4rem',
  flexGrow: 0,
  display: 'flex',
  justifyContent: 'space-between',
});

const PanelTitle = styled('div', {
  padding: basePadding,
});

const CloseIcon = styled('button', {
  border: 'none',
  background: 'none',
  outline: 'none',
  padding: basePadding,
  fontSize: 'inherit',
  ...multiStyle([':focus', ':active', ':hover'], {
    outline: 'none',
  }),
});

const PanelContent = styled('div', {
  display: 'flex',
  padding: basePadding,
  flexGrow: 1,
});

export default ({ title, onClose, children }) => (
  <div>
    <PanelBackground $isActive={!!children} onClick={() => onClose()} />
    <PanelPosition $isActive={!!children}>
      <CSSTransitionGroup
        transitionName="panel"
        transitionAppear={true}
        transitionAppearTimeout={transitionSpeed}
        transitionEnterTimeout={transitionSpeed}
        transitionLeaveTimeout={transitionSpeed}
        transitionEnter={true}
        transitionLeave={true}
      >
        {children && (
          <Panel>
            <PanelHeader>
              <PanelTitle>{title}</PanelTitle>
              <CloseIcon onClick={() => onClose()}>&times;</CloseIcon>
            </PanelHeader>
            <PanelContent>{children}</PanelContent>
          </Panel>
        )}
      </CSSTransitionGroup>
    </PanelPosition>
  </div>
);
