import React, { useEffect, useState } from 'react';
import { toQuery, fromQuery } from '../../../utils/uri';
import {
  PageHeader,
  PageHeading,
  PageContent,
  makeLinkWrapper,
  StyledLink as PageLink,
} from '../../../components/Page';
import { styled } from 'styletron-react';
import { GraphPagination as Pagination } from '../../../components/Pagination';
import SearchField from '../../../components/SearchField';
import List, { makeListItem } from '../../../components/List';
import GraphQLProvider from '../../../components/GraphQLProvider';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';

const LinkListItem = makeListItem(PageLink);
const LinkWrapper = makeLinkWrapper(LinkListItem);

const Search = styled('div', {
  marginLeft: 'auto',
  flexBasis: '400px',
});

const Recipient = styled('div', {
  display: 'flex',
});
const Name = styled('span', {
  flexBasis: '180px',
  marginRight: '1rem',
});

const USERS_QUERY = gql`
  query ServiceUsersListing($contractId: Int!, $after: String, $before: String, $first: Int, $query: String) {
    contract(id: $contractId) {
      id
      serviceUsers(after: $after, before: $before, first: $first, query: $query) {
        edges {
          node {
            id
            firstName
            lastName
            email
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

const SearchUsers = ({ match, location, history, contractId }) => {
  const [queryParams, setQueryParams] = useState(fromQuery(location.search));
  const [debounceQuery, setDebounceQuery] = useState(null);

  useEffect(() => {
    setDebounceQuery(
      debounce((query, history, match, queryParams) => {
        history?.replace(
          `${match.path}?${toQuery({
            query,
            ...(queryParams.first && {
              first: queryParams.first,
            }),
          })}`
        );
      }, 300)
    );
  }, []);

  const { data, loading, error } = useQuery(USERS_QUERY, {
    variables: { contractId: parseInt(contractId), ...queryParams },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (debounceQuery) {
      debounceQuery(queryParams.query, history, match, queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  return (
    <div>
      <GraphQLProvider>
        <div>
          {error && new Error(error.message)}
          <PageHeader>
            <PageHeading>Recipients</PageHeading>
            <Search>
              <SearchField
                placeholder={'Search recipient'}
                defaultValue={queryParams.query}
                onChange={(e) => setQueryParams((prev) => ({ ...prev, query: e.target.value }))}
                loading={loading}
              />
            </Search>
          </PageHeader>
          <PageContent>
            <List>
              {!loading &&
                data.contract.serviceUsers.edges
                  .map((e) => e.node)
                  .map((su) => (
                    <LinkWrapper key={su.id} to={`${match.path}/edit/${su.id}`}>
                      <Recipient>
                        <Name>
                          {su.firstName} {su.lastName}
                        </Name>
                        <span>{su.email}</span>
                      </Recipient>
                    </LinkWrapper>
                  ))}
            </List>
            {!loading && (
              <Pagination
                match={match}
                location={location}
                history={history}
                pageInfo={data.contract.serviceUsers.pageInfo}
              />
            )}
          </PageContent>
        </div>
      </GraphQLProvider>
    </div>
  );
};

export default connect((state) => ({
  contractId: state.contracts.selected.id,
}))(SearchUsers);
