import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styletron-react';
import { multiStyle } from '../../../style';
import { statusRed } from '../../../style/colors';

export default ({ consignmentNumber, currentLocation, deliveryLocation, error, remove, to, status }) => (
  <ShipmentBase $error={error}>
    <ShipmentContent to={to} hasError={!!error}>
      <Title>{consignmentNumber}</Title>
      {!error && (
        <Fragment>
          <Location>Currently at: {currentLocation && currentLocation.name}</Location>
          <Location>Destination: {deliveryLocation && deliveryLocation.name}</Location>
          <Location>Status: {status}</Location>
        </Fragment>
      )}
      {error && <div>Could not find this shipment</div>}
    </ShipmentContent>
    <RemoveButton type="button" onClick={() => remove()}>
      &times;
    </RemoveButton>
  </ShipmentBase>
);

const ShipmentContent = ({ to, hasError, children }) => (
  <ShipmentContentBase>
    {to && (
      <ShipmentContentLink $as={Link} to={to}>
        {children}
      </ShipmentContentLink>
    )}
    {!to && children}
  </ShipmentContentBase>
);

// Styled components
const ShipmentBase = styled('div', ({ $error }) => ({
  display: 'flex',
  alignItems: 'stretch',
  background: $error ? `${statusRed}33` : 'transparent',
}));

const RemoveButton = styled('button', {
  background: 'transparent',
  border: 'none',
  width: '3rem',
  cursor: 'pointer',
  fontSize: '18px',
});

const ShipmentContentBase = styled('div', {
  flexGrow: 1,
  padding: '10px',
});

const ShipmentContentLink = styled('a', {
  display: 'block',
  color: 'inherit',
  ...multiStyle([':focus', ':active', ':hover'], {
    color: '#888',
  }),
});

const Title = styled('h3', {
  fontSize: '1.25em',
  fontWeight: 600,
});

const Location = styled('div', {
  fontSize: '0.8em',
});
