import React from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Import from './import';
import Page, { TabNav, makeTab, activeTabStyle } from '../../components/Page';
import Add from './Add';
import Edit from './Edit';
import Search from './Search';
import ContractSelector from '../../components/ContractSelector';
import ContractContext from '../contracts/ContractContext';
import ErrorBoundary from '../../components/ErrorBoundary';
import { state as asyncState } from '../../utils/fetch';
import { addServiceUser, updateServiceUser } from './action-creators';
import GraphQLProvider from '../../components/GraphQLProvider';

const Tab = makeTab(NavLink);


const ServiceUsers = ({ contract, contractId, match, addServiceUser, updateServiceUser }) => (
  <ErrorBoundary>
    <GraphQLProvider>
      <ContractSelector />
      <Page>
        <TabNav outOfBase>
          <Tab
            exact
            to={`${match.path}`}
            activeStyle={activeTabStyle}
            isActive={(match, location) => {
              if (!match) {
                return location.pathname.startsWith('/recipients/edit');
              }
              return match.path === location.pathname;
            }}
          >
            Recipients
          </Tab>
          <Tab to={`${match.path}/add`} activeStyle={activeTabStyle}>
            Add
          </Tab>
          <Tab to={`${match.path}/import`} activeStyle={activeTabStyle}>
            Import
          </Tab>
        </TabNav>
        <ContractContext>
          {contract.state === asyncState.done && (
            <Switch>
              <Route path={`${match.url}/import`} component={Import} />
              <Route
                path={`${match.url}/add`}
                component={() => (
                  <Add addServiceUser={serviceUser => addServiceUser(serviceUser, contractId)} />
                )}
              />
              <Route
                path={`${match.url}/edit/:serviceUserId`}
                component={props => (
                  <Edit
                    updateServiceUser={serviceUser =>
                      updateServiceUser(serviceUser).then(res => {
                        return res;
                      })
                    }
                    {...props}
                  />
                )}
              />
              <Route component={Search} />
            </Switch>
          )}
        </ContractContext>
      </Page>
    </GraphQLProvider>
  </ErrorBoundary>
);

const mapStateToProps = state => ({
  contractId: state.contracts.selected.id,
  contract: state.contracts.selected,
});
const mapDispatchToProps = {
  addServiceUser,
  updateServiceUser,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceUsers);
