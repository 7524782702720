import fetch from '../../utils/fetch';

const mapLocationToApiValues = values => ({
  ...values,
  parentReferenceId: values.parentLocation
    ? values.parentLocation.referenceId || values.parentReferenceId
    : undefined,
});

export const addLocation = (location, contractId) => (dispatch, getState) =>
  fetch('api/location', {
    method: 'POST',
    body: JSON.stringify({
      item: mapLocationToApiValues(location),
      contractId,
    }),
  })(dispatch, getState).then(res => res);

export const updateLocation = values => (dispatch, getState) =>
  fetch(`api/location/${values.id}`, {
    method: 'PUT',
    body: JSON.stringify({
      contractId: values.contract.id,
      item: mapLocationToApiValues(values),
    }),
  })(dispatch, getState).then(res => res);
