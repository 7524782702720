import React from 'react';
import { styled } from 'styletron-react';
import style from '../../../style';
import MarkdownViewer from './MarkdownViewer';
import { removeLeadingSpaces } from '../../../utils/removeLeadingSpaces';

const PreviewHeading = styled('h4', {
  marginBottom: '5px',
});

const Error = styled('span', {
  color: style.red,
});
const CompiledTemplatePart = styled('div', ({ $body }) => ({
  background: style.superLight,
  padding: '12px 1.5rem',
  marginBottom: '1rem',
  minHeight: '40px',
  ...($body && {
    paddingTop: '1rem',
    minHeight: '390px',
  }),
}));

export default ({ templateErrors, compiledTemplate }) => {
  return (
    <div>
      <PreviewHeading>Subject</PreviewHeading>
      <CompiledTemplatePart>
        {(templateErrors && templateErrors.subject && <Error>{templateErrors.subject}</Error>) ||
          (compiledTemplate && compiledTemplate.subject)}
      </CompiledTemplatePart>
      <PreviewHeading>Body</PreviewHeading>
      {(templateErrors && templateErrors.body && (
        <CompiledTemplatePart $body>
          <Error>{templateErrors.body}</Error>
        </CompiledTemplatePart>
      )) || (
        <CompiledTemplatePart $body>
            {compiledTemplate && <MarkdownViewer markdownText={removeLeadingSpaces(compiledTemplate.body)} />}
          </CompiledTemplatePart>
      )}
    </div>
  );
};
