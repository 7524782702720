export default {
  onUpdate: registration => {
    registration.unregister().then(() => {
      caches
        .keys()
        .then(cacheNames => {
          cacheNames.forEach(cacheName => {
            caches.open(cacheName).then(cache => {
              // We need to delete index.html since it otherwise wount be updated and the workbox-precache will "point to the prevoius version"
              cache.delete('/index.html');
            });
          });
        })
        .then(() => {
          window.location.reload(true);
        });
    });
  },
  onSuccess: registration => {
    //If we want to inform the user there is a new version, this is where we do it.
  },
};
