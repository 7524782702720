import { gql } from '@apollo/client';
export const LOCATIONS_QUERY = gql`
  query LocationsQuery($contractId: Int!) {
    contract(id: $contractId) {
      id
      name
      locations {
        ...location
        locations {
          ...location
          locations {
            ...location
            locations {
              ...location
            }
          }
        }
      }
    }
  }

  fragment location on LocationGraphType {
    id
    name
    referenceId
    status
  }
`;
