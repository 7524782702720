import React from 'react';
import { styled } from 'styletron-react';
import style from '../../style';
import { configOptionType } from '../../features/appConfig/types';
import WithConfig from '../../features/appConfig/WithConfig';
import QuestionMarkFilled from '../Icons/QuestionMarkFilled';
import LinkedIcon from '../LinkedIcon';

const HelpLink = () => (
  <HelpLinkWrapper>
    <WithConfig>
      {config => (
        <LinkedIcon
          icon={<QuestionMarkFilled size="20" fill={style.dark} />}
          to={config.options.length > 0 && config.options.filter(o => o.id === configOptionType.helpPageLink)[0].value}
          target="_blank"
          title="Link to help page"
        />
      )}
    </WithConfig>
  </HelpLinkWrapper>
);

// Styled components
const HelpLinkWrapper = styled('div', {
  padding: '0 1em',
});

export default HelpLink;
