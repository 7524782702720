import React from 'react';
import { connect } from 'react-redux';
import Notification from '../../../utils/promiseNotification';
import { PageContent, PageHeading, PageHeader } from '../../../components/Page';
import Form from '../Form';
import { createContract } from '../action-creators';

const defaultContract = {
  name: '',
  isEditable: true,
};

const AddContract = ({ createContract }) => (
  <div>
    <PageHeader>
      <PageHeading>Add Customer</PageHeading>
    </PageHeader>
    <PageContent>
      <Form
        contract={defaultContract}
        save={values =>
          createContract(values)
            .then(Notification.success('Customer added'))
            .catch(Notification.error('Error: Adding customer failed'))}
      />
    </PageContent>
  </div>
);

export default connect(undefined, (dispatch, props) => ({
  createContract: contract =>
    dispatch(createContract(contract)).then(result =>
      props.history.replace(`/customers/${result.id}`)
    ),
}))(AddContract);
