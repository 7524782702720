import React, { Fragment } from 'react';
import { styled } from 'styletron-react';
import { makeListItem } from '../../../components/List';
import { makeLinkWrapper, StyledLink as PageLink } from '../../../components/Page';

const LinkListItem = makeListItem(PageLink);
const LinkWrapper = makeLinkWrapper(LinkListItem);

const LocationBase = styled('a', ({ $level }) => ({
  paddingLeft: `${$level + 1}rem`,
}));

const Location = ({ match, id, name, locations = [], level }) => (
  <Fragment>
    <LocationBase $as={LinkWrapper} key={id} to={`${match.path}/edit/${id}`} $level={level}>
      <div>{name}</div>
    </LocationBase>
    {locations
      .filter(child => child.status !== 'InActive')
      .map(child => (
        <Location key={child.id} level={level + 1} {...child} match={match} />
      ))}
  </Fragment>
);

export default Location;
