import React from 'react';
import { styled, withStyle } from 'styletron-react';
import { Input } from './Input';
import { ErrorMessage as Error } from './MiscPresentational.js';

const PinFieldWrapper = styled('div', {
  width: '10rem',
  marginBottom: '1rem',
});
const FieldHeading = styled('div', {
  margin: '1.2em 0 10px',
});
const ErrorMessage = withStyle(Error, {
  paddingLeft: '0',
  marginTop: '0.5em',
});

const PinCodeField = props => (
  <PinFieldWrapper>
    <FieldHeading>PIN is required</FieldHeading>
    <Input
      autoFocus
      onChange={e => props.onChange(e.target.value)}
      $invalid={props.errors && props.errors.length > 0}
    />
    {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
  </PinFieldWrapper>
);

export default PinCodeField;
