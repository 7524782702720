import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, NavLink } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import Page, {
  PageHeader,
  PageHeading,
  PageContent,
  makeLinkWrapper,
  TabNav,
  makeTab,
  activeTabStyle,
  StyledLink as PageLink,
} from '../../components/Page';
import Edit from './Edit';
import Add from './Add';
import List, { makeListItem } from '../../components/List';
import GraphQLProvider from '../../components/GraphQLProvider';

const LinkListItem = makeListItem(PageLink);

const LinkWrapper = makeLinkWrapper(LinkListItem);
const Tab = makeTab(NavLink);

const ContractsIndex = ({ contracts, match }) => (
  <div>
    <PageHeader>
      <PageHeading>Customers</PageHeading>
    </PageHeader>
    <PageContent>
      <List>
        {contracts.map(c => (
          <LinkWrapper key={c.id} to={`${match.path}/edit/${c.id}`}>
            {c.name}
          </LinkWrapper>
        ))}
      </List>
    </PageContent>
  </div>
);

const Contracts = ({ match, contracts, isAdmin }) => (
  <ErrorBoundary>
    <GraphQLProvider>
      <Page noContractSelector>
        <TabNav outOfBase>
          <Tab
            exact
            to={`${match.path}`}
            activeStyle={activeTabStyle}
            isActive={(match, location) => {
              if (!match) {
                return location.pathname.startsWith('/customers/edit');
              }
              return match.path === location.pathname;
            }}
          >
            Customers
          </Tab>
          {isAdmin && (
            <Tab to={`${match.path}/add`} activeStyle={activeTabStyle}>
              Add
            </Tab>
          )}
        </TabNav>
        <Switch>
          {isAdmin ? (
            <Route path={`${match.path}/add`} component={Add} />
          ) : (
            <Route path={`${match.path}/add`} render={() => <div>You're not authorized to perform this action</div>} />
          )}
          <Route path={`${match.path}/edit/:contractId`} component={Edit} />
          <Route
            component={props => (
              <ContractsIndex {...props} isAdmin={isAdmin} contracts={contracts} />
            )}
          />
        </Switch>
      </Page>
    </GraphQLProvider>
  </ErrorBoundary>
);

export default connect(state => ({
  contracts: state.contracts.items,
  isAdmin: state.authentication.isAdmin,
}))(Contracts);
