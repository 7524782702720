import React from 'react';
import { styled } from 'styletron-react';
import style from '../style';

const TableWrapper = styled('div', {
  overflow: 'auto',
  marginBottom: '1.3rem',
});

const Table = styled('table', {
  width: '100%',
});

// Table Header
const TableHead = styled('thead', ({ $background, $color }) => ({
  padding: '0.5rem',
  background: $background || 'none',
  color: $color || 'inherit',
  border: `1px solid ${$background}`,
}));

const TableHeader = ({ background, color, children }) => (
  <TableHead $background={background} $color={color}>
    <tr>{children}</tr>
  </TableHead>
);

const TableHeaderCell = styled('th', {
  padding: '0.5rem',
});

// Table Body
const TableBody = styled('tbody');

const TableRow = styled('tr', ({ $clickable }) => ({
  ':nth-child(odd)': {
    background: style.superLight,
  },
  ':nth-child(even)': {
    background: style.white,
  },
  ...($clickable && {
    cursor: 'pointer',
    ':hover': {
      background: style.superLight,
    },
  }),
}));

const TableCell = styled('td', {
  border: `solid 1px ${style.light}`,
  padding: '0.5rem',
});

export { Table, TableHeader, TableHeaderCell, TableBody, TableRow, TableCell, TableWrapper };
