import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import List from '../List';
import SearchField from '../SearchField';
import searchUsers from '../../features/serviceUsers/searchUsers';
import Overlay from '../../components/Overlay';
import UserItem from './UserItem';
import { styled, withStyle } from 'styletron-react';
import { mergeWithDefinition } from '../../utils/customFields';

const userDisplayName = (user) => `${user.firstName} ${user.lastName} (${user.email})`;

const UserPicker = forwardRef(
  (
    {
      active,
      placeholder,
      user,
      focused,
      query,
      onChange,
      onKeyDown,
      setFocused,
      loading,
      users = [],
      onSelected,
      selectedIndex,
      onClose,
    },
    ref
  ) => (
    <UserPickerWrapper>
      <Overlay isActive={active} toggle={active ? onClose : undefined} lightbox>
        <SearchWrapper $active={active} onClick={active ? onClose : undefined}>
          <SearchField
            ref={ref}
            type="text"
            placeholder={placeholder || 'Search recipient'}
            value={user && !focused ? userDisplayName(user) : query}
            onChange={onChange}
            onKeyDown={(e) => onKeyDown(e)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            loading={loading}
          />
          {active && (
            <UserListWrapper>
              <UserList>
                {users.map((u, i) => (
                  <UserItem key={u.id} user={u} active={selectedIndex === i} onSelected={() => onSelected(u)} />
                ))}
              </UserList>
            </UserListWrapper>
          )}
        </SearchWrapper>
      </Overlay>
    </UserPickerWrapper>
  )
);

const DEFAULT_STATE = {
  query: '',
  suggestions: [],
  selectedIndex: -1,
  active: false,
  focused: false,
  loading: false,
};

const UserPickerContainer = ({
  contract,
  currentUserId,
  includeAssigneeUsers,
  searchUsers,
  user,
  deselectOnEmpty,
  onChange,
}) => {
  const [state, setState] = useState(DEFAULT_STATE);

  const searchDebounced = useRef(
    debounce((query) => {
      if (!!query) {
        setState((prevState) => ({ ...prevState, loading: true }));
        searchUsers({
          contractId: contract.id,
          currentUserId,
          includeAssigneeUsers,
          query,
        }).then((res) =>
          res.json().then((users) => {
            setState((prevState) => ({
              ...prevState,
              active: !!prevState.query,
              loading: false,
              suggestions: users.map((user) => ({
                ...user,
                customFields: mergeWithDefinition(contract.serviceUserFields, user.customFields),
              })),
            }));
          })
        );
      } else {
        setState((prevState) => ({ ...prevState, active: false, loading: false }));
      }
    }, 300)
  ).current;

  useEffect(() => {
    if (user && !state.user) {
      setState(DEFAULT_STATE);
    }
  }, [user, state.user]);

  const onQueryChange = (query) => {
    setState({ ...state, query });
    searchDebounced(query);
    if (!query && deselectOnEmpty) {
      onSelected(undefined);
    }
  };

  const onSelected = (user) => {
    setState({ ...state, active: false, focused: false });
    onChange(user);
  };

  const onKeyDown = (e) => {
    const { active, selectedIndex, suggestions } = state;
    if (!suggestions.length) {
      return;
    }
    switch (e.key) {
      case 'Escape':
        return setState({ ...state, active: false });
      case 'ArrowDown':
        return setState((prevState) => ({
          ...state,
          active: true,
          selectedIndex: prevState.active
            ? Math.min(prevState.suggestions.length - 1, prevState.selectedIndex + 1)
            : prevState.selectedIndex,
        }));
      case 'ArrowUp':
        return setState((prevState) => ({
          ...state,
          active: true,
          selectedIndex: prevState.active ? Math.max(0, prevState.selectedIndex - 1) : prevState.selectedIndex,
        }));
      case 'Enter':
        if (active && selectedIndex >= 0) {
          e.preventDefault();
          onSelected(suggestions[selectedIndex]);
        }
        return;
      default:
        break;
    }
  };

  return (
    <UserPicker
      {...state}
      users={state.suggestions}
      onChange={(e) => onQueryChange(e.target.value)}
      onKeyDown={(e) => onKeyDown(e)}
      setFocused={(focused) => setState({ ...state, focused })}
      onSelected={(u) => onSelected(u)}
      onClose={() => setState({ ...state, active: false })}
    />
  );
};

const UserPickerWrapper = styled('div', {
  position: 'relative',
});

const UserList = withStyle(List, {
  width: 'auto',
  background: 'white',
  border: 'none',
  borderRadius: 'none',
});

const UserListWrapper = styled('div', {
  marginTop: 0,
  overflow: 'auto',
});

const SearchWrapper = styled('div', ({ $active }) => ({
  display: 'flex',
  flexDirection: 'column',
  ...($active && {
    position: 'fixed',
    top: '1rem',
    right: '1rem',
    bottom: '1rem',
    left: '1rem',
    zIndex: 3,
  }),
}));

const mapStateToProps = (state) => ({
  contract: state.contracts.selected,
});
const mapDispatchToProps = {
  searchUsers,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserPickerContainer);
