import React from 'react';
import Field, {
  TextAreaField as TextArea,
  CheckboxField as Checkbox,
  Select,
  FieldError,
  FormGroup,
  FormLabel,
} from '../../components/Field';
import Button from '../../components/Button';
import withForm from '../../components/withForm';
import { RightAlign } from '../../components/Layout';
import { withApollo } from '@apollo/client/react/hoc';

const ContractForm = ({ values, onChange, onSubmit, isSubmitting, contract, disabled, errors, client }) => {
  const isDisabled = isSubmitting || !contract.isEditable || disabled;
  const shipmentTypeIsEmpty = !values.hasLetters && !values.hasParcels;
  const disableSubmit = isDisabled || shipmentTypeIsEmpty;
  return (
    <form>
      <FormGroup>
        <Field
          label="Name of contract/customer"
          name="name"
          errors={errors.name}
          value={values.name || ''}
          disabled={isDisabled}
          onChange={onChange}
        />
        <Field
          label="Active Directory group id"
          name="adGroupId"
          errors={errors.adGroupId}
          value={values.adGroupId || ''}
          disabled={isDisabled}
          onChange={onChange}
        />
        <Field
          label="Current shipment number (determines the start number of the Shipment ID)"
          name="currentConsignmentNumber"
          errors={errors.currentConsignmentNumber}
          value={values.currentConsignmentNumber || undefined}
          disabled={isDisabled}
          type="Integer"
          min={0}
          onChange={onChange}
        />

        <TextArea
          label="Description"
          name="description"
          value={values.description || ''}
          disabled={isDisabled}
          onChange={onChange}
        />
        <FormLabel>Shipment types</FormLabel>
        <Checkbox
          label="Letters"
          name="hasLetters"
          onChange={onChange}
          checked={values.hasLetters}
          disabled={isDisabled}
        />
        <Checkbox
          label="Parcels"
          name="hasParcels"
          onChange={onChange}
          checked={values.hasParcels}
          disabled={isDisabled}
        />
        {shipmentTypeIsEmpty && <FieldError>You must choose at least one kind of shipment type</FieldError>}
        <FormLabel>Deviation</FormLabel>
        <Checkbox
          label=" Enable Deviation"
          name="enableDeviation"
          onChange={onChange}
          checked={values.hasParcels}
          disabled={isDisabled}
        />

      </FormGroup>

      <FormGroup>
        <h2>Invoice data</h2>
        <Field
          label="Contact person"
          name="invoiceContactPerson"
          errors={errors.invoiceContactPerson}
          value={values.invoiceContactPerson || undefined}
          disabled={isDisabled}
          onChange={onChange}
        />
        <Select
          label="Company"
          name="invoiceCompany"
          errors={errors.invoiceCompany}
          value={values.invoiceCompany || undefined}
          disabled={isDisabled}
          onChange={onChange}
        >
          <option value="">- Select company -</option>
          <option value="Coor Svc Mgmt AS">Coor Svc Mgmt AS</option>
          <option value="Coor Svc Mgmt A/S">Coor Svc Mgmt A/S</option>
          <option value="Coor Svc Mgmt AB">Coor Svc Mgmt AB</option>
          <option value="Coor Svc Mgmt Oy">Coor Svc Mgmt Oy</option>
        </Select>

        <Field
          label="Cost center"
          name="invoiceCostCenter"
          errors={errors.invoiceCostCenter}
          value={values.invoiceCostCenter || undefined}
          disabled={isDisabled}
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup>
        <h2>Anonymization</h2>
        <Checkbox
          label="Anonymize shipments older than 3 months"
          name="anonymizeConsignments"
          onChange={onChange}
          checked={values.anonymizeConsignments}
          disabled={isDisabled}
        />
      </FormGroup>

      <FormGroup>
        <h2>Notifications</h2>
        <FormLabel>Generate notifications for these shipment types</FormLabel>
        <p>Unchecking these will display notifications for historic shipments.</p>
        <Checkbox
          label="Letters"
          name="sendsNotificationsForLetters"
          onChange={onChange}
          checked={values.sendsNotificationsForLetters}
          disabled={isDisabled}
        />
        <Checkbox
          label="Parcels"
          name="sendsNotificationsForParcels"
          onChange={onChange}
          checked={values.sendsNotificationsForParcels}
          disabled={isDisabled}
        />
      </FormGroup>

      <RightAlign>
        <Button
          primary
          disabled={disableSubmit}
          onClick={e =>
            onSubmit(e).then(_ => {
              // This is a bit aggressive, but until everything is based on GraphQL we have to make due.
              // Also, almost all queries are based on the "Contract" node, meaning that almost everything should invalidate anyways.
              // This is mainly used to reset "Unsent notifications" when updating settings for which shipment types that the contract
              // sends out notifications for.

              // TOMAS: This might not be necessary anymore!!!
              // client.resetStore()
              return _;
            })
          }
        >
          Save
        </Button>
      </RightAlign>
    </form>
  );
};

export default withForm({
  mapPropsToValues: props => props.contract,
  mapPropsToSubmit: props => props.save,
})(withApollo(ContractForm));
