import React from 'react';
import { styled } from 'styletron-react';

const Wrapper = styled('span', {
  display: 'flex',
  height: '18px',
  width: '36px',
  alignItems: 'center',
  justifyContent: 'space-around',
});

const Dot = styled('i', ({ $color }) => ({
  verticalAlign: 'middle',
  display: 'block',
  padding: '0 3px',
  marginTop: '4px',
  width: '6px',
  height: '7px',
  borderRadius: '50%',
  backgroundColor: $color || 'currentColor',
  opacity: '1',
  animation: 'spinner ease-in-out 1s infinite alternate',
  ':nth-child(1)': {
    animationDelay: '0s',
  },
  ':nth-child(2)': {
    animationDelay: '0.2s',
  },
  ':nth-child(3)': {
    animationDelay: '0.3s',
  },
}));

const Spinner = ({ color }) => (
  <Wrapper>
    <Dot $color={color} />
    <Dot $color={color} />
    <Dot $color={color} />
  </Wrapper>
);

export default Spinner;
