import React, { Fragment } from 'react';

const PersonWithMail = ({ firstName, lastName, email, bold = false }) => (
  <Fragment>
    {bold ? (
      <strong>
        {firstName} {lastName}
      </strong>
    ) : (
      `${firstName} ${lastName}`
    )}{' '}
    ({email})
  </Fragment>
);

export default PersonWithMail;
