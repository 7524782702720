import React from 'react';
import { RightAlign } from '../../../components/Layout';
import Field, { TextAreaField } from '../../../components/Field';
import Button from '../../../components/Button';
import withForm from '../../../components/withForm';
import { htmlToMarkdown } from '../../../utils/htmlToMarkdown';

const Form = ({ isSubmitting, values, onSubmit, onChange, renderPreview, hasErrors }) => (
  <form>
    <Field
      label="Subject"
      name="subject"
      onChange={e => {
        onChange(e);
        renderPreview(e);
      }}
      value={values.subject || ''}
      disabled={isSubmitting}
    />
    <TextAreaField
      label="Body"
      name="body"
      onChange={e => {
        onChange(e);
        renderPreview(e);
      }}
      rows="20"
      wrap="off"
      value={values && values.body ? htmlToMarkdown(values.body) : ''}
      disabled={isSubmitting}
      style={{ resize: 'none' }}
    />
    <RightAlign>
      <Button primary onClick={() => onSubmit()} disabled={isSubmitting || hasErrors}>
        Save
      </Button>
    </RightAlign>
  </form>
);

export default withForm({
  mapPropsToValues: props => props.mailTemplate,
  mapPropsToSubmit: props => props.save,
})(Form);
