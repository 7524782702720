import React from 'react';
import { connect } from 'react-redux';

import { pathBuilder } from '../../../utils/fetch';
import Notification from '../../../utils/promiseNotification';
import ShipmentList from '../ShipmentList';
import { sendReminders, deliverMultiple } from '../action-creators';
import { PageContent, PageHeading, PageHeader, FilterRow, FilterCol, FilterLabel } from '../../../components/Page';
import { toQuery, fromQuery } from '../../../utils/uri';
import isEqual from 'lodash.isequal';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import { FromObservable } from 'rxjs/observable/FromObservable';
import { Subject } from 'rxjs/Subject';
import moment from 'moment';
import UserPicker from '../../../components/UserPicker';
import Button from '../../../components/Button';
import DateRangePicker from '../../../components/DateRangePicker';
import { styled } from 'styletron-react';
import SearchField from '../../../components/SearchField';
import Pagination from '../../../components/Pagination';
import { Select } from '../../../components/Select';
import Actions from './Actions';
import LocationSelector from '../../../components/LocationSelector';
import { Consumer as UserDataConsumer } from '../../../components/UserData';
import { search, getExportLink } from './action-creators';

const SelectedShipmentsBase = styled('div', {
  marginTop: '2rem',
});

const defaultState = {
  items: [],
  loading: false,
  minDate: moment().subtract(2, 'months'),
  maxDate: moment(),
  checkedShipments: {},
  sendingReminders: false,
};

class Search extends React.Component {
  constructor(props) {
    super(props);

    const q = fromQuery(props.location.search);

    this.state = {
      ...defaultState,
      ...(q.mindate && { minDate: moment(decodeURIComponent(q.mindate)) }),
      ...(q.maxdate && { maxDate: moment(decodeURIComponent(q.maxdate)) }),
    };
    this.params$ = new Subject();
    this.searchStream = this.params$.debounceTime(600).mergeMap(params => {
      this.setState({ loading: true });
      return FromObservable.create(this.props.search(params));
    });

    this.searchSubscription = this.params$
      .debounceTime(600)
      .mergeMap(params => {
        this.setState({ loading: true });
        return FromObservable.create(this.props.search(params));
      })
      .subscribe(({ shipments, ...links }) => {
        this.setState({ items: shipments, links, loading: false });
      });
  }
  componentDidMount() {
    this.search(this.props.params);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.contractId !== nextProps.contractId) {
      this.setState(defaultState);
    }
    this.search(nextProps.params, this.props.params);
  }
  search(params, oldParams) {
    if (!oldParams || !isEqual(params, oldParams)) {
      this.params$.next(params);
    }
  }
  itemSelected(shipmentId) {
    this.props.history.push(pathBuilder('/shipments/', shipmentId));
  }
  componentWillUnmount() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }
  setParams(params) {
    const { history, match } = this.props;
    const resetParams = {
      ...params,
      offset: 0,
    };
    history.replace(`${match.path}?${toQuery(resetParams)}`);
  }
  mergeParams(diff) {
    this.setParams({
      ...fromQuery(this.props.location.search),
      ...diff,
    });
  }
  setUser(selectedUser) {
    this.setState({ selectedUser });
    this.mergeParams({
      serviceuserid: selectedUser && selectedUser.id,
    });
  }
  dateClosed({ minDate, maxDate }) {
    this.mergeParams({
      mindate: minDate && minDate.toISOString(),
      maxdate: maxDate && maxDate.toISOString(),
    });
  }
  filterStatus(status) {
    this.mergeParams({
      status,
    });
  }
  shipmentChecked(shipment, checked) {
    this.setState(state => {
      const newChecked = { ...state.checkedShipments };
      if (checked) {
        newChecked[shipment.id] = { ...shipment, isChecked: true };
      } else {
        delete newChecked[shipment.id];
      }
      return {
        ...state,
        checkedShipments: newChecked,
      };
    });
  }
  sendReminders() {
    this.setState({ sendingReminders: true });
    return this.props
      .sendReminders(Object.keys(this.state.checkedShipments))
      .then(result => {
        this.setState({ sendingReminders: false, checkedShipments: {} });
        return result;
      })
      .then(Notification.success('Reminders sent'))
      .catch(Notification.error('Error occured: Reminders not sent'));
  }
  deliver(identity) {
    const { deliverMultiple } = this.props;
    const { checkedShipments } = this.state;
    const deliverableShipments = Object.keys(checkedShipments).map(id => checkedShipments[id]);

    return deliverMultiple(deliverableShipments, identity)
      .then(result => {
        this.setState(state => ({
          checkedShipments: {},
          items: state.items.map(item => (state.checkedShipments[item.id] ? { ...item, status: 'Delivered' } : item)),
        }));
        return result;
      })
      .then(Notification.success('Shipments marked as Delivered'))
      .catch(Notification.error('Error occured: Shipments not marked as Delivered'));
  }
  render() {
    const { items, links, checkedShipments } = this.state;
    const { match, contractId } = this.props;
    const params = fromQuery(this.props.location.search);
    return (
      <div className="search-shipments">
        <PageHeader>
          <PageHeading>Existing Shipments</PageHeading>
        </PageHeader>

        <PageContent>
          <FilterRow>
            <FilterCol>
              <FilterLabel title="Shipment number">
                <SearchField
                  autoFocus
                  value={params.consignmentnumber || ''}
                  onChange={e =>
                    this.setParams({
                      ...params,
                      consignmentnumber: e.target.value,
                    })
                  }
                  loading={this.state.loading}
                />
              </FilterLabel>
            </FilterCol>
            <FilterCol>
              <FilterLabel title="Recipient">
                <UserPicker
                  onChange={selectedUser => this.setUser(selectedUser)}
                  user={this.state.selectedUser}
                  placeholder="Search"
                  deselectOnEmpty={true}
                />
              </FilterLabel>
            </FilterCol>
            <FilterCol>
              <FilterLabel title="Current location">
                <LocationSelector
                  contractId={contractId}
                  allowUnset={true}
                  value={this.props.params.currentlocation || ''}
                  onChange={location => {
                    this.props.onLocationChange('currentLocation', location);
                    this.setParams({
                      ...params,
                      currentlocation: location ? location.id : '',
                    });
                  }}
                />
              </FilterLabel>
            </FilterCol>
            <FilterCol>
              <FilterLabel title="Delivery location">
                <LocationSelector
                  contractId={contractId}
                  allowUnset={true}
                  value={this.props.params.deliverylocation || ''}
                  onChange={location => {
                    this.props.onLocationChange('deliveryLocation', location);
                    this.setParams({
                      ...params,
                      deliverylocation: location ? location.id : '',
                    });
                  }}
                />
              </FilterLabel>
            </FilterCol>
          </FilterRow>
          <FilterRow>
            <FilterCol>
              <FilterLabel title="Forwarder">
                <SearchField
                  value={params.forwarder || ''}
                  onChange={e =>
                    this.setParams({
                      ...params,
                      forwarder: e.target.value,
                    })
                  }
                  loading={this.state.loading}
                />
              </FilterLabel>
            </FilterCol>
            <FilterCol>
              <FilterLabel title="Reference number">
                <SearchField
                  value={params.referencenumber || ''}
                  onChange={e =>
                    this.setParams({
                      ...params,
                      referencenumber: e.target.value,
                    })
                  }
                  loading={this.state.loading}
                />
              </FilterLabel>
            </FilterCol>
            <FilterCol>
              <FilterLabel title="Date range">
                <DateRangePicker
                  startDate={this.state.minDate}
                  endDate={this.state.maxDate}
                  onDatesChange={({ startDate, endDate }) => this.setState({ minDate: startDate, maxDate: endDate })}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={focusedInput => this.setState({ focusedInput })}
                  onClose={({ startDate, endDate }) =>
                    this.dateClosed({
                      minDate: startDate,
                      maxDate: endDate,
                    })
                  }
                  isOutsideRange={day => day > defaultState.maxDate}
                />
              </FilterLabel>
            </FilterCol>
            <FilterCol>
              <FilterLabel title="Status">
                <Select value={params.status} onChange={e => this.filterStatus(e.target.value)}>
                  <option>All</option>
                  <option value="registered">Registered</option>
                  <option value="delivered">Delivered</option>
                  <option value="cleared">Cleared</option>
                  <option value="exception">Exception</option>
                  <option value="deviation">Deviation</option>
                </Select>
              </FilterLabel>
            </FilterCol>
          </FilterRow>
          <ShipmentList
            shipments={items.map(s => ({
              ...s,
              isChecked: checkedShipments[s.id],
            }))}
            onItemSelected={shipmentId => this.itemSelected(shipmentId)}
            onItemChecked={(shipmentId, checked) => this.shipmentChecked(shipmentId, checked)}
            onAllItemChecked={checked => items.map(i => this.shipmentChecked(i, checked))}
          />
          <UserDataConsumer>
            {({ data, setData }) => {
              const { shipment = {} } = data;
              return (
                <Pagination
                  links={links}
                  match={match}
                  location={this.props.location}
                  history={this.props.history}
                  limit={(shipment.search && shipment.search.limit) || 10}
                  limitMoved={({ limit, uri }) => {
                    setData({
                      shipment: {
                        ...shipment,
                        search: {
                          ...(shipment && shipment.search),
                          limit,
                        },
                      },
                    });
                    this.props.history.replace(uri);
                  }}
                  actions={<Button onClick={() => this.props.getExportLink()}>Export search result (.xls)</Button>}
                />
              );
            }}
          </UserDataConsumer>
          {Object.keys(checkedShipments).length > 0 && (
            <SelectedShipmentsBase>
              <h3>Selected shipments</h3>
              <Actions
                shipments={Object.keys(checkedShipments).map(key => checkedShipments[key])}
                sendReminders={() => this.sendReminders()}
                deliver={identity => this.deliver(identity)}
              />
              <ShipmentList
                shipments={Object.keys(checkedShipments).map(key => checkedShipments[key])}
                onItemChecked={(shipmentId, checked) => this.shipmentChecked(shipmentId, checked)}
              />
            </SelectedShipmentsBase>
          )}
        </PageContent>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  contractId: state.contracts.selected.id,
});
const mapDispatchToProps = {
  search,
  sendReminders,
  deliverMultiple,
  getExportLink,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(props => (
  <UserDataConsumer>
    {({ data, setData }) => {
      const { shipment: { filters = {}, search = {} } = {} } = data;

      const params = {
        query: '',
        consignmentnumber: '',
        offset: 0,
        orderby: 'registeredDate',
        sortdescending: true,
        ...fromQuery(props.location.search),
        limit: (search && search.limit) || 10,
        contractId: props.contractId,
        currentlocation: filters.currentLocation || '',
        deliverylocation: filters.deliveryLocation || '',
      };

      const onLocationChange = (type, location) => {
        setData({
          shipment: {
            filters: {
              [type]: location ? location.id : null,
            },
          },
        });
      };

      return (
        <Search
          {...props}
          getExportLink={() =>
            props.getExportLink(params).then(location => {
              document.location = location;
            })
          }
          params={params}
          onLocationChange={onLocationChange}
        />
      );
    }}
  </UserDataConsumer>
));
