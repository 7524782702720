import React from 'react';
import { styled } from 'styletron-react';

import Button from '../../../components/Button';

const LocationSelector = ({ locations, selectLocation }) => (
  <div>
    <div>You must select your current location before registering shipments</div>
    <LocationTree>
      {locations &&
        locations
          .filter(l => l.status !== 'InActive')
          .map(l => <Location key={l.id} {...l} selectLocation={selectLocation} />)}
    </LocationTree>
  </div>
);

const Location = ({ selectLocation, ...location }) => (
  <li>
    <Button link onClick={() => selectLocation(location.id)}>
      {location.name}
    </Button>
    {location.locations && (
      <ul>
        {location.locations
          .filter(child => child.status !== 'InActive')
          .map(childLocation => (
            <Location key={childLocation.id} {...childLocation} selectLocation={selectLocation} />
          ))}
      </ul>
    )}
  </li>
);

const LocationTree = styled('ul', {
  marginTop: '1rem',
});

export default LocationSelector;
