import fetch from '../../utils/fetch';
import moment from 'moment';

export const SHIPMENTS_FETCHED = 'SHIPMENTS_FETCHED';
export const SHIPMENT_LOAD = 'SHIPMENT_LOAD';
export const SHIPMENT_LOAD_SUCCESS = 'SHIPMENT_LOAD_SUCCESS';
export const SHIPMENT_DELIVER = 'SHIPMENT_DELIVER';
export const SHIPMENT_DELIVER_SUCCESS = 'SHIPMENT_DELIVER_SUCCESS';
export const SHIPMENT_DELIVER_FAILURE = 'SHIPMENT_DELIVER_FAILURE';
export const SHIPMENT_REMOVE = 'SHIPMENT_REMOVE';
export const SHIPMENT_REMOVE_SUCCESS = 'SHIPMENT_REMOVE_SUCCESS';
export const SHIPMENT_CURRENT_LOCATION = 'SHIPMENT_CURRENT_LOCATION';
export const SHIPMENTS_DELIVER_SUCCESS = 'SHIPMENTS_DELIVER_SUCCESS';
export const SEND_REMINDERS_SUCCESS = 'SEND_REMINDERS_SUCCESS';
export const COUNT_DEVIATION_IMAGES = 'COUNT_DEVIATION_IMAGES';
export const ADD_DEVIATION_IMAGES = 'ADD_DEVIATION_IMAGES';
export const REMOVE_DEVIATION_IMAGES = 'REMOVE_DEVIATION_IMAGES';

export const load = shipmentId => (dispatch, getState) => {
  dispatch({
    type: SHIPMENT_LOAD,
    shipmentId,
  });
  return fetch(`api/consignment/${shipmentId}`)(dispatch, getState).then(data => {
    dispatch({
      type: SHIPMENT_LOAD_SUCCESS,
      shipmentId,
      data,
    });
  });
};

export const fetched = shipments => ({
  type: SHIPMENTS_FETCHED,
  shipments,
});

export const deliver = (shipmentId, pin, identity) => (dispatch, getState) => {
  dispatch({
    type: SHIPMENT_DELIVER,
    shipmentId,
    pin,
    identity,
  });
  return fetch(`api/consignment/${shipmentId}`, {
    method: 'PATCH',
    body: JSON.stringify({
      status: 1,
      eventTime: moment(new Date()).format(),
      pin,
      identity,
    }),
  })(dispatch, getState)
    .then(res => {
      dispatch({
        type: SHIPMENT_DELIVER_SUCCESS,
        shipmentId,
      });
      return res;
    })
    .catch(e =>
      dispatch({
        type: SHIPMENT_DELIVER_FAILURE,
        shipmentId,
        errors: e.data,
      })
    );
};

export const remove = shipmentId => (dispatch, getState) => {
  if (window.confirm('Are you sure you want to remove this shipment?')) {
    dispatch({
      type: SHIPMENT_REMOVE,
      shipmentId,
    });
    return fetch(`api/consignment/${shipmentId}`, {
      method: 'POST',
      body: JSON.stringify({
        eventTime: moment(new Date()).format(),
      }),
    })(dispatch, getState).then(res => {
      dispatch({ type: SHIPMENT_REMOVE_SUCCESS, shipmentId, data: res });
      return res;
    });
  }
  return Promise.resolve();
};


export const isDamagedGoods = shipmentId => (dispatch, getState) => {
  if (window.confirm('Are you sure you want to mark this shipment as damaged')) {
    dispatch({
      type: SHIPMENT_DELIVER,
      shipmentId,
    });
    return fetch(`api/consignment/${shipmentId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        isDamagedGoods: true,
        eventTime: moment(new Date()).format()
      }),
    })(dispatch, getState)
      .then(res => {
        dispatch({
          type: SHIPMENT_DELIVER_SUCCESS,
          shipmentId,
        });
        return res;
      })
      .catch(e =>
        dispatch({
          type: SHIPMENT_DELIVER_FAILURE,
          shipmentId,
          errors: e.data,
        })
      );
  }
  return Promise.resolve();
};

export const deliverMultiple = (shipments, identity) => (dispatch, getState) => {
  const deliverableShipments = shipments
    .filter(s => s.status === 'Registered')
    .map(s => ({
      id: s.id,
      identity: s.deliveryLocation.requiresIdentityVerification && identity ? identity : undefined,
      status: 1,
      eventTime: moment(new Date()).format(),
    }));

  return fetch('api/consignment/', {
    method: 'PATCH',
    body: JSON.stringify(deliverableShipments),
  })(dispatch, getState).then(res => {
    dispatch({
      type: SHIPMENTS_DELIVER_SUCCESS,
      consignments: deliverableShipments,
    });
    return res;
  });
};

export const sendReminders = shipmentIds => (dispatch, getState) => {
  return fetch('api/notification/reminder', {
    method: 'POST',
    body: JSON.stringify({
      consignmentIds: shipmentIds,
      reminderSent: moment(new Date()).format(),
    }),
  })(dispatch, getState).then(res => {
    dispatch({ type: SEND_REMINDERS_SUCCESS });
    return res;
  });
};
export const currentLocationChanged = (shipmentId, location) => ({
  type: SHIPMENT_CURRENT_LOCATION,
  shipmentId,
  location,
});

export const isDeviation = shipmentId => (dispatch, getState) => {
 
    dispatch({ type: SHIPMENT_DELIVER, shipmentId });
    return fetch(`api/consignment/${shipmentId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        Deviation: true,
        eventTime: moment(new Date()).format()
      }),
    })(dispatch, getState)
      .then(res => {
        dispatch({
          type: SHIPMENT_DELIVER_SUCCESS,
          shipmentId,
        });
        return res;
      })
      .catch(e =>
        dispatch({
          type: SHIPMENT_DELIVER_FAILURE,
          shipmentId,
          errors: e.data,
        })
      );
};

export const patchDeviationNote = (shipmentId, note) => (dispatch, getState) => {
  
    dispatch({ type: SHIPMENT_DELIVER, shipmentId });
    
    return fetch(`api/consignment/${shipmentId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        Deviation: true,
        DeviationNote: note,
        eventTime: moment(new Date()).format()
      }),
    })(dispatch, getState)
      .then(res => {
        dispatch({
          type: SHIPMENT_DELIVER_SUCCESS,
          shipmentId,
        });
        return res;
      })
      .catch(e =>
        dispatch({
          type: SHIPMENT_DELIVER_FAILURE,
          shipmentId,
          errors: e.data,
        })
      );
  
};

export const deleteImage = (imageId, shipmentId) => (dispatch, getState) => {
  if (window.confirm('Are you sure you want to delete?')) {
    dispatch({
      type: SHIPMENT_DELIVER,
      shipmentId,
    });
    return fetch(`api/images/${imageId}`, {
      method: 'DELETE',
      body: JSON.stringify({
        eventTime: moment(new Date()).format()
      }),
    })(dispatch, getState)
      .then(res => {
        dispatch({
          type: REMOVE_DEVIATION_IMAGES,
          shipmentId,
        });
        console.log('Image deleted')
        return res;
      })
      .catch(e =>
        dispatch({
          type: SHIPMENT_DELIVER_FAILURE,
          shipmentId,
          errors: e.data,
        })
      );
  }
  return Promise.resolve();
};

export const countDeviationImages = (shipmentId) => (dispatch, getState) => {
   return fetch(`api/images/${shipmentId}/count`)(dispatch, getState).then(data => {
      dispatch({  type: COUNT_DEVIATION_IMAGES, shipmentId, data });
      return data;
    });
};
