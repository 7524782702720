import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { PageHeading, PageHeader, PageContent } from '../../../components/Page';
import File from '../../../components/File';
import Button from '../../../components/Button';
import { styled } from 'styletron-react';
import style from '../../../style';
import Notification from '../../../utils/promiseNotification';
import { Table, TableWrapper, TableHeader, TableHeaderCell, TableRow, TableCell } from '../../../components/Table';

import { parseFile, importUsers, deactivateUsers, getExportLink } from './action-creators';

const MAX_USERS_IN_TABLE = 10;

const parseFileName = fileName => {
  const segments = fileName.split('/');
  return segments[segments.length - 1];
};

const ActionButton = styled('button', {
  marginLeft: 'auto !important',
});

class Import extends React.Component {
  state = {
    deactivationEnabled: false,
  };

  handleImportClick = () => {
    const { onImport, contractId, userImport } = this.props;
    onImport(contractId, userImport);
    // .then(Notification.success('User import successful'))
    // .catch(Notification.error('User import failed'));
  };

  handleDeactivateClick = () => {
    const { onDeactivate, contractId, userImport } = this.props;
    onDeactivate(contractId, userImport.importResult)
      .then(
        Notification.success(
          `Great! You have now deactivated ${userImport.importResult.usersToDeactivate.length} recipients`
        )
      )
      .catch(Notification.error('Error: User deactivation failed'));
  };

  render() {
    const { userImport, onFileChange, getExportLink } = this.props;
    return (
      <div>
        <PageHeader>
          <PageHeading>Import Recipients </PageHeading>
          <ActionButton $as={Button} primary onClick={getExportLink}>
            Export existing recipients
          </ActionButton>
        </PageHeader>
        <PageContent>
          <form>
            <File
              label={userImport.filename ? parseFileName(userImport.filename) : 'Select file (.xls)'}
              onChange={event => onFileChange(event.target.files[0])}
              accept=".xls, .xlsx"
            />
            <Button
              onClick={this.handleImportClick}
              disabled={!userImport.users || !userImport.users.length || userImport.isImporting}
              primary
            >
              {userImport.isParsing && 'Parsing file...'}
              {userImport.isImporting && 'Importing file...'}
              {!userImport.isParsing && !userImport.isImporting && 'Import'}
            </Button>

            {userImport.importResult &&
              userImport.importResult.usersToDeactivate &&
              userImport.importResult.usersToDeactivate.length > 0 && (
                <React.Fragment>
                  <Button
                    onClick={this.handleDeactivateClick}
                    disabled={userImport.isDeactivating || !this.state.deactivationEnabled}
                  >
                    {userImport.isDeactivating && 'Deactivating...'}
                    {!userImport.isDeactivating &&
                      'Deactivate ' + userImport.importResult.usersToDeactivate.length + ' recipients'}
                  </Button>{' '}
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.deactivationEnabled}
                      onChange={e =>
                        this.setState({
                          deactivationEnabled: e.target.checked,
                        })
                      }
                    />{' '}
                    Check to enable deactivation
                  </label>
                </React.Fragment>
              )}
            {userImport.error && (
              <div>
                <div>
                  <b>Error: {userImport.error ? userImport.error.message : ''}</b>
                </div>
                <ul>
                  {userImport.error.data &&
                    Object.keys(userImport.error.data)
                      .map(key => `${key}: ${userImport.error.data[key]}`)
                      .map((msg, i) => <li key={i}>{msg}</li>)}
                </ul>
              </div>
            )}
            {userImport.users && userImport.users.length > 0 && (
              <Fragment>
                <p>
                  Recipients to import: {userImport.users.length}{' '}
                  {userImport.users.length > MAX_USERS_IN_TABLE &&
                    '(showing first ' + MAX_USERS_IN_TABLE + ' in table)'}
                </p>
                <TableWrapper>
                  <Table>
                    <TableHeader background={style.primary} color={style.white}>
                      {userImport.columns.map((column, i) => (
                        <TableHeaderCell key={i}>{column}</TableHeaderCell>
                      ))}
                    </TableHeader>
                    <tbody>
                      {userImport.users.slice(0, MAX_USERS_IN_TABLE).map((userValues, i) => (
                        <TableRow key={i}>
                          {userImport.columns.map((_, j) => (
                            <TableCell key={j}>{userValues[j]}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </TableWrapper>
              </Fragment>
            )}

            {userImport.importResult && Object.keys(userImport.importResult).length > 0 && (
              <div>
                {userImport.importResult.invalidUsers && userImport.importResult.invalidUsers.length > 0 ? (
                  <h2>Watch out! Your import was not totally successful. Here are the import result:</h2>
                ) : (
                  <h2>Congratulations! Your import was successful. Here are the import result:</h2>
                )}
                <div>Added recipients: {userImport.importResult.addedServiceUsers}</div>
                <div>Updated recipients: {userImport.importResult.modifiedServiceUsers}</div>
                <div>Unchanged recipients: {userImport.importResult.unchangedServiceUsers}</div>
                {userImport.importResult.usersToDeactivate && userImport.importResult.usersToDeactivate.length > 0 && (
                  <div>
                    There were {userImport.importResult.deactivatedServiceUsers} recipients that was not included in the
                    import file. If you wish to deactivate all those recipients, click on the button "Deactivate
                    recipients".
                  </div>
                )}

                {userImport.importResult.invalidUsers && userImport.importResult.invalidUsers.length > 0 && (
                  <Fragment>
                    <p>
                      Errors found in file: {userImport.importResult.invalidUsers.length}{' '}
                      {userImport.importResult.invalidUsers.length > MAX_USERS_IN_TABLE &&
                        ' (showing first ' + MAX_USERS_IN_TABLE + ' in table)'}
                    </p>
                    <TableWrapper>
                      <Table>
                        <TableHeader background={style.statusRed} color={style.white}>
                          <TableHeaderCell>Line number</TableHeaderCell>
                          <TableHeaderCell>ReferenceId</TableHeaderCell>
                          <TableHeaderCell>Errors</TableHeaderCell>
                        </TableHeader>
                        <tbody>
                          {userImport.importResult.invalidUsers.slice(0, MAX_USERS_IN_TABLE).map(su => (
                            <TableRow key={su.referenceId + su.lineNumber}>
                              <TableCell>{su.lineNumber}</TableCell>
                              <TableCell>{su.referenceId}</TableCell>
                              <TableCell>
                                <ul>
                                  {su.errors.map((error, i) => (
                                    <li key={i}>{error}</li>
                                  ))}
                                </ul>
                              </TableCell>
                            </TableRow>
                          ))}
                        </tbody>
                      </Table>
                    </TableWrapper>
                  </Fragment>
                )}

                {userImport.importResult.usersToDeactivate && userImport.importResult.usersToDeactivate.length > 0 && (
                  <Fragment>
                    <p>
                      Recipients to be deactivated: {userImport.importResult.usersToDeactivate.length}{' '}
                      {userImport.importResult.usersToDeactivate.length > MAX_USERS_IN_TABLE &&
                        ' (showing first ' + MAX_USERS_IN_TABLE + ' in table)'}
                    </p>
                    <TableWrapper>
                      <Table>
                        <TableHeader background={style.primary} color={style.white}>
                          <TableHeaderCell>First name</TableHeaderCell>
                          <TableHeaderCell>Last name</TableHeaderCell>
                          <TableHeaderCell>Mail</TableHeaderCell>
                        </TableHeader>
                        <tbody>
                          {userImport.importResult.usersToDeactivate.slice(0, MAX_USERS_IN_TABLE).map(su => (
                            <TableRow key={su.id}>
                              <TableCell>{su.firstName}</TableCell>
                              <TableCell>{su.lastName}</TableCell>
                              <TableCell>{su.email}</TableCell>
                            </TableRow>
                          ))}
                        </tbody>
                      </Table>
                    </TableWrapper>
                  </Fragment>
                )}
              </div>
            )}
          </form>
        </PageContent>
      </div>
    );
  }
}

export default connect(
  state => ({
    contractId: state.contracts.selected.id,
    userImport: state.serviceUsers.userImport,
  }),
  {
    onFileChange: parseFile,
    onImport: importUsers,
    onDeactivate: deactivateUsers,
    getExportLink,
  },
  (state, dispatch) => ({
    ...state,
    ...dispatch,
    getExportLink: () =>
      dispatch.getExportLink(state.contractId).then(location => {
        document.location = location;
      }),
  })
)(Import);
