import React from 'react';
import { styled } from 'styletron-react';
import { toQuery, fromQuery, getQueryPart } from '../utils/uri';
import { StyledLink as PageLink } from './Page';

const PullRight = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const PaginationLink = styled('a', {
  margin: '0 5px',
});

const PageSizeLabel = styled('label', {
  marginBottom: 0,
});

const PageSize = styled('select', {
  marginLeft: '0.5rem',
});

const Actions = styled('div', {
  marginRight: 'auto',
});

const moveLimit = (match, location, limit) => {
  const newQuery = toQuery({
    ...fromQuery(location.search),
    limit,
  });
  return `${match.path}?${newQuery}`;
};

export const GraphPagination = ({ pageInfo, match, location, history, actions, hidePageSize = false }) => {
  const queryParams = fromQuery(location.search);
  return (
    <PullRight>
      {actions && <Actions>{actions}</Actions>}
      {!hidePageSize && (
        <PageSizeLabel>
          Page size
          <PageSize
            onChange={e =>
              history.replace(
                `${match.path}?${toQuery({
                  ...queryParams,
                  first: e.target.value,
                })}`
              )
            }
            value={+queryParams.first || 10}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </PageSize>
        </PageSizeLabel>
      )}
      {pageInfo.hasPreviousPage && (
        <PaginationLink
          $as={PageLink}
          to={`${match.path}?${toQuery({
            ...queryParams,
            after: undefined,
            before: pageInfo.startCursor,
          })}`}
        >
          Previous
        </PaginationLink>
      )}
      {pageInfo.hasNextPage && (
        <PaginationLink
          $as={PageLink}
          to={`${match.path}?${toQuery({
            ...queryParams,
            after: pageInfo.endCursor,
            before: undefined,
          })}`}
        >
          Next
        </PaginationLink>
      )}
    </PullRight>
  );
};

const Pagination = ({ links, match, location, limitMoved, limit, actions }) => {
  const cleanLinks = Object.keys(links || {}).reduce((acc, key) => {
    const { contractid, ...cleanQuery } = fromQuery(getQueryPart(links[key]));    
    return {
      ...acc,
      [key]: toQuery(cleanQuery),
    };
  }, {});
  return (
    <PullRight>
      {actions && <Actions>{actions}</Actions>}
      <PageSizeLabel>
        Page size
        <PageSize
          onChange={e =>
            limitMoved({
              uri: moveLimit(match, location, e.target.value),
              limit: e.target.value,
            })
          }
          value={+limit || 10}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </PageSize>
      </PageSizeLabel>
      {cleanLinks.first && (
        <PaginationLink $as={PageLink} to={`${match.path}?${cleanLinks.first}`}>
          First
        </PaginationLink>
      )}
      {cleanLinks.prev && (
        <PaginationLink $as={PageLink} to={`${match.path}?${cleanLinks.prev}`}>
          Previous
        </PaginationLink>
      )}
      {cleanLinks.next && (
        <PaginationLink $as={PageLink} to={`${match.path}?${cleanLinks.next}`}>
          Next
        </PaginationLink>
      )}
    </PullRight>
  );
};

export default Pagination;
