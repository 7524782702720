import React from 'react';
import { styled } from 'styletron-react';
import { Row } from '../../../../components/Layout';
import { Input } from '../../../../components/Input';
import Button from '../../../../components/Button';

const fieldWithAddedOption = field => ({
  ...field,
  options: (field.options || []).concat([{ value: '', active: true }]),
});

const fieldRemoveOption = (field, optionIndex) => ({
  ...field,
  options: field.options.filter((_, i) => i !== optionIndex),
});

const fieldToggleOption = (field, optionIndex) => ({
  ...field,
  options: field.options.map((option, i) => (i !== optionIndex ? option : { ...option, active: !option.active })),
});

const fieldEditOption = (field, optionIndex, value) => ({
  ...field,
  options: field.options.map((option, i) => (i !== optionIndex ? option : { ...option, value })),
});

const CustomFieldOptions = ({ item, disabled, onChange }) => (
  <OptionsBase>
    <h4>Options</h4>
    <ul>
      {(item.options || []).map((option, i) => (
        <CustomFieldOption
          key={i}
          option={option}
          disabled={disabled}
          onButtonClick={() => onChange(option.id ? fieldToggleOption(item, i) : fieldRemoveOption(item, i))}
          onInputChange={e => onChange(fieldEditOption(item, i, e.target.value))}
        />
      ))}
    </ul>
    <div>
      <Button onClick={() => onChange(fieldWithAddedOption(item))} disabled={disabled}>
        Add option
      </Button>
    </div>
  </OptionsBase>
);

const CustomFieldOption = ({ option, disabled, onInputChange, onButtonClick }) => (
  <CustomFieldOptionBase $inActive={!option.active}>
    <Row>
      <Input disabled={disabled} value={option.value} onChange={onInputChange} />
      <Button disabled={disabled} onClick={onButtonClick}>
        {!option.active ? 'Activate' : option.id ? 'Deactivate' : 'Remove'}
      </Button>
    </Row>
  </CustomFieldOptionBase>
);

const OptionsBase = styled('div', {
  marginTop: '1rem',
});

const CustomFieldOptionBase = styled('li', ({ $inActive }) => ({
  paddingBottom: '0.5rem',
  paddingLeft: '0.5rem',
  opacity: $inActive ? '0.6' : '1',
}));

export default CustomFieldOptions;
