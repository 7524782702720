import React from 'react';
import { styled } from 'styletron-react';
import spinnerGif from '../style/static/coor-spinner.gif';

const LoaderWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '15rem',
});

export default () => (
  <LoaderWrapper>
    <img className="spinner" src={spinnerGif} alt="Loading" title="Loading" />
  </LoaderWrapper>
);
