import React from 'react';
import { withStyle } from 'styletron-react';
import withForm from '../../../../components/withForm';
import Button from '../../../../components/Button';
import { RightAlign } from '../../../../components/Layout';
import Group from './CustomFieldsGroup';

const GROUPS = {
  consignmentFields: 'Shipment fields',
  letterFields: 'Letter fields',
  parcelFields: 'Parcel fields',
  serviceUserFields: 'Service user fields',
};

const withAddedGroupItem = (groupName, groups) => ({
  [groupName]: groups[groupName].concat({ name: '', type: 'String' }),
});

const CustomFields = ({ contract, values, errors, onValuesChange, onSubmit, isSubmitting }) => (
  <form>
    {Object.keys(GROUPS).map(group => (
      <Group
        key={group}
        contractId={contract.id}
        label={GROUPS[group]}
        items={values[group]}
        errors={errors[group]}
        disabled={isSubmitting || !contract.isEditable}
        onAdd={() => onValuesChange(withAddedGroupItem(group, values))}
        onChange={items => onValuesChange({ [group]: items })}
      />
    ))}
    <BottomRow>
      <i>Will save all fields in edit customer fields section</i>{' '}
      <Button primary disabled={!contract.isEditable} onClick={onSubmit}>
        Save
      </Button>
    </BottomRow>
  </form>
);

export default withForm({
  mapPropsToValues: ({ contract }) => ({
    consignmentFields: contract.consignmentFields,
    letterFields: contract.letterFields,
    parcelFields: contract.parcelFields,
    serviceUserFields: contract.serviceUserFields,
  }),
  mapPropsToSubmit: props => props.save,
})(CustomFields);

// Styled components
const BottomRow = withStyle(RightAlign, {
  padding: '20px',
});
