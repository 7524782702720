import fetch from '../../utils/fetch';

export const STICKER_CONFIGURATION_LOAD = 'STICKER_CONFIGURATION_LOAD';
export const STICKER_CONFIGURATION_LOAD_SUCCESS =
  'STICKER_CONFIGURATION_LOAD_SUCCESS';
export const STICKER_CONFIGURATION_EDIT = 'STICKER_CONFIGURATION_EDIT';
export const STICKER_CONFIGURATION_EDIT_SUCCESS =
  'STICKER_CONFIGURATION_EDIT_SUCCESS';

export const load = contractId => (dispatch, getState) => {
  dispatch({
    type: STICKER_CONFIGURATION_LOAD,
    contractId,
  });
  const onFinally = result => {
    dispatch({
      type: STICKER_CONFIGURATION_LOAD_SUCCESS,
      contractId,
      data: result,
    });
    return result;
  };
  return fetch(`api/contract/${contractId}/stickerconfiguration`)(
    dispatch,
    getState
  ).then(onFinally, onFinally);
};

export const edit = (contractId, configuration) => (dispatch, getState) => {
  dispatch({
    type: STICKER_CONFIGURATION_EDIT,
    contractId,
  });
  return fetch(`api/contract/${contractId}/stickerconfiguration`, {
    method: 'PUT',
    body: JSON.stringify(configuration),
  })(dispatch, getState).then(results => {
    dispatch({
      type: STICKER_CONFIGURATION_EDIT_SUCCESS,
      contractId,
    });
    return results;
  });
};
