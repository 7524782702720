import React from 'react';
import { Link } from 'react-router-dom';
import { styled, withStyle } from 'styletron-react';
import style from '../style';
import { makeListItem } from './List';
import { Row, Col } from './Layout';

const PageWrapper = styled('div', ({ $noContractSelector }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '40px',
  marginTop: $noContractSelector ? '90px' : '20px',
}));

const PageBase = styled('div', {
  position: 'relative',
  background: 'white',
  padding: style.compactBodyPadding,
  ...style.md({
    padding: '20px',
  }),
});

export const PageHeader = styled('header', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '0',
  paddingBottom: '0',
  paddingLeft: style.compactBodyPadding,
  paddingRight: style.compactBodyPadding,
  ...style.md({
    paddingLeft: '20px',
    paddingRight: '20px',
  }),
});

export const PageHeading = styled('h1', {
  fontSize: '24px',
  margin: 0,
  padding: '20px 0',
  fontWeight: style.boldFont,
});

export const PageActionButton = styled('button', {
  marginLeft: 'auto !important',
});

export const TabNav = styled('nav', {
  display: 'flex',
  flexDirection: 'row',
});

export const activeTabStyle = {
  background: '#fff',
  color: style.primary,
};

export const PageDescription = styled('div', {
  fontStyle: 'italic',
  padding: '0 20px',
  color: style.textColorLight,
});

export const PageContent = styled('div', {
  padding: style.compactBodyPadding,
  ...style.md({
    padding: '20px',
  }),
});

export const makeTab = Component =>
  styled(Component, {
    display: 'flex',
    alignItems: 'center',
    background: style.primary,
    color: '#fff',
    ':hover': {
      color: '#fff',
      background: style.primaryDarker,
      textDecoration: 'none',
    },
    ':focus': {
      color: '#fff',
      background: style.primaryDarker,
      outline: 'none',
    },
    padding: '0.8rem 1.8rem',
    marginRight: '0.5em',
  });

const InnerBadge = styled('div', ({ $fetching, $count }) => ({
  position: 'absolute',
  fontSize: '0.8em',
  width: '1.6rem',
  height: '1.6rem',
  lineHeight: '1.6rem',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  borderRadius: '50%',
  background: style.dark,
  boxShadow: '0 0 0 rgba(254,254,254, 0.4)',
  color: $fetching && $count === 0 ? style.dark : '#fff',
  // Animation
  ...($count === 0
    ? {
        opacity: 0,
      }
    : $count
    ? {
        animation: 'badge 2s',
        animationIterationCount: '1',
      }
    : {}),
  // ...animation,
}));

const BadgeHolder = styled('div', {
  right: '-0.7rem',
  position: 'relative',
  lineHeight: '18px',
  width: '18px',
  height: '18px',
});

export const TabBadge = ({ fetching, count }) => (
  <BadgeHolder>
    <InnerBadge $fetching={fetching} $count={count}>
      {count}
    </InnerBadge>
  </BadgeHolder>
);

export const makeLinkWrapper = Component => styled(Component, { padding: '12px 16px' });

export const StyledLink = props => {
  const Component = styled('a', {
    textDecoration: 'none',
    color: style.primary,
    ':hover': {
      color: style.primary,
    },
    ':focus': {
      color: style.primary,
    },
  });
  return <Component $as={Link} {...props} />;
};

const LinkListItem = makeListItem(Link);

export const LinkWrapper = styled(LinkListItem, {
  padding: '12px 16px',
});

export const FilterRow = withStyle(Row, {
  justifyContent: 'space-between',
});

export const FilterCol = withStyle(Col, {
  flexBasis: 1,
  flexGrow: 1,
});

const LabelBase = styled('label', {
  fontSize: '0.8rem',
  display: 'block',
});

const LabelTitle = styled('div', {
  padding: '0.25rem',
  display: 'inline-block',
});

export const FilterLabel = ({ title, children }) => (
  <LabelBase>
    <LabelTitle>{title}</LabelTitle>
    {children}
  </LabelBase>
);

const Page = ({ noContractSelector, ...props }) => {
  const OutOfBaseChildren = React.Children.map(props.children, c => {
    const divProps = Object.assign({}, c.props);
    delete divProps.outOfBase;

    return !c.props.outOfBase
      ? null
      : {
          ...c,
          props: divProps,
        };
  });
  const Children = React.Children.map(props.children, c => (!c.props.outOfBase ? c : null));

  return (
    <PageWrapper $noContractSelector={noContractSelector}>
      {OutOfBaseChildren}
      <PageBase>{Children}</PageBase>
    </PageWrapper>
  );
};

export default Page;
