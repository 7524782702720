import React, { Fragment } from 'react';
import { styled, withStyle } from 'styletron-react';
import { isException } from './status';
import { PageHeading, PageHeader, PageContent } from '../../components/Page';
import Button from '../../components/Button';
import Print from '../../components/Print/PrintShipments';
import PinCodeField from '../../components/PinCodeField';
import IdentityVerificationField from '../../components/IdentityVerificationField';
import LocationSelector from '../../components/LocationSelector';
import { state as asyncState } from '../../utils/fetch';
import { formatDate } from '../../utils/dateUtil';
import Notification from '../../utils/promiseNotification';
import { TABS_COUNT_QUERY } from '../shipment/index';
import { withApollo } from '@apollo/client/react/hoc';
import ReactModal from 'react-modal';
import ImagesView from '../../components/ImagesView';
import ShowImage from '../../components/Images/AddToPhotos';
import Filter from '../../components/Images/Filter';
import ImageUpload from '../../components/ImageUpload';
import Edit from '../../components/Images/Edit';
import { TextAreaField as TextArea } from '../../components/Field';

const shipmentStatus = {
  registered: 'Registered',
};

const modalStyle = {
  content: {
    display: 'block',
    overflow: 'auto',
    margin: '0 auto',
    position: 'unset',
    maxWidth: '500px',
    maxHeight: 'calc(80vh)',
    marginTop: '5%',
  },
};

class DetailsView extends React.Component {
  state = {
    pin: undefined,
    identity: undefined,
    showImages: false,
    imagesCount: 0,
    addImages: false,
    editDiviationNote: false,
    deviationNote: this.props.shipment.deviationNote,
  };

  componentDidMount() {
    const { shipment, countDeviationImages } = this.props;

    countDeviationImages(shipment.id).then((data) => {
      const { count } = data;
      this.setState({
        imagesCount: count,
      });
    });
  }

  onPinChange = (pin) => {
    this.setState({ pin });
  };

  onIdentityChange = (identity) => {
    this.setState({ identity });
  };

  onDeviationNoteChange = (deviationNote) => {
    this.setState({ deviationNote });
  };

  saveDiviationNote(id, note) {
    const { patchDeviationNote } = this.props;
    patchDeviationNote(id, note);
    this.setState({
      showImages: false,
      editDiviationNote: false,
      addImages: false,
    });
  }

  handleDeviationClick(id) {
    const { isDeviation } = this.props;
    isDeviation(id);
  }

  showEditDiviationNote(e) {
    e.preventDefault();
    this.setState({
      editDiviationNote: true,
    });
  }

  showShowImagesModal(e) {
    e.preventDefault();
    this.setState({
      showImages: true,
    });
  }

  closeModal(e) {
    e.preventDefault();
    this.setState({
      showImages: false,
      editDiviationNote: false,
      addImages: false,
    });
  }

  handleDeliverClick = (shipmentId) => {
    const { deliver, shipment, client } = this.props;
    const { pin, identity } = this.state;
    return deliver(shipmentId, pin, identity)
      .then(Notification.success('Shipment delivered'))
      .then((result) => {
        // Fetch tab badge numbers, network-only to force a call to the server
        client.query({
          query: TABS_COUNT_QUERY,
          variables: {
            contractId: shipment.contract.id,
          },
          fetchPolicy: 'network-only',
        });
        return result;
      })
      .catch(Notification.error('Error occured: Shipment not delivered'));
  };

  handleShowImagesClick = (shipmentId) => {
    this.setState({
      showImages: true,
    });
  };

  handleIShowDiviationNoteClick = (shipmentId) => {
    this.setState({
      editDiviationNote: true,
    });
  };

  handleAddImagesClick = (shipmentId) => {
    this.setState({
      addImages: true,
    });
  };

  handleRemoveClick = (shipmentId) => {
    const { remove, shipment, client } = this.props;
    return remove(shipmentId)
      .then(Notification.success('Shipment removed'))
      .then((result) => {
        // Fetch tab badge numbers, network-only to force a call to the server
        client.query({
          query: TABS_COUNT_QUERY,
          variables: {
            contractId: shipment.contract.id,
          },
          fetchPolicy: 'network-only',
        });
        return result;
      })
      .catch(Notification.error('Error: Shipment not removed'));
  };

  render() {
    const { shipment, setCurrentLocation, isCompact, deleteImage } = this.props;
    const { deviationNote, imagesCount } = this.state;
    const additionalInformation = []
      .concat(
        shipment.type === 'Parcel'
          ? [
              {
                title: 'Number of packages',
                value: shipment.numberOfPackages,
              },
              {
                title: 'Forwarder',
                value: shipment.forwarder,
              },
              {
                title: 'Reference number',
                value: shipment.referenceNumber,
              },
            ]
          : []
      )
      .concat(
        Object.keys(shipment.customFields).map((field) => ({
          title: field,
          value: shipment.customFields[field],
        }))
      );

    return (
      <Fragment>
        <PageHeader>
          <PageHeading>Shipment: {shipment.consignmentNumber}</PageHeading>
        </PageHeader>
        <PageContent>
          <Row>
            {shipment.receiver ? (
              <ShipmentPerson label="Recipient" name={`${shipment.receiver.firstName} ${shipment.receiver.lastName}`} />
            ) : (
              <ShipmentPerson label="Recipient" name="ANONYMIZED" />
            )}

            {shipment.assignee && (
              <ShipmentPerson label="Assignee" name={`${shipment.assignee.firstName} ${shipment.assignee.lastName}`} />
            )}
          </Row>
          <Row>
            <Section>
              <Information>
                <InformationTitle>Shipment type</InformationTitle>: <InformationValue>{shipment.type}</InformationValue>
              </Information>

              <Information>
                <InformationTitle>Arrived on</InformationTitle>:{' '}
                <InformationValue>{formatDate(shipment.registeredDate)}</InformationValue>
              </Information>

              {shipment.deviation && (
                <InformationWarning>
                  <InformationTitle>
                    Deviation
                    <span style={{ marginLeft: '20' }}>
                      {shipment.deviation && imagesCount > 0 && (
                        <>
                          <Filter />
                          <a
                            style={{ color: 'orange', textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => this.handleShowImagesClick(shipment.id)}
                          >{`Show Images (${imagesCount})`}</a>
                        </>
                      )}

                      <ShowImage />
                      <a
                        style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => this.handleAddImagesClick(shipment.id)}
                      >
                        Add image
                      </a>
                    </span>
                  </InformationTitle>
                </InformationWarning>
              )}
              {shipment.deviation && (
                <InformationWarning>
                  <InformationTitle>Deviation note</InformationTitle>:{' '}
                  <InformationValue>
                    {deviationNote}
                    <Edit />
                    <a
                      style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => this.handleIShowDiviationNoteClick(shipment.id)}
                    >
                      Edit
                    </a>
                  </InformationValue>
                </InformationWarning>
              )}

              <ReactModal isOpen={this.state.showImages} style={modalStyle}>
                <button
                  onClick={(e) => document.location.reload()}
                  style={{ color: 'orange', textDecoration: 'underline', float: 'right', cursor: 'pointer' }}
                >
                  Close
                </button>
                <ImagesView
                  id={shipment.id}
                  name={`${shipment.receiver.firstName} ${shipment.receiver.lastName}`}
                  deleteImage={deleteImage}
                />
              </ReactModal>

              <ReactModal isOpen={this.state.addImages} style={modalStyle}>
                <ImageUpload id={shipment.id} />
                <Button onClick={(e) => document.location.reload()} style={{ float: 'right' }} primary>
                  Done
                </Button>
              </ReactModal>

              <ReactModal isOpen={this.state.editDiviationNote} style={modalStyle}>
                <button
                  onClick={(e) => document.location.reload()}
                  style={{ color: 'orange', textDecoration: 'underline', float: 'right', cursor: 'pointer' }}
                >
                  Close
                </button>
                <label>Deviation Note</label>
                <TextArea value={deviationNote || ''} onChange={(e) => this.onDeviationNoteChange(e.target.value)} />
                <Button
                  primary
                  style={{ float: 'right' }}
                  onClick={(e) => this.saveDiviationNote(shipment.id, deviationNote)}
                >
                  Save
                </Button>
              </ReactModal>

              <LocationSelector
                label="Current location"
                contractId={shipment.contract.id}
                value={shipment.currentLocation && shipment.currentLocation.id}
                onChange={(location) => setCurrentLocation(location.id)}
              />

              <SectionHeader>Deliver to</SectionHeader>
              <div>{shipment.deliveryLocation.name}</div>

              {additionalInformation.length > 0 && <SectionHeader>Additional information</SectionHeader>}
              {additionalInformation.map((information, i) => (
                <Information key={i}>
                  <InformationTitle>{information.title}</InformationTitle>:{' '}
                  <InformationValue>{information.value}</InformationValue>
                </Information>
              ))}
            </Section>

            <Status>
              <SectionHeader>Status</SectionHeader>
              <p>{shipment.status}</p>
              <div>
                {shipment.requirePin && shipment.status !== 'Delivered' && (
                  <PinCodeField
                    shipmentId={shipment.id}
                    onChange={(pin) => this.onPinChange(pin)}
                    error={shipment.errors && shipment.errors.pin}
                  />
                )}

                {shipment.status === shipmentStatus.registered && (
                  <Fragment>
                    <Button
                      type="button"
                      onClick={() => this.handleDeliverClick(shipment.id)}
                      disabled={
                        [asyncState.done, asyncState.exception].indexOf(shipment.state) === -1 ||
                        (shipment.requirePin && !this.state.pin)
                      }
                      primary
                    >
                      Deliver
                    </Button>
                    {shipment.requirePin && (
                      <Button
                        type="button"
                        onClick={() => this.handleDeliverClick(shipment.id)}
                        disabled={[asyncState.done, asyncState.exception].indexOf(shipment.state) === -1}
                      >
                        Deliver without PIN
                      </Button>
                    )}
                  </Fragment>
                )}
                {!isException(shipment.status) && (
                  <Button
                    onClick={() => this.handleRemoveClick(shipment.id)}
                    disabled={shipment.state !== asyncState.done}
                    danger
                  >
                    Remove
                  </Button>
                )}

                {!shipment.deviation && (
                  <Button
                    type="button"
                    onClick={() => this.handleDeviationClick(shipment.id)}
                    disabled={shipment.state !== asyncState.done}
                    danger
                  >
                    Register deviation
                  </Button>
                )}

                {shipment.deliveryLocation.requiresIdentityVerification &&
                  shipment.status !== 'Delivered' &&
                  shipment.type === 'Parcel' && (
                    <IdentityVerificationField
                      shipmentId={shipment.id}
                      onChange={(identity) => this.onIdentityChange(identity)}
                    />
                  )}

                {!isCompact && (
                  <PrintSection>
                    <Print shipments={[shipment]} />
                  </PrintSection>
                )}
              </div>
            </Status>
          </Row>
          <Events>
            <SectionHeader>Events</SectionHeader>
            <EventsList reversed>
              {shipment.eventLog
                .concat([])
                .sort((a, b) => (new Date(a.eventDate) > new Date(b.eventDate) ? -1 : 1))
                .map((event, i) => (
                  <EventsListItem key={`event-${i}`}>
                    <strong>{formatDate(event.eventDate)}</strong> - {event.eventType} ({event.user || 'Unknown'}):{' '}
                    {event.comment}
                  </EventsListItem>
                ))}
            </EventsList>
          </Events>
        </PageContent>
      </Fragment>
    );
  }
}

export default withApollo(DetailsView);

const ShipmentPerson = ({ label, name }) => (
  <ShipmentPersonWrapper>
    <label>{label}</label>
    <UserName>{name}</UserName>
  </ShipmentPersonWrapper>
);

// Styled Components
const ShipmentPersonWrapper = styled('div', {
  marginRight: '20px',
  marginBottom: '20px',
});
const Row = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
});

const UserName = styled('div', {
  fontSize: '1.5rem',
});

const Section = styled('div', {
  flexGrow: 1,
  flexBasix: 0,
  minWidth: '240px',
  paddingBottom: '20px',
});

const SectionHeader = styled('h3', {
  margin: '20px 0 0.75rem 0',
});

const Status = withStyle(Section, {
  padding: '0 20px 20px 20px',
  borderStyle: 'solid',
  borderWidth: '1px 1px 0 1px',
});

const Information = styled('div', {
  marginBottom: '0.25rem',
});

const InformationWarning = styled('div', {
  marginBottom: '0.25rem',
  color: '#D8000C',
});

const InformationTitle = styled('span', {
  fontWeight: 'bold',
});

const InformationValue = styled('span', {});

const Events = styled('div', {
  borderTop: 'solid 1px',
});

const EventsList = styled('ol', {
  listStyle: 'none',
  padding: 0,
});

const EventsListItem = styled('li', {
  marginBottom: '0.5rem',
});

const PrintSection = styled('div', {
  marginTop: '10px',
});
