import React from 'react';
import ContractContext from '../../contracts/ContractContext';
import { PageHeading, PageHeader, PageContent, PageDescription } from '../../../components/Page';
import Punchcard from '../../../components/statistics/Punchcard';
import StatisticType from '../../../components/statistics/StatisticType';
import { CONTRACT_STATISTICS_QUERY } from '../../Statistics/graph';
import GraphQLProvider from '../../../components/GraphQLProvider';
import { Query } from '@apollo/client/react/components';

export default () => (
  <ContractContext>
    {({ contract }) => (
      <React.Fragment>
        <div>
          <GraphQLProvider>
            <PageHeader>
              <PageHeading>Statistics</PageHeading>
            </PageHeader>
            <PageDescription>
              <p>
                This punchcard tells you what's your busiest time of the week. The two types are{' '}
                <StatisticType no={0} /> and <StatisticType no={1} />. A larger circle indicates more activity.
              </p>
              <p>The data presented are all shipments ever registered and delivered.</p>
            </PageDescription>
            <PageContent>
              <Query
                query={CONTRACT_STATISTICS_QUERY}
                skip={!(contract && contract.id)}
                variables={{ contractId: parseInt(contract.id) }}
              >
                {({ data }) => (!data ? null : <Punchcard punchcard={data.contract.statistics.punchcard} />)}
              </Query>
            </PageContent>
          </GraphQLProvider>
        </div>
      </React.Fragment>
    )}
  </ContractContext>
);
