import React from 'react';

const DEFAULT_STATE = {
  values: {},
  errors: {},
  formState: {},
  isSubmitting: false,
};

function validateEmail(value) {
  let isValid;
  if (!value) {
    isValid = true;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    isValid = false;
  } else isValid = true;

  return isValid;
}

const withForm = ({ mapPropsToValues, mapPropsToSubmit } = {}) => Component => {
  class Form extends React.Component {
    state = DEFAULT_STATE;

    onChange(e) {
      let { target } = e;
      this.setState(state => ({
        values: {
          ...state.values,
          [target.name]: target.type === 'checkbox' ? target.checked : target.value,
        },
      }));
    }

    onBlur(e) {
      console.log('onBlur', e);
      let result = validateEmail(e.target.value);
      this.setState(state => ({
        values: {
          ...state.values,
          isValidEmail: result,
        },
      }));
    }

    onValuesChange(values) {
      this.setState(state => ({
        values: {
          ...state.values,
          ...values,
        },
      }));
    }
    onSuccess() {
      this.resetForm();
    }
    onError(err) {
      this.setState({
        isSubmitting: false,
        errors: err ? err.data : {},
      });
    }
    setSubmitting() {
      this.setState({ isSubmitting: true });
    }
    submit() {
      const submit = mapPropsToSubmit(this.props);
      const values = {
        ...mapPropsToValues(this.props),
        ...this.state.values,
      };
      this.setSubmitting();
      return submit(values).then(() => this.onSuccess(), result => this.onError(result));
    }
    resetForm() {
      this.setState(DEFAULT_STATE);
    }
    render() {
      const { values } = this.state;

      return (
        <Component
          {...this.props}
          {...this.state}
          values={{
            ...(mapPropsToValues && mapPropsToValues(this.props)),
            ...values,
          }}
          onBlur={e => this.onBlur(e)}
          onChange={e => this.onChange(e)}
          onValuesChange={values => this.onValuesChange(values)}
          resetForm={() => this.resetForm()}
          onSubmit={() => this.submit()}
          onSuccess={result => this.onSuccess(result)}
          onError={result => this.onError(result)}
          setSubmitting={() => this.setSubmitting()}
          setFormState={formStateDiff =>
            this.setState(state => ({
              formState: { ...state.formState, ...formStateDiff },
            }))
          }
        />
      );
    }
  }
  Form.displayName = `Form(${Component.name})`;
  return Form;
};

export const Render = withForm({
  mapPropsToValues: props => props.defaultValues,
})(({ children, ...props }) => children(props));

export default withForm;
