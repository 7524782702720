import React from 'react';
import { connect } from 'react-redux';
import File from '../../../components/File';
import Button from '../../../components/Button';
import { PageHeader, PageHeading, PageContent, PageActionButton } from '../../../components/Page';
// import { styled } from 'styletron-react';
import Notification from '../../../utils/promiseNotification';
import { parseFile, importLocations, getExportLink } from './action-creators';
import { Table, TableWrapper, TableHeader, TableHeaderCell, TableRow, TableCell } from '../../../components/Table';
import style from '../../../style';

const MAX_LOCATIONS_IN_TABLE = 100;

const parseFileName = fileName => {
  const segments = fileName.split('/');
  return segments[segments.length - 1];
};

class Import extends React.Component {
  handleImportClick = () => {
    const { onImport, contractId, locationImport } = this.props;
    onImport(contractId, locationImport)
      .then(Notification.success('Location import successful'))
      .catch(Notification.error('Location import failed'));
  };

  render() {
    const { locationImport, onFileChange, getExportLink } = this.props;

    return (
      <div>
        <PageHeader>
          <PageHeading>Import Locations </PageHeading>
          <PageActionButton $as={Button} primary onClick={() => getExportLink()}>
            Export existing locations
          </PageActionButton>
        </PageHeader>
        <PageContent>
          <form>
            <File
              label={locationImport.filename ? parseFileName(locationImport.filename) : 'Select file (.xls)'}
              onChange={event => onFileChange(event.target.files[0])}
              accept=".xls, .xlsx"
            />
            <Button
              onClick={this.handleImportClick}
              disabled={!locationImport.locations || !locationImport.locations.length || locationImport.isImporting}
              primary
            >
              {locationImport.isParsing && 'Parsing file...'}
              {locationImport.isImporting && 'Importing file...'}
              {!locationImport.isParsing && !locationImport.isImporting && 'Import'}
            </Button>

            {locationImport.error && (
              <div>
                <div>
                  <b>Error: {locationImport.error ? locationImport.error.message : ''}</b>
                </div>
                <ul>
                  {locationImport.error.data &&
                    Object.keys(locationImport.error.data)
                      .map(key => `${key}: ${locationImport.error.data[key]}`)
                      .map((msg, i) => <li key={i}>{msg}</li>)}
                </ul>
              </div>
            )}

            {locationImport.locations && locationImport.locations.length > 0 && (
              <div>
                <div>
                  Locations to import: {locationImport.locations.length}{' '}
                  {locationImport.locations.length > MAX_LOCATIONS_IN_TABLE &&
                    '(showing first ' + MAX_LOCATIONS_IN_TABLE + ' in table)'}
                </div>
                <TableWrapper>
                  <Table>
                    <TableHeader background={style.primary} color={style.white}>
                      {locationImport.columns.map((column, i) => (
                        <TableHeaderCell key={i}>{column}</TableHeaderCell>
                      ))}
                    </TableHeader>
                    <tbody>
                      {locationImport.locations.slice(0, MAX_LOCATIONS_IN_TABLE).map((locationValues, i) => (
                        <TableRow key={i}>
                          {locationImport.columns.map((_, j) => (
                            <TableCell key={j}>{locationValues[j]}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </TableWrapper>
              </div>
            )}

            {locationImport.importResult && Object.keys(locationImport.importResult).length > 0 && (
              <div>
                <div>Import result:</div>
                <div>Added locations: {locationImport.importResult.addedLocations}</div>
                <div>Updated locations: {locationImport.importResult.modifiedLocations}</div>
                <div>Unchanged locations: {locationImport.importResult.unchangedLocations}</div>

                {locationImport.importResult.invalidLocations &&
                  locationImport.importResult.invalidLocations.length > 0 && (
                    <div>
                      <div>
                        Errors found in file: {locationImport.importResult.invalidLocations.length}{' '}
                        {locationImport.importResult.invalidLocations.length > MAX_LOCATIONS_IN_TABLE &&
                          ' (showing first ' + MAX_LOCATIONS_IN_TABLE + ' in table)'}
                      </div>
                      <Table striped bordered condensed hover>
                        <TableHeader background={style.primary} color={style.white}>
                          <TableHeaderCell>Line number</TableHeaderCell>
                          <TableHeaderCell>ReferenceId</TableHeaderCell>
                          <TableHeaderCell>Errors</TableHeaderCell>
                        </TableHeader>
                        <tbody>
                          {locationImport.importResult.invalidLocations.slice(0, MAX_LOCATIONS_IN_TABLE).map(su => (
                            <TableRow key={su.id}>
                              <TableCell>{su.lineNumber}</TableCell>
                              <TableCell>{su.referenceId}</TableCell>
                              <TableCell>
                                <ul>
                                  {su.errors.map((error, i) => (
                                    <li key={i}>{error}</li>
                                  ))}
                                </ul>
                              </TableCell>
                            </TableRow>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
              </div>
            )}
          </form>
        </PageContent>
      </div>
    );
  }
}
export default connect(
  state => ({
    contractId: state.contracts.selected.id,
    locationImport: state.locations.locationImport,
  }),
  {
    onFileChange: parseFile,
    onImport: importLocations,
    getExportLink,
  },
  (state, dispatch) => ({
    ...state,
    ...dispatch,
    getExportLink: () =>
      dispatch.getExportLink(state.contractId).then(location => {
        document.location = location;
      }),
  })
)(Import);
