import React, { useState, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { styled } from 'styletron-react';
import { connect } from 'react-redux';
import Notification from '../../../utils/promiseNotification';
import ShipmentTypeToggle from './ShipmentTypeToggle';
import ParcelForm from './ParcelForm';
import shipmentType from '../type';
import UserPicker from '../../../components/UserPicker';
import PersonWithMail from '../../../components/PersonWithMail';
import CustomField from '../../../components/CustomField';
import Icon from '../../../components/Icons/ArrowRight';
import { CheckboxField as Checkbox } from '../../../components/Field';
import StatefulButton from '../../../components/StatefulButton.js';
import SelectPrinter from '../../../components/Print/SelectPrinter.js';
import { getDefaultPrinter, getPrinterStatus, printShipments as print } from '../../../components/Print/printing';
import WithStickerConfiguration from '../../stickerConfiguration/WithStickerConfiguration';
import Add from '../../serviceUsers/Add';
import ReactModal from 'react-modal';
import { searchUserById } from '../../serviceUsers/searchUsers';
import LocationSelector from '../../../components/LocationSelector';

const USER_QUERY = gql`
  query ShipmentFormServiceUser($contractId: Int!, $serviceUserId: Int!) {
    contract(id: $contractId) {
      id
      serviceUser(id: $serviceUserId) {
        id
        letterLocation {
          id
          name
        }
        parcelLocation {
          id
          name
        }
        customFields {
          key
          value
        }
        assignee {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

const buttonAsLink = {
  backgroundColor: 'transparent',
  textDecoration: 'underline',
  border: 'none',
  color: '#E78125',
  cursor: 'pointer',
};

const defaultState = {
  type: shipmentType.letter,
  user: undefined,
  parcelInfo: {
    referenceNumber: '',
    forwarder: '',
    numberOfPackages: 1,
    deviation: false,
    deviationNote: '',
  },
  requirePin: true,
  customFields: {},
  selectedPrinter: undefined,
  printerStatus: undefined,
  isLoadingShipment: false,
  showAddRecipientModal: false,
};

const modalStyle = {
  content: {
    position: 'absolute',
    top: '40%',
    bottom: 'auto',
    left: '50%',
    maxWidth: '500px',
    transform: 'translate(-50%, -50%)',
  },
};

ReactModal.setAppElement('#root');

const ShipmentForm = ({ contract, onAdd, searchUserById, stickerConfiguration }) => {
  const [state, setState] = useState(defaultState);
  const defaultInput = useRef(null);

  const { data, loading, error } = useQuery(USER_QUERY, {
    skip: !state.user,
    variables: {
      contractId: contract && parseInt(contract.id),
      serviceUserId: state.user && parseInt(state.user.id),
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (contract.hasLetters && !contract.hasParcels) {
      onTypeChange(shipmentType.letter);
    }
    if (!contract.hasLetters && contract.hasParcels) {
      onTypeChange(shipmentType.parcel);
    }

    getDefaultPrinter()
      .then((selectedPrinter) => {
        setState((prevState) => ({ ...prevState, selectedPrinter }));
        return selectedPrinter;
      })
      .then((printer) =>
        getPrinterStatus(printer)
          .then((printerStatus) => {
            setState((prevState) => ({ ...prevState, printerStatus }));
          })
          .catch((printerStatus) => setState((prevState) => ({ ...prevState, printerStatus })))
      )
      .catch(() => setState((prevState) => ({ ...prevState, printerStatus: 'No printer(s) found' })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTypeChange = (type) => {
    setState({ ...state, type });
  };

  const onUserChange = (user) => {
    setState({ ...state, user });
  };

  const onAddShipment = (e) => {
    e.preventDefault();
    const { user, type, requirePin, parcelInfo, selectedPrinter } = state;

    setState({ ...state, isLoadingShipment: true });

    const value = {
      ...state,
      parcelInfo: type === shipmentType.parcel ? parcelInfo : {},
      currentLocationId: contract.selectedLocationId,
      deliveryLocationId: type === 0 ? user.letterLocation.id : user.parcelLocation.id,
      requirePin: user.parcelLocation.enablePinCodes && type === shipmentType.parcel && requirePin,
    };

    onAdd(value)
      .then((batch) => {
        setState({ ...state, isLoadingShipment: false });
        if (selectedPrinter) {
          let shipment = batch.items[0];
          return print(selectedPrinter, [shipment], stickerConfiguration);
        }
        return batch;
      })
      .then(() => {
        setState({
          ...defaultState,
          selectedPrinter,
          type: value.type,
          printerStatus: state.printerStatus,
          customFields: Object.keys(state.customFields).reduce(
            (fields, field) => ({
              ...fields,
              [field]: '',
            }),
            {}
          ),
        });
      })
      .catch((error) => {
        setState({ ...state, isLoadingShipment: false });
        Notification.error(error.error);
      });

    defaultInput.current?.focus();
  };

  const onParcelInfoChange = (parcelInfo) => {
    setState({ ...state, parcelInfo });
  };

  const onCustomFieldChange = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      customFields: {
        ...prevState.customFields,
        [field]: value,
      },
    }));
  };

  const onPrinterChange = (selectedPrinter) => {
    setState({ ...state, selectedPrinter });
    getPrinterStatus(selectedPrinter).then((printerStatus) => setState({ ...state, printerStatus }));
  };

  const onCheckboxChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    });
  };

  const showAddRecipientModal = (e) => {
    e.preventDefault();
    setState({ ...state, showAddRecipientModal: true });
  };

  const closeAddRecipientModal = (e) => {
    e.preventDefault();
    setState({ ...state, showAddRecipientModal: false });
  };

  const setServiceUserAsRecipient = (id) => {
    searchUserById(id, contract.id)
      .then((result) => result.json())
      .then((user) => {
        setState({ ...state, showAddRecipientModal: false });
        onUserChange(user);
      });
  };

  const onChangeDeliveryLocation = (location) => {
    const newUser = { ...state.user };
    newUser.letterLocation = location;
    newUser.parcelLocation = location;
    onUserChange(newUser);
  };

  const translateOptionIdToValue = (selectKey, optionId) => {
    const { serviceUserFields } = contract;

    const select = serviceUserFields.find((f) => f.name === selectKey);
    const selectOption = select.options.find((o) => o.id === optionId);
    if (!selectOption) {
      console.error(`Unable to find value for ${optionId} in ${selectKey} options`, 'error');
      return '';
    } else {
      return selectOption.value;
    }
  };

  const customFieldGroup = (customFields) => {
    return customFields
      .filter((field) => !field.disabled)
      .map((field) => (
        <CustomField
          label={field.name}
          type={field.type}
          options={field.options}
          required={field.required}
          key={field.name}
          value={state.customFields[field.name] || ''}
          onChange={(value) => onCustomFieldChange(field.name, value)}
        />
      ));
  };

  const serviceUser = data && data.contract && data.contract.serviceUser;
  const isValid = !!state.user;
  if (error) {
    console.log('ApolloError->USER_QUERY:', error);
  }

  return (
    <div>
      <ReactModal isOpen={state.showAddRecipientModal} style={modalStyle}>
        <Add
          onClose={(e) => closeAddRecipientModal(e)}
          setServiceUserAsRecipient={(id) => setServiceUserAsRecipient(id)}
        />
      </ReactModal>
      <strong>Recipient</strong>
      <button style={buttonAsLink} onClick={(e) => showAddRecipientModal(e)}>
        Add new
      </button>
      <form
        onSubmit={(e) => {
          onAddShipment(e);
          setState(defaultState);
        }}
      >
        <UserPickerWrapper>
          <UserPicker inputRef={defaultInput} onChange={(changedUser) => onUserChange(changedUser)} user={state.user} />
        </UserPickerWrapper>

        {state.user && (
          <p>
            <PersonWithMail {...state.user} bold />
          </p>
        )}

        {state.user && (
          <RecipientInfoList>
            {serviceUser && serviceUser.assignee && (
              <RecipientInfoListItem label="Assignee">
                <PersonWithMail {...serviceUser.assignee} bold />
              </RecipientInfoListItem>
            )}
            <LocationSelector
              label="Delivery location"
              contractId={contract.id}
              allowUnset={false}
              value={state.type === 0 ? state.user.letterLocation.id : state.user.parcelLocation.id}
              onChange={(location) => onChangeDeliveryLocation(location)}
            />

            {serviceUser &&
              serviceUser.customFields &&
              serviceUser.customFields.length > 0 &&
              serviceUser.customFields.map((field) => {
                const customField = contract.serviceUserFields.find((f) => f.name === field.key && !f.disabled);

                if (!customField) return null;
                if (customField.type.toLowerCase() === 'select') {
                  const selectValue = translateOptionIdToValue(field.key, field.value);
                  field.value = selectValue;
                }

                return (
                  <RecipientInfoListItem label={field.key} key={field.key}>
                    {(field.value === 'true' && 'Yes') || (field.value === 'false' && 'No') || field.value}
                  </RecipientInfoListItem>
                );
              })}
          </RecipientInfoList>
        )}

        {contract.hasLetters && contract.hasParcels && (
          <ShipmentTypeToggle onChange={(type) => onTypeChange(type)} value={state.type} />
        )}

        {state.user && state.user.parcelLocation.enablePinCodes && state.type === shipmentType.parcel && (
          <Checkbox
            label="Require PIN"
            name="requirePin"
            checked={state.requirePin}
            onChange={(e) => onCheckboxChange(e)}
          />
        )}

        {state.type === shipmentType.parcel && (
          <ParcelForm onChange={(info) => onParcelInfoChange(info)} value={state.parcelInfo} contractId={contract.id} />
        )}

        {customFieldGroup(contract.consignmentFields)}

        {state.type === shipmentType.letter && customFieldGroup(contract.letterFields)}

        {state.type === shipmentType.parcel && customFieldGroup(contract.parcelFields)}

        <ButtonRow>
          <SelectPrinter
            selectedPrinter={state.selectedPrinter}
            onChange={(printer) => onPrinterChange(printer)}
            printerStatus={state.printerStatus}
          />
          <StatefulButton
            type="submit"
            disabled={!isValid || !serviceUser || !data || loading}
            isLoading={state.isLoadingShipment}
            primary
          >
            <span>
              Register Shipment <Icon />
            </span>
          </StatefulButton>
        </ButtonRow>
      </form>
    </div>
  );
};

const ButtonRow = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px',
});

const UserPickerWrapper = styled('div', {
  marginBottom: '20px',
});

const RecipientInfoList = styled('ul', {
  listStyle: 'none',
  padding: '0',
});

const RecipientInfoListItem = ({ label, children }) => (
  <li>
    {label}: {children}
  </li>
);

const mapDispatchToProps = {
  searchUserById,
};

const ShipmentFormWithStickers = (props) => {
  return (
    <WithStickerConfiguration contractId={props.contract.id}>
      {({ configuration }) => <ShipmentForm {...props} stickerConfiguration={configuration} />}
    </WithStickerConfiguration>
  );
};

export default connect(null, mapDispatchToProps)(ShipmentFormWithStickers);
