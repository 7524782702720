import fetch from '../../utils/fetch';
import moment from 'moment';

export const BATCH_REGISTER = 'BATCH_REGISTER';
export const BATCH_REGISTER_SUCCESS = 'BATCH_REGISTER_SUCCESS';
export const BATCH_FETCH = 'BATCH_FETCH';
export const BATCH_FETCH_SUCCESS = 'BATCH_FETCH_SUCCESS';
export const BATCH_APPEND = 'BATCH_APPEND';
export const BATCH_APPEND_SUCCESS = 'BATCH_APPEND_SUCCESS';

export const registerBatch = (contractId, shipments) => dispatch => {
  dispatch({
    type: BATCH_REGISTER,
  });
  return dispatch(
    fetch('api/consignment/register', {
      method: 'POST',
      body: JSON.stringify({
        contractId,
        items: shipments,
        registeredDate: moment(new Date()).format(),
      }),
    })
  ).then(res => {
    dispatch({
      type: BATCH_REGISTER_SUCCESS,
      batchId: res.id,
      data: res,
    });
    return res;
  });
};

export const appendBatch = (batchId, shipments) => dispatch => {
  dispatch({ type: BATCH_APPEND, batchId });
  return dispatch(
    fetch(`api/consignment/batch/${batchId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        items: shipments,
        registeredDate: moment(new Date()).format(),
      }),
    })
  ).then(res => {
    dispatch({
      type: BATCH_APPEND_SUCCESS,
      batchId,
      data: res,
    });
    return res;
  });
};

export const fetchBatch = id => dispatch => {
  dispatch({
    type: BATCH_FETCH,
    batchId: id,
  });
  return dispatch(fetch(`api/consignment/batch/${id}`)).then(res => {
    dispatch({
      type: BATCH_FETCH_SUCCESS,
      batchId: id,
      data: res,
    });
  });
};
