import React, { Component } from 'react';
import { connect } from 'react-redux';
import { styled } from 'styletron-react';
import style from '../style';
import Page, { PageHeading, PageContent } from './Page';
import { Container } from './Layout';

const ErrorMessage = styled('div', {
  color: style.statusRed,
  background: style.statusRed + '33',
  padding: '20px',
  marginTop: '20px',
});

class ErrorBoundary extends Component {
  state = { error: null };

  componentDidCatch(error, errorInfo) {
    console.error(errorInfo);
    this.setState({ error });
  }
  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <Page>
          <Container>
            <PageContent>
              <PageHeading>Oops! Something wen't wrong...</PageHeading>
              <p>
                We apologize for any inconvenience this causes. If you want to help us solving this issue faster, please
                report feedback with info about what you were trying to do to <a href = "mailto: coor@support.com">coor@support.com</a>.
              </p>
              <ErrorMessage>
                <h4>Error info</h4>
                <p>{this.state.error.message}</p>
              </ErrorMessage>
            </PageContent>

          </Container>
        </Page>
      );
    }
    return this.props.children;
  }
}
export default connect(state => ({
  user: state.oidc.user,
  authentication: state.authentication,
  selectedContractId: (state.contracts.selected && state.contracts.selected.id) || null,
  selectedLocationId: (state.contracts.selected && state.contracts.selected.selectedLocationId) || null,
  batches: (state.shipment && state.shipment.batches) || null,
}))(ErrorBoundary);
