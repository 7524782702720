import React from 'react';
import { styled } from 'styletron-react';

const StyledLink = styled('a', {
  ':focus': {
    outline: '0',
  },
});

const LinkedIcon = ({ icon, title, to, ...props }) => (
  <StyledLink href={to ? to : undefined} title={title} {...props}>
    {icon}
  </StyledLink>
);
export default LinkedIcon;
