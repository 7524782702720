import React from 'react';
import { styled } from 'styletron-react';

import GraphiQL from 'graphiql';
import 'graphiql/graphiql.min.css';

export default class GraphiQLWrapper extends React.Component {
  request = params =>
    fetch(`${process.env.REACT_APP_API_URI}/webgraph`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }).then(res => res.json());

  render() {
    return (
      <FullHeightWrapper>
        <GraphiQL fetcher={this.request} />
      </FullHeightWrapper>
    );
  }
}

const FullHeightWrapper = styled('div', {
  height: '100vh',
});
