import React from 'react';
import { makeButtonStyle } from './Button';
import { styled } from 'styletron-react';
import style from '../style';

const Input = styled('input', {
  position: 'absolute',
  zIndex: -1,
  opacity: 0,
});

const Wrapper = styled('label', {
  marginLeft: '1rem',
  ':firstChild': {
    marginLeft: 0,
  },
});

const FakeToggle = styled('span', ({ $checked, $focused }) => {
  const buttonStyle = makeButtonStyle({ $primary: $checked });
  const focusStyle = buttonStyle[':focus'];
  return {
    ...buttonStyle,
    background: 'rgba(0, 0, 0, 0.1)',
    ...($checked && {
      background: style.primary,
    }),
    ':hover': {
      background: style.primary,
    },
    display: 'inline-block',
    marginLeft: 0,
    ...($focused &&
      focusStyle && {
        background: style.primary,
        boxShadow: `0 0 8px ${style.primary}`,
      }),
  };
});

const ToggleGroupWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: style.inputMaxWidth,
});

export class Toggle extends React.Component {
  state = {
    isFocused: false,
  };
  focusChange(focused) {
    this.setState({ isFocused: focused });
  }
  render() {
    const { children, onChange, checked, ...props } = this.props;
    const { isFocused } = this.state;
    return (
      <Wrapper>
        <Input
          type="radio"
          {...props}
          checked={checked}
          onChange={() => onChange(props.value)}
          onFocus={() => this.focusChange(true)}
          onBlur={() => this.focusChange(false)}
        />
        <FakeToggle $focused={isFocused} $checked={checked}>
          {children}
        </FakeToggle>
      </Wrapper>
    );
  }
}

export const ToggleGroup = ({ value, onChange, children, name }) => (
  <ToggleGroupWrapper>
    {React.Children.map(children, child =>
      React.cloneElement(child, {
        onChange,
        checked: child.props.value === value,
        name,
      })
    )}
  </ToggleGroupWrapper>
);
