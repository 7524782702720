import React from 'react';

const CustomersFilled = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>customersFilled</title>
    <g id="Canvas" transform="translate(-327 -4446)">
      <g id="orgFilled">
        <g id="Fill 732">
          <use
            xlinkHref="#customersFilled-path0_fill"
            transform="translate(327 4461.44)"
            fill={props.fill || '#5C5C5C'}
          />
        </g>
        <g id="Fill 733">
          <use
            xlinkHref="#customersFilled-path1_fill"
            transform="translate(342.918 4461.44)"
            fill={props.fill || '#5C5C5C'}
          />
        </g>
        <g id="Fill 734">
          <use
            xlinkHref="#customersFilled-path2_fill"
            transform="translate(330.586 4446)"
            fill={props.fill || '#5C5C5C'}
          />
        </g>
        <g id="Fill 735">
          <use
            xlinkHref="#customersFilled-path3_fill"
            transform="translate(334.542 4461.44)"
            fill={props.fill || '#5C5C5C'}
          />
        </g>
      </g>
    </g>
    <defs>
      <path
        id="customersFilled-path0_fill"
        fillRule="evenodd"
        d="M 6.542 8.044C 6.542 7.139 6.801 6.301 7.22 5.565C 6.816 5.053 6.298 4.635 5.695 4.363C 6.212 3.905 6.545 3.243 6.545 2.5C 6.545 1.122 5.423 0 4.045 0C 2.666 0 1.545 1.122 1.545 2.5C 1.545 3.242 1.877 3.903 2.392 4.361C 0.985 4.995 0 6.403 0 8.044C 0 8.32 0.224 8.544 0.5 8.544L 6.542 8.544L 6.542 8.044Z"
      />
      <path
        id="customersFilled-path1_fill"
        fillRule="evenodd"
        d="M 7.169 7.895C 7.111 6.319 6.142 4.978 4.777 4.364C 5.294 3.905 5.627 3.244 5.627 2.5C 5.627 1.122 4.505 0 3.127 0C 1.749 0 0.627 1.122 0.627 2.5C 0.627 3.243 0.959 3.904 1.475 4.362C 0.897 4.622 0.396 5.018 0 5.502C 0.442 6.251 0.713 7.113 0.713 8.044L 0.713 8.544L 6.672 8.544L 6.692 8.544C 6.968 8.544 7.192 8.321 7.192 8.044C 7.192 7.992 7.184 7.943 7.169 7.895Z"
      />
      <path
        id="customersFilled-path2_fill"
        fillRule="evenodd"
        d="M 15.5 11.984L 8.5 11.984L 8.5 10.984L 12.439 10.984L 12.459 10.984C 12.734 10.984 12.959 10.76 12.959 10.484C 12.959 10.433 12.951 10.383 12.937 10.336C 12.878 8.35 11.64 6.655 9.9 5.927C 10.729 5.333 11.274 4.368 11.274 3.273C 11.274 1.468 9.806 0 8 0C 6.195 0 4.727 1.468 4.727 3.273C 4.727 4.368 5.272 5.334 6.101 5.928C 4.318 6.673 3.062 8.434 3.062 10.484C 3.062 10.76 3.286 10.984 3.562 10.984L 7.5 10.984L 7.5 11.984L 0.5 11.984C 0.224 11.984 0 12.208 0 12.484L 0 13.463C 0 13.74 0.224 13.963 0.5 13.963C 0.776 13.963 1 13.74 1 13.463L 1 12.984L 7.5 12.984L 7.5 13.463C 7.5 13.74 7.724 13.963 8 13.963C 8.276 13.963 8.5 13.74 8.5 13.463L 8.5 12.984L 15 12.984L 15 13.463C 15 13.74 15.224 13.963 15.5 13.963C 15.776 13.963 16 13.74 16 13.463L 16 12.484C 16 12.208 15.776 11.984 15.5 11.984Z"
      />
      <path
        id="customersFilled-path3_fill"
        fillRule="evenodd"
        d="M 8.086 7.895C 8.028 6.319 7.059 4.978 5.694 4.364C 6.211 3.905 6.544 3.244 6.544 2.5C 6.544 1.122 5.422 0 4.044 0C 2.666 0 1.544 1.122 1.544 2.5C 1.544 3.244 1.876 3.904 2.392 4.362C 0.985 4.996 0 6.404 0 8.044C 0 8.321 0.224 8.544 0.5 8.544L 7.589 8.544L 7.609 8.544C 7.885 8.544 8.109 8.321 8.109 8.044C 8.109 7.992 8.101 7.943 8.086 7.895Z"
      />
    </defs>
  </svg>
);

export default CustomersFilled;
