import React, { Component } from 'react';
import { styled } from 'styletron-react';

import Overlay from '../../../components/Overlay';
import Modal from '../../../components/Modal';
import Identity from '../Identity';
import Button from '../../../components/Button';

const ActionsBase = styled('div', {
  marginBottom: '1rem',
});

const DEFAULT_STATE = {
  identity: '',
  displayIdentity: false,
};

class Actions extends Component {
  state = DEFAULT_STATE;

  deliver = () => {
    const { deliver } = this.props;
    deliver(this.state.identity).then(() => this.setState(DEFAULT_STATE));
  };

  toggleIdentityModal = () =>
    this.setState(prevState => ({
      displayIdentity: !prevState.displayIdentity,
    }));
  hideIdentityModal = () => this.setState({ displayIdentity: false });

  render() {
    const { shipments, sendReminders } = this.props;
    const { displayIdentity } = this.state;

    const shipmentsWithPin = shipments.filter(s => s.requirePin);
    const deliveredShipments = shipments.filter(s => s.status === 'Delivered');
    const deliverableShipments = shipments.filter(
      s => s.status === 'Registered'
    );
    const identityRequired = deliverableShipments.some(
      s => s.deliveryLocation.requiresIdentityVerification
    );

    return (
      <ActionsBase>
        <div>
          <Button onClick={sendReminders}>Send reminders</Button>
          <Button
            onClick={
              identityRequired
                ? () => this.setState({ displayIdentity: true })
                : this.deliver
            }
            disabled={
              shipmentsWithPin.length > 0 || deliverableShipments.length === 0
            }
          >
            Deliver
          </Button>
        </div>
        {shipmentsWithPin.length > 0 && (
          <div>
            <i>
              It's not possible to deliver these at once, the following
              shipments needs to be delivered from their respective details view
              as they require a pin code:{' '}
              {shipmentsWithPin.map(s => s.consignmentNumber).join()}.
            </i>
          </div>
        )}
        {deliveredShipments.length > 0 &&
          deliverableShipments.length > 0 && (
            <div>
              <i>
                Some of the selected shipments are already delivered but you may
                still deliver the following shipments:{' '}
                {deliverableShipments.map(s => s.consignmentNumber).join()}.
              </i>
            </div>
          )}
        <Overlay
          lightbox
          isActive={displayIdentity}
          toggle={this.toggleIdentityModal}
        >
          {displayIdentity && (
            <Modal>
              <Identity
                identity={this.state.identity}
                onChange={identity => this.setState({ identity })}
                submit={this.deliver}
                close={this.hideIdentityModal}
              />
            </Modal>
          )}
        </Overlay>
      </ActionsBase>
    );
  }
}
export default Actions;
