import React from 'react';
import { styled } from 'styletron-react';
import style from '../style';
import ChevronDown from './Icons/ChevronDown.svg';
import updateSvgProps from '../utils/updateSvgProps';

let chevronDownPrimary = updateSvgProps(ChevronDown, 'fill', style.primaryDarker);

const chevronPosition = 0.5;
const chevronSize = 1.75;

export const Select = styled('select', {
  width: '100%',
  maxWidth: style.inputMaxWidth,
  padding: style.basePadding,
  borderRadius: 'none',
  background: `url(${chevronDownPrimary}) no-repeat`,
  backgroundPosition: `right ${chevronPosition}rem center`,
  paddingRight: `${chevronPosition * 2 + chevronSize}rem`,
  backgroundSize: `auto ${chevronSize}rem`,
  ':disabled': {
    border: 'none',
    background: 'none',
  },
});

const FloatingSelectInput = styled('select', {
  borderColor: 'transparent',
  width: '100%',
  padding: '1.25rem 0.5rem 0.5rem 0.5rem',
  background: 'transparent',
  fontSize: '16px', // iPhone will zoom in if fontsize is less than 16px
});

const FloatingSelectWrapper = styled('label', {
  position: 'relative',
  flexGrow: 1,
  flexBasis: 0,
  margin: 0,
  background: 'white',
});

const FloatingSelectTitle = styled('span', {
  position: 'absolute',
  top: '0.25rem',
  fontSize: '0.8em',
  left: '0.5rem',
  color: '#888',
});

export const FloatingSelect = ({ label, children, ...selectProps }) => (
  <FloatingSelectWrapper>
    <FloatingSelectTitle>{label}</FloatingSelectTitle>
    <FloatingSelectInput {...selectProps}>{children}</FloatingSelectInput>
  </FloatingSelectWrapper>
);
