export const viewports = {
  sm: 544,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
};

const responsiveFactory = width => style => ({
  [`@media (min-width: ${width}px)`]: style,
});

export default {
  viewports,
  ...Object.keys(viewports).reduce(
    (acc, key) => ({
      ...acc,
      [key]: responsiveFactory(viewports[key]),
    }),
    {}
  ),
};
