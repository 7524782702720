import React from 'react';

import Button from '../../components/Button';
import { Input } from '../../components/Input';

const Identity = ({ identity, onChange, close, submit }) => (
  <div>
    <i>
      Some of these packages must be signed for, please input the
      name/identification number of the person picking them up.
    </i>
    <Input value={identity} onChange={e => onChange(e.target.value)} />
    <Button style={{ marginLeft: 0, marginTop: '1rem' }} onClick={close}>
      Cancel
    </Button>
    <Button disabled={!identity} primary onClick={submit}>
      Deliver
    </Button>
  </div>
);

export default Identity;
