import { state as asyncState } from '../../utils/fetch';

import {
  BATCH_REGISTER,
  BATCH_REGISTER_SUCCESS,
  BATCH_FETCH,
  BATCH_FETCH_SUCCESS,
  BATCH_APPEND,
  BATCH_APPEND_SUCCESS,
} from '../batch/action-creators';
import {
  SHIPMENT_LOAD,
  SHIPMENT_LOAD_SUCCESS,
  SHIPMENTS_FETCHED,
  SHIPMENT_DELIVER,
  SHIPMENT_DELIVER_SUCCESS,
  SHIPMENT_REMOVE,
  SHIPMENT_REMOVE_SUCCESS,
  SHIPMENT_DELIVER_FAILURE,
  SHIPMENT_CURRENT_LOCATION,
  SHIPMENTS_DELIVER_SUCCESS,
  // COUNT_DEVIATION_IMAGES,
  // ADD_DEVIATION_IMAGES,
  // REMOVE_DEVIATION_IMAGES 
} from './action-creators';

const defaultState = {
  state: asyncState.done,
  items: {},
  batches: {},
};

const withUpdatedShipment = (state, shipmentId, diff) => {
  const shipment = {
    id: shipmentId,
    ...state.items[shipmentId],
    ...diff,
  };
  const batch =
    shipment.consignmentBatchId && state.batches[shipment.consignmentBatchId];
  return {
    ...state,
    items: {
      ...state.items,
      [shipmentId]: shipment,
    },
    ...(batch && {
      batches: {
        ...state.batches,
        [batch.id]: {
          ...batch,
          items: batch.items.filter(c => c.id === shipmentId).length
            ? batch.items.map(c => (c.id === shipmentId ? shipment : c))
            : batch.items.concat([shipment]),
        },
      },
    }),
  };
};

const withUpdatedShipments = (state, shipments = []) => ({
  ...state,
  items: shipments.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: {
        state: asyncState.notTriggered,
        ...acc[item.id],
        ...item,
      },
    }),
    state.items
  ),
});

const withUpdatedBatch = (batches, batchId, diff) => ({
  ...batches,
  [batchId]: {
    id: batchId,
    items: [],
    ...batches[batchId],
    ...diff,
  },
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case BATCH_REGISTER:
      return { ...state, state: asyncState.pushing };
    case BATCH_APPEND:
      return {
        ...state,
        batches: withUpdatedBatch(state.batches, action.batchId, {
          state: asyncState.pushing,
        }),
      };
    case BATCH_FETCH:
      return {
        ...state,
        batches: withUpdatedBatch(state.batches, action.batchId, {
          state: asyncState.fetching,
        }),
      };
    case BATCH_REGISTER_SUCCESS:
    case BATCH_APPEND_SUCCESS:
    case BATCH_FETCH_SUCCESS:
      return {
        ...state,
        ...withUpdatedShipments(state, action.data.items),
        state: asyncState.done,
        batches: withUpdatedBatch(state.batches, action.batchId, {
          ...action.data,
          state: asyncState.done,
        }),
      };
    case SHIPMENT_LOAD:
      return withUpdatedShipment(state, action.shipmentId, {
        state: asyncState.fetching,
      });
    case SHIPMENT_LOAD_SUCCESS:
    case SHIPMENT_REMOVE_SUCCESS:
      return withUpdatedShipment(state, action.shipmentId, {
        state: asyncState.done,
        hasLoadedOnce: true,
        ...action.data,
      });
    case SHIPMENT_DELIVER:
      return withUpdatedShipment(state, action.shipmentId, {
        state: asyncState.pushing,
      });
    case SHIPMENT_REMOVE:
      return withUpdatedShipment(state, action.shipmentId, {
        state: asyncState.deleting,
      });
    case SHIPMENT_DELIVER_SUCCESS:
      return withUpdatedShipment(state, action.shipmentId, {
        state: asyncState.notTriggered,
      });
    case SHIPMENT_DELIVER_FAILURE:
      return withUpdatedShipment(state, action.shipmentId, {
        state: asyncState.exception,
        errors: action.errors,
      });
    case SHIPMENTS_FETCHED:
      return withUpdatedShipments(state, action.shipments);
    case SHIPMENT_CURRENT_LOCATION:
      return withUpdatedShipment(state, action.shipmentId, {
        currentLocation: action.location,
        state: asyncState.notTriggered,
      });
    case SHIPMENTS_DELIVER_SUCCESS:
      return withUpdatedShipments(
        state,
        action.consignments.map(consignment => ({
          ...consignment,
          status: 'Delivered',
        }))
      );
    default:
      return state;
  }
};
