export const build = (...parts) => parts.join('/').replace(/\/+/g, '/'); // replace consecutive slashes with a single slash

export const toQuery = (params = {}) =>
  Object.keys(params)
    .filter(k => k && params[k])
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

export const fromQuery = (query = '') =>
  query
    .replace('?', '')
    .split('&')
    .map(pair => pair.split('='))
    .filter(pair => pair[0])
    .reduce(
      (acc, curr) => ({
        ...acc,
        [decodeURIComponent(curr[0].toLowerCase())]: decodeURIComponent(
          curr[1]
        ),
      }),
      {}
    );

export const parseLinks = (link = '') => {
  if (!link) return null;
  return link
    .split(',')
    .map(pair => pair.split(';'))
    .reduce(
      (acc, pair) =>
        pair && pair.length > 1
          ? {
            ...acc,
            [pair[1]
              .replace('rel="', '')
              .replace('"', '')
              .replace(' ', '')]: pair[0].replace('<', '').replace('>', ''),
          }
          : acc,
      {}
    );
};

export const getQueryPart = (uri = '') => uri.substring(uri.indexOf('?'));
