import React from 'react';
import { Input } from '../../../../components/Input';
import { Select } from '../../../../components/Select';
import { Row, Col } from '../../../../components/Layout';
import CustomFieldOptions from './CustomFieldOptions';

const CustomField = ({ onChange, onRemove, isDisabled, ...item }) => (
  <li>
    <Row>
      <Col>
        <Input
          value={item.name}
          disabled={isDisabled || item.active}
          type="text"
          onChange={e => onChange({ ...item, name: e.target.value })}
        />
      </Col>
      <Col>
        <Select
          value={item.type}
          disabled={isDisabled || item.active}
          onChange={e => onChange({ ...item, type: e.target.value })}
        >
          <option value="String">String</option>
          <option value="Integer">Integer</option>
          <option value="Decimal">Decimal</option>
          <option value="Boolean">Yes/No</option>
          <option value="Select">Select list</option>
        </Select>
      </Col>
      <Col>
        <label style={{ lineHeight: 2.5 }}>
          <input
            type="checkbox"
            disabled={isDisabled}
            checked={item.required || false}
            onChange={() => onChange({ ...item, required: !item.required })}
          />{' '}
          Required
        </label>
      </Col>
      <Col>
        <label style={{ lineHeight: 2.5 }}>
          <input
            type="checkbox"
            checked={item.disabled || false}
            onChange={() => onChange({ ...item, disabled: !item.disabled })}
          />{' '}
          Disabled
        </label>
      </Col>
    </Row>
    {item.type === 'Select' && <CustomFieldOptions item={item} disabled={isDisabled} onChange={onChange} />}
  </li>
);

export default CustomField;
