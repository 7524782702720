import React, { Fragment, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import GraphQLProvider from '../../../components/GraphQLProvider';
import { gql } from '@apollo/client';
import Form from '../Form';
import Notification from '../../../utils/promiseNotification';
import { PageHeader, PageActionButton, PageHeading, PageContent } from '../../../components/Page';
import Button from '../../../components/Button';
import PrintButton from '../../../components/Print/PrintButton';
import { printLocations } from '../../../components/Print/printing';

const LOCATION_QUERY = gql`
  query LocationForm($contractId: Int!, $locationId: Int!) {
    contract(id: $contractId) {
      id
      location(id: $locationId) {
        id
        name
        description
        referenceId
        parentLocation {
          id
          referenceId
        }
        contract {
          id
          name
        }
        enablePinCodes
        requiresIdentityVerification
        isRemovable
        status
      }
    }
  }
`;

const DELETE_LOCATION_MUTATION = gql`
  mutation LocationRemove($input: LocationRemoveInputType!) {
    locationRemove(input: $input) {
      id
      name
      description
      referenceId
      parentLocation {
        id
        referenceId
      }
      contract {
        id
        name
      }
      enablePinCodes
      requiresIdentityVerification
      isRemovable
      status
    }
  }
`;

const Actions = ({ location, disabled, history }) => {
  const [deleteEnabled, setDeleteEnabled] = useState(false);

  const [deleteLocation] = useMutation(DELETE_LOCATION_MUTATION);

  const toggleEnableDelete = () => setDeleteEnabled((prevState) => !prevState);

  const handleDelete = () => {
    deleteLocation({
      variables: { input: { id: location.id } },
      fetchPolicy: 'network-only',
    })
      .then((res) => {
        Notification.success('Location deleted successfully')(res);
        history.push('/locations');
      })
      .catch((error) => {
        return Notification.error('Error: Deleting location failed')(error);
      });
  };

  return (
    <Fragment>
      {location.isRemovable && (
        <label>
          Check to enable delete button <input type="checkbox" checked={deleteEnabled} onChange={toggleEnableDelete} />
        </label>
      )}
      {location.status !== 'InActive' && !location.isRemovable && (
        <span style={{ maxWidth: '30%' }}>
          It's not possible to remove this location as there are users connected to it or it has active child locations.
        </span>
      )}

      <Button disabled={disabled || !deleteEnabled || !location.isRemovable} onClick={handleDelete} danger>
        Delete
      </Button>
    </Fragment>
  );
};

const EditLocation = ({ contractId, disabled, match, updateLocation, history }) => {
  const locationId = parseInt(match.params.locationId);

  const { loading, data, refetch } = useQuery(LOCATION_QUERY, {
    variables: { contractId: parseInt(contractId), locationId },
  });

  const location = data?.contract?.location;

  const handleSave = (location, refetch) => {
    return updateLocation(location)
      .then((res) => {
        refetch();
        return res;
      })
      .then(Notification.success('Location saved'))
      .catch(Notification.error('Error: Saving location failed'));
  };

  return (
    <GraphQLProvider>
      <div>
        <PageHeader>
          <PageHeading>Edit Location</PageHeading>
          {location && (
            <PageActionButton $as={PrintButton} onPrint={(printer) => printLocations(printer, [location])}>
              Print QR-Code for location
            </PageActionButton>
          )}
        </PageHeader>
        <PageContent>
          <Form
            location={location}
            save={(location) => handleSave(location, refetch)}
            disabled={disabled || loading || !location || location.status === 'InActive'}
            currentContractId={contractId}
            disableRefId={true}
            actions={location ? <Actions location={location} disabled={disabled} history={history} /> : null}
          />
        </PageContent>
      </div>
    </GraphQLProvider>
  );
};

export default EditLocation;
