import React from 'react';
import { styled } from 'styletron-react';
import style from '../style';

const Overlay = styled('div', ({ $lightbox }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  ...($lightbox && {
    background: style.darkDarker,
    opacity: 0.8,
  }),
}));

const Wrapper = styled('div', ({ $isActive }) => ({
  position: 'relative',
  ...($isActive && {
    zIndex: 1,
  }),
}));

export default ({ children, isActive, toggle = undefined, lightbox = false }) => (
  <Wrapper $isActive={isActive}>
    {isActive && <Overlay onClick={toggle} $lightbox={lightbox} />}
    {children}
  </Wrapper>
);
