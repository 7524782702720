import React from 'react';
import { styled, withStyle } from 'styletron-react';
import style from '../../style';
import { ListItem } from '../List';

const UserItem = ({ user, active, onSelected }) => (
  <UserItemBase onClick={onSelected} $active={active}>
    <UserContentCell>
      {user.firstName} {user.lastName} ({user.email})
    </UserContentCell>
    <UserMeta>
      <UserMetaCell>
        <UserMetaHeader>Letter location</UserMetaHeader>
        <UserMetaContent>{user.letterLocation.name}</UserMetaContent>
      </UserMetaCell>
      <UserMetaCell>
        <UserMetaHeader>Parcel location</UserMetaHeader>
        <UserMetaContent>{user.parcelLocation && user.parcelLocation.name}</UserMetaContent>
      </UserMetaCell>
      {Object.keys(user.customFields || {}).map(customFieldKey => (
        <UserMetaCell key={customFieldKey}>
          <UserMetaHeader>{customFieldKey}</UserMetaHeader>
          <UserMetaContent>{user.customFields[customFieldKey] || <NoContent>No value</NoContent>}</UserMetaContent>
        </UserMetaCell>
      ))}
    </UserMeta>
  </UserItemBase>
);

export default UserItem;

const doublePadding = `${style.basePaddingVertical / 2}px ${style.basePaddingHorizontal / 2}px`;

const UserItemBase = withStyle(ListItem, ({ $active }) => ({
  padding: doublePadding,
  borderWidth: style.focusBorderWidth,
  cursor: 'pointer',
  ':hover': {
    color: 'white',
    backgroundColor: style.primary,
  },
  ...($active && {
    boxShadow: style.baseShadow,
    border: style.focusBorderRed,
    ':firstChild': {
      border: style.focusBorderRed,
    },
  }),
}));

const UserContentCell = styled('div', {
  padding: doublePadding,
});

const UserMeta = styled('dl', {
  display: 'flex',
  flexWrap: 'wrap',
  margin: 0,
});

const UserMetaCell = withStyle(UserContentCell, {
  fontSize: '0.8em',
  flexGrow: 1,
  lineHeight: '1.2',
  minWidth: '200px',
  maxWidth: '300px',
});

const UserMetaHeader = ({ children }) => <dt>{children}</dt>;

const UserMetaContent = styled('dd', { margin: 0 });

const NoContent = styled('i', { padding: 0 });
