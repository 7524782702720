import { state as asyncState } from '../../utils/fetch';
import {
  STICKER_CONFIGURATION_LOAD,
  STICKER_CONFIGURATION_LOAD_SUCCESS,
  STICKER_CONFIGURATION_EDIT,
  STICKER_CONFIGURATION_EDIT_SUCCESS,
} from './action-creators';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case STICKER_CONFIGURATION_LOAD:
    case STICKER_CONFIGURATION_EDIT:
      return {
        ...state,
        [action.contractId]: {
          state: asyncState.fetching,
          loaded: false,
        },
      };
    case STICKER_CONFIGURATION_LOAD_SUCCESS:
      return {
        ...state,
        [action.contractId]: {
          state: asyncState.done,
          loaded: true,
          ...action.data,
        },
      };
    case STICKER_CONFIGURATION_EDIT_SUCCESS:
      return {
        ...state,
        [action.contractId]: {
          ...state[action.contractId],
          state: asyncState.notTriggered,
          loaded: false,
        },
      };
    default:
      return state;
  }
};
