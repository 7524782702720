import React from 'react';
import PropTypes from 'prop-types';
import { styled, withStyle } from 'styletron-react';
import style from '../style';
import List, { ListItem } from './List';
import Field from './Field';

export default class AutoSuggestionField extends React.Component {
  static propTypes = {
    suggestions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
  };

  state = {
    selectedIndex: -1,
    active: true,
    visibleSuggestions: [],
  };

  componentDidUpdate(prevProps) {
    const { suggestions, value } = this.props;

    if (prevProps.value === value) return;

    if (suggestions && suggestions.length) {
      this.setState({
        visibleSuggestions: !value ? [] : suggestions.filter(f => f.toLowerCase().indexOf(value.toLowerCase()) === 0),
      });
    }
  }

  setActive(active) {
    this.setState({ active });
  }
  onSelected(suggestion) {
    this.setState({
      selectedIndex: -1,
      active: false,
      visibleSuggestions: [],
    });
    this.props.onChange(suggestion);
  }

  handleKeyDown = e => {
    const { selectedIndex, visibleSuggestions } = this.state;
    if (!visibleSuggestions.length) {
      return;
    }
    switch (e.key) {
      case 'Escape':
        this.setActive(false);
        return;
      case 'ArrowDown':
        this.setState(prevState => ({
          selectedIndex: Math.min(visibleSuggestions.length - 1, prevState.selectedIndex + 1),
        }));
        return;
      case 'ArrowUp':
        this.setState(prevState => ({
          selectedIndex: Math.max(0, prevState.selectedIndex - 1),
        }));
        return;
      case 'Enter':
        if (selectedIndex >= 0) {
          this.onSelected(visibleSuggestions[selectedIndex]);
          e.preventDefault();
        }
        return;
      default:
        this.setActive(true);
        break;
    }
  };
  render() {
    const { value, onChange, label, placeholder } = this.props;
    const { visibleSuggestions, active, selectedIndex } = this.state;

    return (
      <AutosuggestWrapper>
        <Field
          type="text"
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e.target.value)}
          onKeyDown={this.handleKeyDown}
          onFocus={() => this.setActive(true)}
          onBlur={() => this.setActive(false)}
        />
        {active && visibleSuggestions.length > 0 && (
          <AutosuggestList>
            {visibleSuggestions.map((f, i) => (
              <AutosuggestListItem key={f} onMouseDown={() => this.onSelected(f)} $active={selectedIndex === i}>
                {f}
              </AutosuggestListItem>
            ))}
          </AutosuggestList>
        )}
      </AutosuggestWrapper>
    );
  }
}

const AutosuggestWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const AutosuggestList = withStyle(List, {
  width: 'auto',
  background: 'white',
  border: style.focusBorderRed,
  borderRadius: 'none',
  marginTop: '-20px',
});
const AutosuggestListItem = withStyle(ListItem, ({ $active }) => ({
  borderWidth: style.focusBorderWidth,
  cursor: 'pointer',
  backgroundColor: $active ? style.primary : null,
  color: $active ? 'white' : style.textColor,
  ':hover': {
    backgroundColor: style.primary,
    color: 'white',
  },
}));
