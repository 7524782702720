export const baseBorderWidth = '1px';
export const focusBorderWidth = '2px';

export const baseBorder = `${baseBorderWidth} solid #9E9E9E`;
export const focusBorderRed = `${focusBorderWidth} solid #E78125`;

export const baseShadow = '1px 1px 2px 0 rgba(165,165,165,0.50)';
export const baseInputShadow = 'inset 0 1px 3px 0 rgba(129,129,129,0.50)';

export const inputMaxWidth = '500px';
