import React, { Fragment } from 'react';
import Field, { CheckboxField, TextAreaField as TextArea } from '../../../components/Field';
import AutoSuggestionField from '../../../components/AutoSuggestionField';
import { useQuery, gql } from '@apollo/client';

const FORWARDER_QUERY = gql`
  query Forwarder($contractId: Int!) {
    contract(id: $contractId) {
      id
      forwarders
    }
  }
`;

const ParcelForm = ({ onChange, value, contractId }) => {
  const { loading, error, data } = useQuery(FORWARDER_QUERY, {
    variables: { contractId: parseInt(contractId) },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return (
    <Fragment>
      <Field
        label="Reference number"
        type="text"
        value={value.referenceNumber || ''}
        onChange={(e) => onChange({ ...value, referenceNumber: e.target.value })}
        placeholder="Reference"
      />

      <Field
        label="Number of packages"
        type="Integer"
        value={value.numberOfPackages || ''}
        onChange={(e) => onChange({ ...value, numberOfPackages: e.target.value })}
        min="1"
        placeholder="Number of packages"
      />

      <CheckboxField
        label="Deviation"
        type="Boolean"
        value={value.deviation || false}
        onChange={(e) => onChange({ ...value, deviation: e.target.checked })}
        placeholder="Deviation"
      />
      {value.deviation && (
        <>
          <i>* Do not forget to notify deviation on the consignment note</i>
          <TextArea
            label="Cause of deviation"
            type="text"
            value={value.deviationNote || ''}
            onChange={(e) => onChange({ ...value, deviationNote: e.target.value })}
            placeholder="What happened?"
          />
        </>
      )}

      <AutoSuggestionField
        label="Forwarder"
        placeholder="Forwarder"
        value={value.forwarder || ''}
        onChange={(forwarder) => onChange({ ...value, forwarder })}
        suggestions={(data && data.contract && data.contract.forwarders) || []}
      />
    </Fragment>
  );
};

export default ParcelForm;
