import { useContext, useState } from 'react';
import AuthenticationContext from '../authentication/AuthenticationContext';

export const state = {
  notTriggered: 'NOT_TRIGGERED',
  fetching: 'FETCHING',
  pushing: 'PUSHING',
  deleting: 'DELETING',
  exception: 'EXCEPTION',
  done: 'DONE',
};

export const pathBuilder = (...paths) =>
  paths
    .reduce((path, segment) => path.concat((segment + '').split('/')), [])
    .join('/')
    .replace('//', '/');

const getApiUri = () => process.env.REACT_APP_API_URI || 'https://coor-smartflow2-api.azurewebsites.net';

const rawWithToken = (input, init = {}, token) => {
  const apiUri = getApiUri();
  const uri = `${apiUri}/${input}`;
  const method = init.method || 'GET';

  init = {
    ...init,
    headers: {
      ...(['POST', 'PUT', 'PATCH'].indexOf(method) >= 0 && {
        'Content-Type': 'application/json;charset=utf-8',
      }),
      ...init.headers,
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(uri, init).then(res => {
    if (res.Error) {
      throw res.Error;
    }

    if (res.status.toString().startsWith(4)) {
      if (res.headers.get('Content-Type') && res.headers.get('Content-Type').indexOf('application/json') >= 0) {
        return res.json().then(data =>
          Promise.reject({
            error: res.statusText,
            data,
          })
        );
      } else return Promise.reject();
    }
    return res;
  });
};

export const raw = (input, init = {}) => (_, getState) => rawWithToken(input, init, getState().oidc.user.access_token);

export const useAuthenticatedFetch = (input, init) => {
  const user = useContext(AuthenticationContext);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState();

  const perform = () => {
    setIsLoading(true);
    return rawWithToken(input, init, user.access_token)
      .then(res => (res.headers.get('Content-Type') || '').indexOf('application/json') >= 0 && res.json())
      .then(res => {
        setData(res);
        setErrors(undefined);
        setIsLoading(false);
      })
      .catch(errors => {
        setErrors(errors);
        setIsLoading(false);
      });
  };

  return [{data, isLoading, errors}, perform];
};

export default (input, init) => (dispatch, getState) =>
  raw(input, init)(dispatch, getState).then(
    res => (res.headers.get('Content-Type') || '').indexOf('application/json') >= 0 && res.json()
  );
