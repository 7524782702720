export const removeLeadingSpaces = (text) => {
  if (!text) return;
  // Split the text into lines
  const lines = text.split('\n');

  // Remove leading spaces from each line
  const trimmedLines = lines.map((line) => line.trimLeft());

  // Join the lines back together
  const result = trimmedLines.join('\n');

  return result;
};
