import React from 'react';
import { styled } from 'styletron-react';
import style from '../../style';

const inputStyles = ({ $invalid = false }) => ({
  appearance: 'none',
  width: '100%',
  border: $invalid ? style.focusBorderRed : style.baseBorder,
  padding: style.basePadding,
  fontSize: 'inherit',
  boxShadow: style.baseInputShadow,
  ':focus': {
    border: style.focusBorderRed,
    padding: `${style.basePaddingVertical - 1}px ${style.basePaddingHorizontal - 1}px`,
    outline: 'none',
  },
  ':disabled': {
    borderColor: style.light,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    cursor: 'not-allowed',
  },
});

const InputWrapper = styled('div', {
  position: 'relative',
  fontSize: '14px',
  lineHeight: '18px',
  maxWidth: style.inputMaxWidth,
});
const StyledInput = styled('input', inputStyles);

export const TextArea = styled('textarea', props => ({
  ...inputStyles(props),
  minHeight: '6rem',
}));

// TODO: This doesn't scale well, neither the code nor the physical icon
const IconWrapper = styled('div', {
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  height: '18px',
  width: 'auto',
  margin: style.basePadding,
});

export const Input = ({ icon, $invalid = false, ...inputProps }) => (
  <InputWrapper>
    <StyledInput $invalid={$invalid} {...inputProps} />
    {icon && <IconWrapper>{icon}</IconWrapper>}
  </InputWrapper>
);
