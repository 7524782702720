import React from 'react';
import { styled } from 'styletron-react';
import style from '../style';

const Base = styled('div', {
  position: 'fixed',
  top: '50%',
  left: '50%',
  maxWidth: '100vw',
  maxHeight: '100vh',
  overflow: 'auto',
  padding: style.basePadding,
  background: 'white',
  transform: 'translate(-50%,-50%)',
});

export default ({ children }) => <Base>{children}</Base>;
