import React from 'react';
import { styled } from 'styletron-react';
import style from '../../style';

export const statisticTypes = {
  0: {
    title: 'Registered',
    color: style.primary,
  },
  1: {
    title: 'Delivered',
    color: style.statusGreen,
  },
};

const StatisticTypeSpan = styled('span', ({ $color }) => ({
  color: $color,
}));

const StatisticType = ({ no }) => (
  <StatisticTypeSpan $color={statisticTypes[no].color}>{statisticTypes[no].title}</StatisticTypeSpan>
);

export default StatisticType;
