import React from 'react';

const RecipientsFilled = props => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>recipientsFilled</title>
    <g id="recipientsFilled-Canvas" transform="translate(-846 -4035)">
      <g id="recipientsFilled">
        <g id="Fill 3268">
          <use
            xlinkHref="#recipientsFilled-path0_fill"
            transform="translate(866 4045)"
            fill={props.fill || '#5C5C5C'}
          />
        </g>
        <g id="Fill 3269">
          <use
            xlinkHref="#recipientsFilled-path1_fill"
            transform="translate(848.999 4035)"
            fill={props.fill || '#5C5C5C'}
          />
        </g>
        <g id="Fill 3270">
          <use
            xlinkHref="#recipientsFilled-path2_fill"
            transform="translate(846 4046)"
            fill={props.fill || '#5C5C5C'}
          />
        </g>
      </g>
    </g>
    <defs>
      <path
        id="recipientsFilled-path0_fill"
        fillRule="evenodd"
        d="M 3.5 0L 0.5 0C 0.224 0 0 0.224 0 0.5L 0 8.5C 0 8.776 0.224 9 0.5 9L 3.5 9C 3.776 9 4 8.776 4 8.5L 4 0.5C 4 0.224 3.776 0 3.5 0"
      />
      <path
        id="recipientsFilled-path1_fill"
        fillRule="evenodd"
        d="M 3.687 12.998C 4.089 12.388 4.736 12 5.5 12L 8.669 12C 9.669 11.281 11.281 10 13.5 10L 15 10L 15 0.5C 15 0.224 14.777 0 14.5 0L 10 0L 10 8.5L 7.5 6.042L 5 8.5L 5 0L 0.5 0C 0.224 0 0 0.224 0 0.5L 0 11.96C 0.842 12.029 1.196 12.21 3.687 12.998"
      />
      <path
        id="recipientsFilled-path2_fill"
        fillRule="evenodd"
        d="M 11.9995 2L 8.49954 2C 7.87754 2 7.49354 2.437 7.49354 3.004C 7.49354 3.464 7.75754 4 8.49954 4L 13.9995 4C 14.2755 4 14.4995 4.224 14.4995 4.5C 14.4995 4.776 14.2755 5 13.9995 5L 8.49954 5C 7.18354 5 6.49354 3.996 6.49354 3.004C 6.49354 2.999 6.49554 2.993 6.49554 2.986L 3.63754 2.082C 2.33454 1.708 1.32054 2.035 0.145536 3.209C -0.0964641 3.453 -0.0254641 3.859 0.275536 4.01C 7.06254 7.403 8.99554 8.943 11.3245 8.943C 13.0165 8.943 14.9365 8.131 18.9995 6.758L 18.9995 0L 16.4995 0C 14.8985 0 13.1485 0.983 11.9995 2"
      />
    </defs>
  </svg>
);

export default RecipientsFilled;
