import React from 'react';
import { styled } from 'styletron-react';
import style from '../../style';
import Overlay from '../Overlay';
import Slide from '../Slide';
import List from '../List';

export default class Dropdown extends React.Component {
  state = {
    isOpen: false,
  };
  toggle = () => this.setState(state => ({ isOpen: !state.isOpen }));

  render() {
    const { title, children } = this.props;
    const { isOpen } = this.state;
    return (
      <DropdownBase>
        <DropdownToggle onClick={this.toggle}>{title}</DropdownToggle>
        <StyledOverlay isActive={isOpen} toggle={this.toggle}>
          <SlideWrapper>
            <Slide isActive={isOpen}>
              <List onClick={this.toggle}>{children}</List>
            </Slide>
          </SlideWrapper>
        </StyledOverlay>
      </DropdownBase>
    );
  }
}

// Styled Components
const DropdownBase = styled('div', {
  position: 'relative',
});

const DropdownToggle = styled('button', {
  border: `1px solid ${style.light}`,
  padding: style.basePadding,
  background: 'white',
  borderRadius: '0',
  cursor: 'pointer',
  ':focus': {
    outline: 'none',
  },
});

const StyledOverlay = styled(Overlay, {
  maxWidth: '240px',
  margin: 'auto',
});

const SlideWrapper = styled('div', {
  width: '100%',
  position: 'absolute',
  top: '100%',
  right: '0',
});

export const DropdownItem = styled('li', ({ onClick, to, $noHover }) => ({
  display: 'block',
  textAlign: 'center',
  background: 'white',
  color: style.textColor,
  border: 'none',
  padding: style.basePadding,
  ...((onClick || to) &&
    !$noHover && {
      cursor: 'pointer',
      ':hover': {
        textDecoration: 'none',
        backgroundColor: style.primary,
        color: style.white,
      },
    }),
}));
