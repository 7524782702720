import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled, withStyle, withStyleDeep } from 'styletron-react';

import style from '../../style';
import { Container } from '../Layout';
import Nav, { itemFactory } from './Nav';
import SiteLogo from '../SiteLogo';

const HEADER_MIN_HEIGHT = '70px';

const Header = ({ user, signout, location, isAdmin, isCompact  }) => (
  <Base>
    <HeaderWrapper>
      <Title to="/">
        <SiteLogo />
      </Title>
      {user && !isCompact && <Nav user={user} signout={signout} location={location} isAdmin={isAdmin} />}
    </HeaderWrapper>
  </Base>
);

// Styled Components
const Base = styled('header', {
  background: 'white',
  minHeight: HEADER_MIN_HEIGHT,
});

const HeaderWrapper = withStyleDeep(Container, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: HEADER_MIN_HEIGHT,
  ...style.lg({
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'stretch',
  }),
});

const TitleLink = itemFactory(Link);

const Title = withStyle(TitleLink, {
  fontFamily: 'HoeflerTextRoman, "Hoefler Text", "Baskerville Old Face", Garamond, "Times New Roman", serif',
  minHeight: HEADER_MIN_HEIGHT,
  ':hover': {
    textDecoration: 'none',
    color: 'initial',
  },
  ':visited': {
    color: 'initial',
  },
  ':active': {
    '-webkit-text-stroke': 'initial',
  },
  ':focus': {
    '-webkit-text-stroke': 'initial',
    color: style.textColor,
    outline: '0',
  },
});

const mapStateToProps = state => ({ isAdmin: state.authentication.isAdmin });

export default connect(mapStateToProps)(Header);
