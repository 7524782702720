import React from 'react';

const QuestionMarkFilled = props => (
  <svg
    width={props.size || 23}
    height={props.size || 23}
    viewBox="0 0 23 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Canvas" transform="translate(-558 -4645)">
      <g id="questionMarkFilled">
        <g id="Fill 2456">
          <use
            xlinkHref="#qm0_fill"
            transform="translate(558 4645)"
            fill={props.fill || '#5C5C5C'}
          />
        </g>
      </g>
    </g>
    <defs>
      <path
        id="qm0_fill"
        fillRule="evenodd"
        d="M 12 11.965L 12 14.5C 12 14.777 11.775 15 11.5 15C 11.224 15 11 14.777 11 14.5L 11 11.5C 11 11.224 11.224 11 11.5 11C 12.878 11 14 9.879 14 8.5C 14 7.121 12.878 6 11.5 6C 10.121 6 9 7.121 9 8.5C 9 8.777 8.775 9 8.5 9C 8.224 9 8 8.777 8 8.5C 8 6.57 9.569 5 11.5 5C 13.43 5 15 6.57 15 8.5C 15 10.26 13.693 11.721 12 11.965L 12 11.965ZM 11.5 18C 10.947 18 10.5 17.553 10.5 17C 10.5 16.448 10.947 16 11.5 16C 12.051 16 12.5 16.448 12.5 17C 12.5 17.553 12.051 18 11.5 18L 11.5 18ZM 11.5 0C 5.148 0 0 5.149 0 11.5C 0 17.852 5.148 23 11.5 23C 17.852 23 23 17.852 23 11.5C 23 5.149 17.852 0 11.5 0L 11.5 0Z"
      />
    </defs>
  </svg>
);

export default QuestionMarkFilled;
