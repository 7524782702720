import React from 'react';
import Button from '../components/Button';


class ImagesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            images: [],
        };
    }

    onDeleteImage(fileId, id) {
        const { deleteImage } = this.props;
        deleteImage(fileId, id)
        .then((e) => {
            console.log('Calling list images')
            fetch(`${process.env.REACT_APP_API_URI}/api/images/${id}/list`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        images: result.images
                    });
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
                )
            }
                );
    };

    componentDidMount() {
        const { id } = this.props;
        
        fetch(`${process.env.REACT_APP_API_URI}/api/images/${id}/list`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        images: result.images
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const { error, isLoaded, images } = this.state;
        const { name, id } = this.props;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <>
                    <h3>{name} - shipment {id}</h3>
                        {
                            images.map( (image, i) => (
                               <div key={i}>
                                    <div>
                                        <img width="360" src={image.uri} alt=''/>
                                    </div>
                                    <div style={{marginBottom : '20px'}}>
                                        <Button danger onClick={e =>  this.onDeleteImage(image.fileId, id)} >Delete</Button>
                                    </div>
                                </div>
                            ))
                        }

                </>
            );
        }
    }
}

export default ImagesView
