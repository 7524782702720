import fetch from '../../utils/fetch';
import moment from 'moment';

export const send = consignmentIds => (dispatch, getState) => {
  return fetch('api/consignment/notifications/', {
    method: 'POST',
    body: JSON.stringify({
      consignmentIds,
      reminderSent: moment(new Date()).format(),
    }),
  })(dispatch, getState);
};
