import React from 'react';
import Field from '../../components/Field';
import Button from '../../components/Button';
import withForm from '../../components/withForm';
import { RightAlign } from '../../components/Layout';
import { optionFieldType } from './types';

const AppConfigForm = ({
  values,
  onChange,
  onSubmit,
  isSubmitting,
  disabled,
  config,
}) => {
  const isDisabled = isSubmitting || disabled;
  return (
    <form>
      {config.options.map(o => {
        switch (o.type) {
          case optionFieldType.string:
            return (
              <Field
                key={o.id}
                label={o.name}
                name={o.id}
                value={values[o.id] || ''}
                disabled={isDisabled}
                onChange={onChange}
              />
            );
          default:
            return null;
        }
      })}

      <RightAlign>
        <Button primary disabled={isDisabled} onClick={() => onSubmit()}>
          Save
        </Button>
      </RightAlign>
    </form>
  );
};

export default withForm({
  mapPropsToValues: props => props.options,
  mapPropsToSubmit: props => props.save,
})(AppConfigForm);
