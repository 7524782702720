import React from 'react';

export const ParcelIcon = () => (
  <svg
    className="parcel"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="32"
    viewBox="0 0 512 512"
    fill="white"
    display="block"
    aria-labelledby="title"
  >
    <title id="title">Parcel Icon</title>
    <path d="M467.301 168.084c-1.805 0-3.516.329-5.07.962l-2.281 1.15-171.475 88.938-3.888 1.996s-.052.023-.052.047c-7.56 4.668-12.535 12.506-12.535 21.425v185.854c0 6.382 5.649 11.544 12.69 11.544a13.77 13.77 0 0 0 6.065-1.404c.155-.095.363-.188.519-.282l174.731-92.694.259-.141c8.241-4.504 13.735-12.742 13.735-22.105V179.582c.001-6.359-5.7-11.498-12.698-11.498zM454.273 118.521l-181.7-81.731S261.914 32 255.996 32s-16.526 4.79-16.526 4.79L57.614 118.521s-7.989 3.292-7.989 9.479c0 6.562 8.3 11.5 8.3 11.5l182.582 96.371 2.902 1.479c3.784 1.687 8.077 2.649 12.587 2.649 4.561 0 8.915-.963 12.742-2.696l2.539-1.315 182.893-96.536s7.508-4.036 7.508-11.453c0-6.499-7.405-9.478-7.405-9.478zM227.466 261.178l-3.992-2.043-171.432-88.938-2.28-1.15c-1.495-.634-3.258-.962-5.071-.962-6.997 0-12.69 5.138-12.69 11.497v183.792c0 9.388 5.485 17.602 13.727 22.105l.208.141 174.739 92.694c1.918 1.08 4.199 1.687 6.636 1.687 6.989 0 12.69-5.162 12.69-11.544V282.603c-.001-8.919-4.977-16.757-12.535-21.425z" />
  </svg>
);
