import React from 'react';
import WithStickerConfiguration from '../../features/stickerConfiguration/WithStickerConfiguration';
import PrintButton from './PrintButton';
import { printShipments } from './printing';

export default ({ shipments }) => (
  <WithStickerConfiguration contractId={shipments.length && shipments[0].contract.id}>
    {({ configuration }) => (
      <PrintButton
        disabled={!configuration.loaded}
        onPrint={printer => printShipments(printer, shipments, configuration)}
      />
    )}
  </WithStickerConfiguration>
);
