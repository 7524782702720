import XLSX from 'xlsx';
import fetch, { raw } from '../../../utils/fetch';

export const LOCATION_IMPORT_FILE_PARSING = 'LOCATION_IMPORT_FILE_PARSING';
export const LOCATION_IMPORT_FILE_PARSED_SUCCESS = 'LOCATION_IMPORT_FILE_PARSED_SUCCESS';
export const LOCATION_IMPORT_FILE_PARSED_FAILED = 'LOCATION_IMPORT_FILE_PARSED_FAILED';
export const LOCATION_IMPORT_STARTED = 'LOCATION_IMPORT_STARTED';
export const LOCATION_IMPORT_SUCCESS = 'LOCATION_IMPORT_SUCCESS';
export const LOCATION_IMPORT_FAILED = 'LOCATION_IMPORT_FAILED';

export const importLocations = (contractId, { columns, locations }) => dispatch => {
  if (!columns || columns.length === 0) return;
  if (!locations || locations.length === 0) return;

  dispatch({ type: LOCATION_IMPORT_STARTED });

  return dispatch(
    fetch('api/location/import', {
      method: 'POST',
      body: JSON.stringify({
        contractId,
        columns,
        locations,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  )
    .then(res => {
      if (res.error) {
        return dispatch({
          type: LOCATION_IMPORT_FAILED,
          error: {
            message: res.error,
            data: res.data,
          },
        });
      }

      dispatch({
        type: LOCATION_IMPORT_SUCCESS,
        data: res,
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: LOCATION_IMPORT_FAILED,
        error,
      });
      throw new Error('Location import failed');
    });
};

export const parseFile = file => dispatch => {
  if (!file) {
    return;
  }

  dispatch({
    type: LOCATION_IMPORT_FILE_PARSING,
    data: {
      filename: file.name,
    },
  });

  const reader = new FileReader();

  reader.onload = event => {
    const fileData = new Uint8Array(event.target.result);

    const workbook = XLSX.read(fileData, { type: 'array' });
    const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });

    dispatch({
      type: LOCATION_IMPORT_FILE_PARSED_SUCCESS,
      data,
    });
  };

  reader.onerror = event => {
    dispatch({
      type: LOCATION_IMPORT_FILE_PARSED_FAILED,
      error: {
        message: event.error && (event.error.message || event.error),
        data: {},
      },
    });
  };

  setTimeout(() => reader.readAsArrayBuffer(file), 500);
};

export const getExportLink = contractId => (dispatch, getState) =>
  raw(`api/location/export?contractid=${contractId}`)(dispatch, getState).then(res => {
    return res.headers.get('Location');
  });
