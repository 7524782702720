import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { makeButtonStyle } from './Button';
import { styled } from 'styletron-react';
import Spinner from '../components/Spinner';

const Button = styled('button', ({ $primary, $danger }) => makeButtonStyle({ $primary, $danger }));

const StatefulButton = forwardRef(({ isLoading, primary, danger, children, ...rest }, ref) => {
  const [buttonStyle, setButtonStyle] = useState({});
  const domElementRef = useRef(null);

  useEffect(() => {
    if (domElementRef.current) {
      setButtonStyle({
        minWidth: domElementRef.current.offsetWidth,
        display: 'flex',
        justifyContent: 'center',
      });
    }
  }, []);

  return (
    <Button
      ref={ref}
      type="button"
      $primary={primary}
      $danger={danger}
      style={buttonStyle}
      {...rest}
    >
      {isLoading ? <Spinner color={(primary || danger) && 'white'} /> : children}
    </Button>
  );
});

export default StatefulButton;
