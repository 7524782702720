import {
  LOCATION_IMPORT_FILE_PARSING,
  LOCATION_IMPORT_FILE_PARSED_SUCCESS,
  LOCATION_IMPORT_FILE_PARSED_FAILED,
  LOCATION_IMPORT_STARTED,
  LOCATION_IMPORT_SUCCESS,
  LOCATION_IMPORT_FAILED,
} from './import/action-creators';

const defaultState = {
  locationImport: {
    isParsing: false,
    isImporting: false,
    filename: '',
    columns: [],
    locations: [],
    importResult: {},
    error: undefined,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOCATION_IMPORT_FILE_PARSING:
      return {
        ...state,
        locationImport: {
          ...state.locationImport,
          isParsing: true,
          filename: action.data.filename,
          error: undefined,
        },
      };
    case LOCATION_IMPORT_FILE_PARSED_SUCCESS:
      return {
        ...state,
        locationImport: {
          ...state.locationImport,
          isParsing: false,
          columns: action.data.shift(),
          locations: action.data,
          importResult: {},
          error: undefined,
        },
      };
    case LOCATION_IMPORT_FILE_PARSED_FAILED:
      return {
        ...state,
        locationImport: {
          ...state.locationImport,
          isParsing: false,
          error: action.error,
        },
      };
    case LOCATION_IMPORT_STARTED:
      return {
        ...state,
        locationImport: {
          ...state.locationImport,
          isImporting: true,
          error: undefined,
        },
      };
    case LOCATION_IMPORT_SUCCESS:
      return {
        ...state,
        locationImport: {
          ...state.locationImport,
          isImporting: false,
          error: undefined,
          columns: [],
          locations: [],
          importResult: action.data,
        },
      };
    case LOCATION_IMPORT_FAILED:
      return {
        ...state,
        locationImport: {
          ...state.locationImport,
          isImporting: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};
