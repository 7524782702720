export const primary = '#E78125';
export const primaryDarker = '#D16E0E';

export const red = '#D14200';
export const redDarker = '#A24002';

export const dark = '#83786E';
export const darkDarker = '#5E554E';

export const white = '#FFFFFF';
export const superLight = '#F4F4F4';
export const light = '#E0DED8';
export const grey = '#C8C2B9';

export const textColor = '#222';
export const titleColor = '#0D0D0D';
export const textColorLight = '#CCC';
export const textColorSuperLight = '#AEA79E';
export const borderColor = '#9E9E9E';

export const statusGreen = '#97BE57';
export const statusYellow = '#F3B33C';
export const statusRed = '#D14200';
export const statusGrey = '#ADA79E';
