import React from 'react';
import { connect } from 'react-redux';
import { state as asyncState } from '../../utils/fetch';
import { load, edit } from './action-creators';

class WithStickerConfiguration extends React.Component {
  componentDidMount() {
    this.load(this.props);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.load(nextProps);
  }
  load(props) {
    if (
      props.configuration.state === asyncState.notTriggered &&
      props.contractId
    ) {
      props.load(props.contractId);
    }
  }
  render() {
    return this.props.children(this.props);
  }
}

export default connect(
  (state, props) => ({
    configuration: state.stickerConfiguration[props.contractId] || {
      state: asyncState.notTriggered,
      loaded: false,
    },
  }),
  {
    load,
    edit,
  }
)(WithStickerConfiguration);
