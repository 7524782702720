import React, { useRef, useState, useEffect } from 'react';
import { styled } from 'styletron-react';

const transition = attribute => `${attribute} 0.4s ease-in-out`;

const SlideWrapper = styled('div', ({ $isActive, $contentHeight }) => ({
  position: 'relative',
  overflow: 'hidden',
  transition: transition('max-height'),
  maxHeight: $isActive ? `${$contentHeight}px` : '0px',
}));

const SlideContent = styled('div', ({ $isActive }) => ({
  transform: 'translate3d(0, -100%,0)',
  transition: transition('transform'),
  ...($isActive && {
    transform: 'translate3d(0,0,0)',
  }),
}));

const Slide = ({ isActive, children }) => {
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    }
  }, [isActive]);

  return (
    <SlideWrapper $isActive={isActive} $contentHeight={contentHeight}>
      <SlideContent ref={contentRef} $isActive={isActive}>
        {children}
      </SlideContent>
    </SlideWrapper>
  );
};

export default Slide;
