import React from 'react';
import { styled } from 'styletron-react';
import style from '../../../style';

const Base = styled('form', {
  display: 'flex',
  alignItems: 'stretch',
  borderRadius: '0.25rem',
  overflow: 'hidden',
});

const Input = styled('input', {
  flexGrow: 1,
  padding: '0.5rem',
  border: '1px solid white',
  outline: 'none',
  fontSize: '16px', // iPhone will zoom in if fontsize is less than 16px
  '-webkit-appearance': 'none',
});

const Button = styled('button', {
  background: 'white',
  border: '1px solid white',
  borderLeft: `1px solid ${style.light}`,
  outline: 'none',
});

export default class ShipmentInput extends React.Component {
  state = {
    value: '',
  };
  render() {
    const { onAdd } = this.props;
    const { value } = this.state;
    return (
      <Base
        onSubmit={e => {
          e.preventDefault();
          if (!value) return;
          onAdd(value);
          this.setState({ value: '' });
        }}
      >
        <Input
          type="number"
          pattern="\d*"
          value={value}
          onChange={() => {}}
          onInput={e => {
            if (isNaN(e.nativeEvent.data)) {
              // Handle e, minus(-) and decimals(.,)
              this.setState({ value: 1 });
              this.forceUpdate(() => this.setState({ value }));
            } else if (e.nativeEvent.data === null) {
              // Handle backspace
              this.setState({ value: e.target.value || '' });
            } else {
              // Handle valid numbers
              this.setState({ value: value + e.nativeEvent.data });
            }
          }}
          placeholder="Enter shipment number..."
        />
        <Button>Add</Button>
      </Base>
    );
  }
}
