import React from 'react';

const ShipmentsFilled = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>shipmentFilled</title>

    <g id="Canvas" transform="translate(-849 -3870)">
      <g id="packageFilled">
        <g id="Fill 3209">
          <use
            xlinkHref="#path01_fill"
            transform="translate(849.808 3872.46)"
            fill={props.fill || '#5C5C5C'}
          />
        </g>
        <g id="Fill 3210">
          <use
            xlinkHref="#path11_fill"
            transform="translate(849 3875.55)"
            fill={props.fill || '#5C5C5C'}
          />
        </g>
        <g id="Fill 3211">
          <use
            xlinkHref="#path21_fill"
            transform="translate(861 3875.55)"
            fill={props.fill || '#5C5C5C'}
          />
        </g>
        <g id="Fill 3212">
          <use
            xlinkHref="#path31_fill"
            transform="translate(856.397 3870)"
            fill={props.fill || '#5C5C5C'}
          />
        </g>
      </g>
    </g>
    <defs>
      <path
        id="path01_fill"
        fillRule="evenodd"
        d="M 5.423 0L 0 2.358L 10.692 7.488L 15.577 5.477L 5.423 0Z"
      />
      <path
        id="path11_fill"
        fillRule="evenodd"
        d="M 3 4.45008C 3 4.08408 3.38 3.84408 3.707 3.99508L 8.707 6.49508C 8.885 6.57608 9 6.75408 9 6.95008L 9 11.9501C 9 12.3131 8.623 12.5551 8.293 12.4051L 3.293 9.90508C 3.114 9.82408 3 9.64608 3 9.45008L 3 4.45008ZM 0 12.95C 0 13.149 0.118 13.329 0.301 13.408L 11 18.278L 11 5.27L 0 0L 0 12.95Z"
      />
      <path
        id="path21_fill"
        fillRule="evenodd"
        d="M 0 5.27L 0 18.278L 11.699 13.408C 11.882 13.329 12 13.149 12 12.95L 12 0L 0 5.27Z"
      />
      <path
        id="path31_fill"
        fillRule="evenodd"
        d="M 15.794 4.821L 4.802 0.042C 4.675 -0.014 4.53 -0.014 4.403 0.042L 0 1.956L 10.127 7.418L 15.794 4.821Z"
      />
    </defs>
  </svg>
);

export default ShipmentsFilled;
