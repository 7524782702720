import { state as asyncState } from '../../utils/fetch';
import {
  CONFIG_LOAD,
  CONFIG_LOAD_SUCCESS,
  UPDATE_CONFIG_OPTION,
  UPDATE_CONFIG_OPTION_SUCCESS,
} from './action-creators';

const defaultState = {
  options: [],
  state: asyncState.notTriggered,
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case CONFIG_LOAD:
      return { ...state, state: asyncState.fetching };
    case CONFIG_LOAD_SUCCESS:
      return {
        ...state,
        options: action.data,
        state: asyncState.done,
      };
    case UPDATE_CONFIG_OPTION:
      return {
        ...state,
        state: asyncState.pushing,
      };

    case UPDATE_CONFIG_OPTION_SUCCESS:
      return {
        ...state,
        options: state.options.map(
          o =>
            o.id === action.option.id ? { ...o, value: action.option.value } : o
        ),
        state: asyncState.done,
      };
    default:
      return state;
  }
};
